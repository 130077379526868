<template>
    <v-card>
        <v-card-title class="client-info__heading">
            <div class="d-flex" style="width:100%">
                <div>
                    Team
                </div>
            </div>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-simple-table width="100%" class="mb-10 table-list" v-if="currentClient.admins.length > 0">
                <thead>
                <tr>
                    <th width="50"></th>
                    <th class="text-left table-list__heading-sortable"><span>ID <v-icon>expand_more</v-icon></span></th>
                    <th class="text-left table-list__heading-sortable"><span>Name <v-icon>expand_more</v-icon></span></th>
                    <th class="text-left table-list__heading-sortable"><span>Email <v-icon>expand_more</v-icon></span></th>
                    <th class="text-right" v-if="isRootClient"><span>Actions</span></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(user, index) in currentClient.admins" :key="index">
                    <td width="50">
                        <v-avatar color="grey lighten-4" :tile="false" :size="40">
                            <span>{{user.name.substring(0, 1)}}</span>
                        </v-avatar>
                    </td>
                    <td>{{user.id}}</td>
                    <td>
                        {{user.name}}
                       <!-- <router-link :to="{name: 'edit-user', params: {surveyId: user.id } }"></router-link>-->
                    </td>
                    <td>{{user.email}}</td>
                    <td class="align-right d-flex justify-end align-center table-list__actions" v-if="isRootClient">
                        <v-menu bottom left>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        light
                                        icon
                                        small
                                        v-on="on"
                                >
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <!--<v-list-item link :to="{name: 'edit-user', params: {userId: user.id } }">
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>-->
                                <v-list-item link @click="onDeleteUser(user.id)">
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>
            <empty-state error-text="Currently no team members are added" v-if="currentClient.admins.length === 0" />
            <confirm-delete-user v-if="showConfirmDeleteUserModal" :userId="selectedUserId" @userDeleted="onUserDeleted"/>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import ConfirmDeleteUser from '../../Users/Modals/ConfirmDeleteUser';

export default {
  name: 'ClientTeam',
  components: {ConfirmDeleteUser},
  data: () => ({
    isLoading: false,
    selectedUserId: ''
  }),
  computed: {
    ...mapState('clients', ['currentClient']),
    ...mapGetters('clients', ['isRootClient']),
    ...mapState('modals', ['showConfirmDeleteUserModal'])
  },
  methods: {
    onDeleteUser(id) {
      this.selectedUserId = id;
      this.$store.dispatch('modals/toggleModal', 'showConfirmDeleteUserModal');
    },
    onUserDeleted() {
      this.$store.dispatch('clients/getClientById', this.$route.params.id);
    }
  }
};
</script>
