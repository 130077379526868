<template>
    <v-dialog
            v-model="dialog"
            max-width="390">
        <v-card>
            <v-card-title class="headline text-center d-block">Mark survey as <div class="text-no-wrap">"{{ status }}"?</div></v-card-title>

            <v-card-text>
                <div class="text-center" v-if="textState === 1">
                    <v-icon :size="60" color="primary" class="py-3">done_all</v-icon>
                    <p>Please confirm that you want to mark this survey as "{{ status }}". Afterwards
                        the final report will be generated</p>
                    <div class="text-center">
                        <v-btn color="primary" :loading="isLoading" outlined rounded @click="onConfirm">
                            Confirm
                        </v-btn>
                        <v-btn text @click="onCloseModal">Cancel</v-btn>
                    </div>
                </div>
                <div class="text-center" v-if="textState === 2">
                    <p>The survey has been marked as {{status }}.</p>
                    <v-btn color="primary" outlined rounded @click="onCloseModal">Close</v-btn>
                </div>
                <v-alert type="error" icon="error_outline" v-if="isError">
                    {{errorText}}
                    <br/>
                    <v-btn color="white" outlined small class="mt-2" rounded @click="onCloseModal">Close</v-btn>
                </v-alert>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import EventBus from '../../../eventBus';

export default {
  name: 'ConfirmCloseSurvey',
  props: {
    surveyId: {
      required: true
    },
    surveyStatus: {
      required: true
    }
  },
  data: () => ({
    dialog: true,
    isLoading: false,
    isError: false,
    errorText: null,
    textState: 1
  }),
  computed: {
    status() {
      switch (this.surveyStatus) {
      case 'SUBMITTED':
        return 'Pending Finalization';
      case 'PENDING_FINALIZATION':
        return 'Completed';
      default:
        return "Unknown state";
      }
    }
  },
  watch: {
    dialog(val) {
      this.onCloseModal();
    }
  },
  methods: {
    onConfirm() {
      this.isLoading = true;
      this.$store.dispatch('submittedSurveys/completeSurveyInstance', this.surveyId).then(() => {
        EventBus.$emit('reloadSubmittedSurveys');
        this.isLoading = false;
        this.textState = 2;
      }).catch((err) => {
        this.isLoading = false;
        this.isError = true;
        this.textState = null;
        this.errorText = err;
      });
    },
    onCloseModal() {
      this.$emit('setSurveyId');
      this.$store.dispatch('modals/closeModal');
    }
  }
};
</script>
