<template>
    <div style="background-color:#f5f5f5;" class="pa-3 my-4">
        <v-list>
            <v-list-item link v-for="(question, index) in children" :key="question.id" class="u-border-bottom" :class="{'u-border-bottom-none': (index + 1) === children.length }">
                <v-list-item-avatar>
                    <v-avatar
                            :tile="false"
                            :size="30"
                            color="primary"
                    >
                        <span class="white--text">Q</span>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{question.title.en}}</v-list-item-title>
                    <v-list-item-content class="py-0">
                        <generic-answer-options
                                v-if="question.inputType === 'CHECKBOX' || question.inputType === 'RADIO' || question.inputType === 'DROPDOWN'"
                                :answer="question.answer"/>
                        <generic-answer-media v-if="question.inputType === 'MEDIA'"
                                             :answer="question.answer" :show-count="true"/>
                        <generic-answer-date-time :answer="question.answer"
                                                 v-if="question.inputType === 'DATETIME'"/>
                        <generic-answer-date :answer="question.answer"
                                            v-if="question.inputType === 'DATE'"/>
                        <generic-answer-cost-estimate :answer="question.answer"
                                                     v-if="question.inputType === 'COSTESTIMATE'"/>
                        <generic-answer-string v-if="question.inputType === 'STRING' ||
                                        question.inputType === 'BOOLEAN' ||
                                        question.inputType === 'CALLSIGN' ||
                                        question.inputType === 'IMO' ||
                                        question.inputType === 'NUMERIC' ||
                                        question.inputType === 'CONFIRM'"
                                              :answer="question.answer"/>
                    </v-list-item-content>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!children || children.length === 0">
                <v-list-item-content>
                    No questions added
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import GenericAnswerString from '../../Answers/GenericAnswerString';
import GenericAnswerCostEstimate from '../../Answers/GenericAnswerCostEstimate';
import GenericAnswerDate from '../../Answers/GenericAnswerDate';
import GenericAnswerDateTime from '../../Answers/GenericAnswerDateTime';
import GenericAnswerMedia from '../../Answers/GenericAnswerMedia';
import GenericAnswerOptions from '../../Answers/GenericAnswerOptions';

export default {
  name: 'SurveyEditorListQuestions',
  props: {
    children: {
      type: Array,
      required: true
    }
  },
  components: {
    GenericAnswerString,
    GenericAnswerCostEstimate,
    GenericAnswerDate,
    GenericAnswerMedia,
    GenericAnswerOptions,
    GenericAnswerDateTime
  }
};
</script>

<style scoped>

</style>
