<template>
    <v-container>
        <v-layout row wrap class="mb-10">
            <v-flex xs6>
                <div class="pa-3" v-if="clientsWithSurveys">
                    <v-select label="Filter clients" placeholder="Filter by client" rounded clearable solo flat
                              :items="clientsWithSurveys"
                              item-value="id"
                              v-model="selectedClient"
                              item-text="name" @change="onFilterByClient" />
                </div>
            </v-flex>
            <v-flex xs6 class="d-flex justify-end">
                <div class="pt-5 pr-3">
                    <button-add-to-survey remove-shadow="true" add-mode="Survey" button-title="Create survey"/>
                </div>
            </v-flex>
        </v-layout>
        <manage-surveys></manage-surveys>
        <add-survey-modal v-if="showAddToSurveyModal"></add-survey-modal>
    </v-container>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import ButtonAddToSurvey from '../../components/UI/Buttons/ButtonAddToSurvey';
import AddSurveyModal from '../../components/Surveys/Modals/AddSurveyModal';
import ManageSurveys from '../../components/Surveys/ManageSurveys';

export default {
  name: 'SurveysAdministration',
  data: () => ({
    selectedClient: null
  }),
  computed: {
    ...mapState('modals', ['showAddToSurveyModal']),
    ...mapState('clients', ['clientsWithSurveys', 'filterClientId'])
  },
  components: {ButtonAddToSurvey, ManageSurveys, AddSurveyModal},
  methods: {
    ...mapMutations('cms', ['updatePageTitle']),
    ...mapMutations('clients', ['setFilteredClient']),
    onFilterByClient(clientId) {
      this.setFilteredClient(clientId);
    }
  },
  created() {
    this.updatePageTitle('Survey template management');
    if (this.filterClientId) {
      this.selectedClient = this.filterClientId;
    }
  }
};
</script>
