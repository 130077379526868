<template>
    <v-container>
        <v-layout>
            <v-flex xs12>
                <v-card flat class="mx-auto">
                    <helper-offset :inline="true"
                                   :full-width="false"
                                   :offset="24">
                        <v-card color="primary"
                                class="v-card--material__header d-flex align-center mb-5"
                                dark
                                elevation="10"
                                min-height="80"
                        >
                            <v-card-title>
                                <h4 class="title font-weight-light mb-2">Client Management</h4>
                            </v-card-title>
                        </v-card>
                    </helper-offset>
                    <v-divider></v-divider>

                    <loading v-if="isLoading" />

                    <v-simple-table width="100%" class="mb-10 table-list" v-if="!isLoading">
                        <thead>
                        <tr>
                            <th class="text-left table-list__heading-sortable" @click="sortBy('name')"><span>Client <v-icon>expand_more</v-icon></span>
                            </th>
                            <th class="text-left table-list__heading-sortable" @click="sortBy('adminCount')"><span>Team members <v-icon>expand_more</v-icon></span>
                            </th>
                            <th class="text-left table-list__heading-sortable" @click="sortBy('userCount')"><span>App users <v-icon>expand_more</v-icon></span>
                            </th>
                            <th class="text-left"><span>Branding</span></th>
                            <th class="text-right"><span>Actions</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(client, index) in filteredClients" :key="index">
                            <td>
                                <router-link :to="{name: 'client-detail', params: {id: client.id } }">
                                    {{client.name}}
                                </router-link>
                            </td>
                            <td>{{client.adminCount || 'N/A'}}</td>
                            <td>{{client.userCount || 'N/A' }}</td>
                            <td>
                                <span :style="{'backgroundColor': client.color}" class="brand-color brand-color--medium mr-2" style="display:inline-block;"></span>
                                <!--<span style="background-color: #ccc; border-radius: 50%; width:30px; height:30px; display:inline-block; margin-right:10px; "></span>-->
                                <span :style="{ 'background': '#fff url(' + cloudinaryUrl + client.logo + ')' }" class="brand-logo brand-logo--rounded brand-logo--as-background"></span>
                            </td>
                            <td class="align-right d-flex justify-end align-center table-list__actions">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                                light
                                                icon
                                                small
                                                v-on="on"
                                        >
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item link :to="{name: 'client-detail', params: {id: client.id } }">
                                            <v-list-item-title>View client</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item link @click="onDeleteClient(client.id)">
                                            <v-list-item-title>Delete client</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-flex>
        </v-layout>
        <confirm-delete-client v-if="showConfirmDeleteClientModal" :client-id="selectedClientId" @clientDeleted="fetchClients" />
    </v-container>
</template>

<script>
import logo from '../../assets/img/saremote-white-logo.png';
import {mapState, mapMutations} from 'vuex';
import constants from '../../helpers/constants';
import ConfirmDeleteClient from './Modals/ConfirmDeleteClient';

export default {
  name: 'ClientListOverview',
  data: () => ({
    cloudinaryUrl: constants.cloudinaryUrl,
    logo: logo,
    isLoading: false,
    selectedClientId: ''
  }),
  components: {ConfirmDeleteClient},
  computed: {
    ...mapState('clients', ['clients', 'searchQuery']),
    ...mapState('modals', ['showConfirmDeleteClientModal']),
    filteredClients() {
      return this.clients.filter((client) => {
        if (this.searchQuery.length > 2) {
          if (client.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
            return client;
          };
        } else {
          return client;
        }
      });
    }
  },
  methods: {
    ...mapMutations('clients', ['setSortByClientsSection']),
    onDeleteClient(id) {
      this.selectedClientId = id;
      this.$store.dispatch('modals/toggleModal', 'showConfirmDeleteClientModal');
    },
    fetchClients() {
      this.$store.dispatch('clients/getAllClients').then(() => {
        this.isLoading = false;
      });
    },
    sortBy(sorting) {
      this.setSortByClientsSection(sorting);
    }
  },
  created() {
    this.isLoading = true;
    this.fetchClients();
  }
};
</script>
