<template>
    <div v-if="currentSurvey">
        <v-alert type="info" class="mb-10" prominent tile>
            <p>
                Generate a PDF by by clicking the button below. It will either ask what you want to do (download / view)
                or
                download it automatically.
            </p>
            <v-btn color="white" rounded depressed light @click="printDocument" :disabled="printDocumentDisabled">
                {{ printDocumentText }}
            </v-btn>
        </v-alert>

        <!-- Survey start -->
        <div class="text-center pb-10">
            <h2>Report preview</h2>
            <h3 class="subtitle-2">Preview will be missing the page header, footer and page breaks - Remember to turn on
                colors, backgrounds, including enabling headers and footers in your print settings</h3>
        </div>
        <div ref="print" class="survey-report">
            <div class="page-header" style="text-align:right; padding:20px;">
                <img :src="imagePath + currentSurvey.reportLogo" alt="" style="max-height:40px;"
                     v-if="currentSurvey.reportLogo"/>
                <img src="../../../../assets/img/saremote-logo.png" style="max-height:40px;" alt=""
                     v-if="!currentSurvey.reportLogo"/>
            </div>

            <div class="page-footer">
                <table width="100%" style="width:100%; font-size:12px">
                    <tr>
                        <td style="vertical-align: top;">
                            <table width="100%">
                                <tr>
                                    <td style="text-align:left;">
                                        <div
                                                style="white-space:pre-line; font-family: 'Rajdhani', 'Arial', sans-serif !important; font-size:12px; font-weight: normal; text-align:left;">
                                            <span v-if="currentSurvey .reportAddress">{{
                                                    currentSurvey.reportAddress.trim()
                                                }}</span>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td style="vertical-align: top; text-align:right;">
                            <table width="100%">
                                <tr>
                                    <td style="text-align:right;">
                                        Survey master: {{ getFieldValue(currentSurvey.title) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align:right;">
                                        Survey type: {{ currentSurvey.type }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align:right;">
                                        Revision:
                                        <span style="font-size:12px;"
                                              v-if="currentSurvey.closedAt">{{
                                                currentSurvey.closedAt | formatDateWithTime
                                            }}</span>
                                        <span style="font-size:12px;"
                                              v-else>{{ currentSurvey.updatedAt | formatDateWithTime }}</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>

            <table style="width:100%; background-color:#fff;">
                <tr>
                    <td>
                        <table style="width:100%">

                            <thead>
                            <tr>
                                <td>
                                    <!--place holder for the fixed-position header-->
                                    <div class="page-header-space"></div>
                                </td>
                            </tr>
                            </thead>

                            <tbody>
                            <tr>
                                <td class="survey-report">
                                    <!--*** CONTENT GOES HERE ***-->
                                    <div class="survey-report__page-one page">

                                        <div class="survey-report__sub-title" style="text-align:center;">
                                            <h2 style="text-align:center">
                                                {{ getFieldValue(currentSurvey.title) }}
                                            </h2>
                                        </div>
                                        <div class="survey-report__description text-center py-3"
                                             v-if="getFieldValue(currentSurvey.description)">
                                            <p style="text-align:center">
                                                {{ getFieldValue(currentSurvey.description) }}</p>
                                        </div>
                                    </div>

                                    <!-- Table of contents -->
                                    <div class="survey-report__table-of-contents  mt-10 page print-toc">
                                        <div class="survey-report__page-title">
                                            <h2>Table of contents</h2>
                                        </div>
                                        <ul class="pa-5 appendix table-of-contents"
                                            v-if="currentSurvey.children">

                                            <li v-for="(page, indexPage) in currentSurvey.children"
                                                :key="indexPage">
                                                <a class="toc-line" :title="getFieldValue(page.title)"
                                                   :href="mustache(`_tocMap.page_${page.id}.href`)"><span
                                                        class="toc-title">{{ `${indexPage + 1}` }} - {{
                                                        getFieldValue(page.title)
                                                    }}</span><span class="page-number"
                                                                   :data-page-number="mustache(`_tocMap.page_${page.id}.page`)"></span></a>
                                                <template v-for="(section, indexSection) in page.children">
                                                    <ul :key="indexSection"
                                                        v-if="shouldShowSection(section.children) === true">
                                                        <li><a class="toc-line"
                                                               :href="mustache(`_tocMap.section_${section.id}.href`)">
                              <span class="toc-title">{{
                                      `${indexPage + 1}.${indexSection + 1}`
                                  }} - {{
                                      getFieldValue(section.title)
                                  }}</span><span class="page-number"
                                                 :data-page-number="mustache(`_tocMap.section_${section.id}.page`)"></span>
                                                        </a>
                                                        </li>
                                                    </ul>
                                                </template>
                                            </li>

                                        </ul>
                                    </div>

                                    <!-- Report content -->
                                    <div class="survey-report__content" v-if="currentSurvey">
                                        <!-- Pages -->
                                        <template v-if="currentSurvey.children">
                                            <div :id="'page'+indexPage" class="survey-report__page page"
                                                 v-for="(page, indexPage) in currentSurvey.children"
                                                 :key="indexPage">
                                                <div class="survey-report__page-title">
                                                    <h2 :id="`page_${page.id}`">{{ page.index }} -
                                                        {{ getFieldValue(page.title) }}</h2>
                                                </div>
                                                <div class="survey-report__page-description pt-3"
                                                     v-if="getFieldValue(page.description)">
                                                    <p>{{ getFieldValue(page.description) }}</p>
                                                </div>
                                                <!-- Section -->
                                                <template v-if="page.children">
                                                    <div class="survey-report__section"
                                                         v-for="(section, indexSection) in page.children"
                                                         :key="indexSection">
                                                        <template v-if="shouldShowSection(section.children) === true">
                                                            <div class="survey-report__section-title"
                                                                 style="display:flex"
                                                                 v-if="section.children && section.children.length > 0">

                                                                <div>
                                                                    <h3 :id="`section_${section.id}`">
                                                                        {{ section.index }}
                                                                        {{ getFieldValue(section.title) }}</h3>
                                                                    <pre
                                                                            style="font-family: 'Arial', sans-serif; white-space: pre-line; color:#fff !important;"
                                                                            v-if="section.comments">
                                                                        {{ section.comments[0].content }}
                                                                    </pre>
                                                                </div>
                                                            </div>
                                                            <div class="survey-report__section-description"
                                                                 v-if="getFieldValue(section.description)">
                                                                <p>{{ getFieldValue(section.description) }}</p>
                                                            </div>
                                                        </template>
                                                        <!-- QUESTIONS -->
                                                        <template v-if="section.children">
                                                            <div v-for="(question, indexQuestion) in section.children"
                                                                 :key="indexQuestion">
                                                                <div class="survey-report__question page-break-inside"
                                                                     style="border-bottom:1px solid #000">
                                                                    <table width="100%">
                                                                        <tbody>
                                                                        <tr>
                                                                            <td
                                                                                    style="width: 100px; vertical-align:top; text-align:left; padding:5px 0;border-bottom:1px dotted #ccc;">
                                                                                <strong>{{ question.index }}</strong>
                                                                            </td>
                                                                            <td
                                                                                    style="text-align:left; vertical-align:top; padding:5px 0; border-bottom:1px dotted #ccc;">
                                                                                <b>{{
                                                                                        getFieldValue(question.title)
                                                                                    }} <span v-if="question.isRequired"
                                                                                             style="color:#ff0000">*</span></b>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="100px">

                                                                            </td>
                                                                            <td style="text-align:left; padding:10px 0;">
                                                                                <generic-question-options
                                                                                        v-if="question.inputType === 'CHECKBOX' || question.inputType === 'RADIO' || question.inputType === 'DROPDOWN'"
                                                                                        :question="question"/>
                                                                                <generic-question-damage
                                                                                        v-if="question.inputType === 'DAMAGE'"
                                                                                        :question="question"/>
                                                                                <generic-question-media
                                                                                        v-if="question.inputType === 'MEDIA'"
                                                                                        :question="question"/>
                                                                                <generic-question-date-time
                                                                                        :question="question"
                                                                                        v-if="question.inputType === 'DATETIME'"/>
                                                                                <generic-question-date
                                                                                        :question="question"
                                                                                        v-if="question.inputType === 'DATE'"/>
                                                                                <generic-question-cost-estimate
                                                                                        :question="question"
                                                                                        v-if="question.inputType === 'COSTESTIMATE'"/>
                                                                                <generic-question-boolean
                                                                                        :question="question"
                                                                                        v-if="question.inputType === 'BOOLEAN'"
                                                                                />
                                                                                <generic-question-string v-if="question.inputType === 'STRING' ||
                                                                                            question.inputType === 'CALLSIGN' ||
                                                                                            question.inputType === 'IMO' ||
                                                                                            question.inputType === 'NUMERIC' ||
                                                                                            question.inputType === 'CONFIRM'"
                                                                                                         :question="question"/>

                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>


                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                    </div>
                                </td>
                            </tr>
                            </tbody>

                            <tfoot>
                            <tr>
                                <td>
                                    <!--place holder for the fixed-position footer-->
                                    <div class="page-footer-space"></div>
                                </td>
                            </tr>
                            </tfoot>

                        </table>
                    </td>
                </tr>
            </table>
        </div>

        <!-- END LALALALA -->

    </div>
</template>

<script>
import { mapState } from "vuex";
import constants from "../../../../helpers/constants";
import { chooseDBasedOnHostname } from "../../../../helpers/config-helpers";
import GenericQuestionBoolean from "./Question/GenericQuestionBoolean.vue";
import GenericQuestionCostEstimate from "./Question/GenericQuestionCostEstimate.vue";
import GenericQuestionDamage from "./Question/GenericQuestionDamage.vue";
import GenericQuestionDateTime from "./Question/GenericQuestionDateTime.vue";
import GenericQuestionDate from "./Question/GenericQuestionDate.vue";
import GenericQuestionOptions from "./Question/GenericQuestionOptions.vue";
import GenericQuestionMedia from "./Question/GenericQuestionMedia.vue";
import GenericQuestionString from "./Question/GenericQuestionString.vue";

export default {
  name: "SurveyMasterPrint",
  data: () => ({
    imagePath: constants.cloudinaryUrl,
    originalPath: constants.cloudinaryUrlOriginal,
    vesselData: null,
    printDocumentText: "Generate PDF report",
    printDocumentDisabled: false
  }),
  components: {
    GenericQuestionBoolean,
    GenericQuestionCostEstimate,
    GenericQuestionDamage,
    GenericQuestionDate,
    GenericQuestionDateTime,
    GenericQuestionMedia,
    GenericQuestionString,
    GenericQuestionOptions,
  },
  computed: {
    ...mapState('surveys', [
      'currentSurvey',]),

  },
  methods: {
    getFieldValue(field, options) {
      if (typeof field === "string") {
        return field;
      }

      let defaultOptions = { defaultLocale: "en", locale: "en" };
      if (typeof options === "string") {
        options = { locale: options };
      }
      options = { ...defaultOptions, ...options };
      if (field && field[options.locale]) {
        return field[options.locale];
      }
      if (options.defaultLocale && field && field[options.defaultLocale]) {
        return field[options.defaultLocale];
      }

      if (options.default) {
        return options.default;
      }

      return null;
    },
    shouldShowSection(children) {
      return true;
    },
    setVesselData() {

    },
    fetchReport() {
      this.isLoading = true;
      this.$store.dispatch("surveys/getSurveyById", this.$route.params.surveyId).then(() => {
        this.isLoading = false;
      });
    },
    goBack() {
      this.$router.back();
    },
    mustache(ref) {
      return `{{${ref}}}`;
    },
    printDocument() {
      //Get HTML to print from element
      const prtHtml = this.$refs.print.innerHTML;
      let i = 0;
      this.printDocumentDisabled = true;
      this.printDocumentText = "Generating report";
      let interval = setInterval(() => {
        if (i % 5 === 0) {
          this.printDocumentText = "Generating report";
        } else {
          this.printDocumentText = `${this.printDocumentText}.`;
        }
        i++;
      }, 500);
      //Get all stylesheets HTML
      let stylesHtml = [];
      document.querySelectorAll("link[rel=\"stylesheet\"], style").forEach(node => {
        if (node.nodeName.toLowerCase() === "link") {
          node.href = node.sheet.href;
          if (!/kaspersky/.test(node.href)) {
            stylesHtml.push(node.outerHTML);
          }
        } else {
          if (!/nonce=/.test(node.outerHTML)) {
            stylesHtml.push(node.outerHTML);
          }
        }
      });
      let host = chooseDBasedOnHostname(window.location.hostname);
      fetch(`${host}/generate`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json"
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",

        body: JSON.stringify({
          content: `<!DOCTYPE html>
            <html>
              <head>
                ${stylesHtml.join("\n")}
              <body>
                ${prtHtml}
              </body>
            </html>`,
          footer: "{{{ pageNumber }}} / {{{ totalPages }}}"
        })
      }).then(async response => {
        if (response.status === 200) {
          //Try to find out the filename from the content disposition `filename` value

          //The actual download
          let blob = await response.blob();
          let link = window.document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          let reportName = ["master", this.getFieldValue(this.currentSurvey.title)];
          link.download = `${reportName.join("-")}.pdf`;
          window.document.body.appendChild(link);

          link.click();

          window.document.body.removeChild(link);
        } else {
          response.json().then(resp => {
            alert(resp.message);
          });
        }
      }).finally(() => {
        clearInterval(interval);
        this.printDocumentText = "Generate PDF Report";
        this.printDocumentDisabled = false;
      });
    }
  },
  created() {
    this.fetchReport();
  }
};
</script>
<style lang="scss">
.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.page-break-inside {
  page-break-before: auto;
  page-break-inside: avoid;
}
</style>
<style lang="scss" scoped>

pre, code {
  color: #000 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.survey-report {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 50px;
  background-color: #fff;
  font-size: 14px;

  .table-of-contents li a {
    display: flex;

    &.toc-line {
      border-bottom: 1px dashed #e5e5e5;
    }

    .toc-title {
      flex-grow: 1;
    }

    & span.page-number {
      text-align: right;
    }
  }

  .text-center {
    text-align: center !important;
  }

  &__ratings {
    margin: 50px 0;
  }

  &__page-one,
  &__rating,
  &__table-of-contents,
  &__summary,
  &__conclusion,
  &__attachments,
  &__recommendations {
    page-break-after: always;
  }

  &__page-title,
  &__report-title {
    background-color: #1867c0;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;

    h2 {
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__section-title {
    background-color: lighten(#1867c0, 10%);
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 0 20px 0;

    h2 {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__page-title,
  &__section-title,
  &__report-title {
    padding: 15px;
  }

  &__page-description,
  &__section-description {
    padding: 0px 15px;
  }

  &__table-of-contents {
    list-style: none;

    ul {
      padding: 5px 0 5px 10px !important;
      list-style: none;
    }

    li {
      font-size: 14px;
      margin: 0;
      display: block;
      padding: 8px 0;

      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}

.border-bottom {
  border-bottom: 1px solid black;
}

.page-header {
  display: block;
}

.page-footer {
  display: none; //none;
}

.page-header {
  height: 80px;
}

.page-header-space {
  height: 100px;
}

.page-footer, .page-footer-space {
  height: 120px;

}

@media print {

  //@page {
  //  counter-increment: page;
  //  font-family: 'Arial', sans-serif;
  //  @bottom-right {
  //    font-family: 'Arial', sans-serif;
  //    content: "Page " counter(page) " of " counter(pages);
  //  }
  //}

  //ul.appendix a:after {
  //  font-family: 'Arial', sans-serif;
  //  content: leader('.') target-counter(attr(href url), page);
  //}

  .survey-report {
    max-width: 100%;
    margin: auto;
    padding: 0;
    margin-bottom: 0;
  }

  .page-number {
    text-align: right;

    &:before {
      content: attr(data-page-number);
    }
  }

  .page-footer,
  .page-header {
    display: block;
  }

  .survey-report__content,
  .survey-report__page {
    page-break-after: always;
  }

  .page-footer {
    left: 0;
    right: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #f1f1f1; /* for demo */
    background: white; /* for demo */
  }

  .page-header {
    left: 0;
    right: 0;
    position: fixed;
    top: 0mm;
    width: 100%;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  body {
    margin: 0;
  }
}

</style>
