<template>
  <v-form ref="form" v-if="user">
    <!--<v-text-field placeholder="Enter first name" label="First name" v-model="user.firstName" />
    <v-text-field placeholder="Enter last name" label="Last name" v-model="user.lastName" />-->
    <!-- <v-text-field type="email" placeholder="Enter email" label="Email" v-model="user.name"/>
     <v-text-field type="email" placeholder="Enter email" label="Email" v-model="user.email"/>-->

    <!--    <h3>Update password</h3>-->
    <!--    <v-text-field-->
    <!--        v-model="user.password"-->
    <!--        :append-icon="showPass2 ? 'visibility' : 'visibility_off'"-->
    <!--        :rules="[rules.required, rules.min]"-->
    <!--        :type="showPass2 ? 'text' : 'password'"-->
    <!--        name="input-10-1"-->
    <!--        label="New password"-->
    <!--        hint="At least 8 characters"-->
    <!--        counter-->
    <!--        @click:append="showPass2 = !showPass2"-->
    <!--    ></v-text-field>-->
    <!--    <v-text-field-->
    <!--        v-model="passwordConfirm"-->
    <!--        :append-icon="showPass3 ? 'visibility' : 'visibility_off'"-->
    <!--        :rules="[rules.required, rules.min, passwordConfirmationRule]"-->
    <!--        :type="showPass3 ? 'text' : 'password'"-->
    <!--        name="input-10-1"-->
    <!--        label="Confirm password"-->
    <!--        hint="At least 8 characters"-->
    <!--        counter-->
    <!--        @click:append="showPass3 = !showPass3"-->
    <!--    ></v-text-field>-->
    <div v-if="isRootClient  && mode === 'app-user'">
      <h3>Update role</h3>
      <span>This is the role of the user. It is shared under a client.</span>
      <v-select
          class="mt-4"
          :items="rolesForClient"
          item-text="title.en"
          item-value="roleName"
          v-model="user.roleName"
          filled
          label="Edit user role"
          @change="roleChanged=true"
      > <template slot="item" slot-scope="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.title.en }} ({{ data.item.roleName }})
          </v-list-item-title>
        </v-list-item-content>
      </template>
        <template slot="selection" slot-scope="data">
          {{ data.item.title.en }} ({{ data.item.roleName }})
        </template></v-select>
      <h3>Update primary company</h3>
      <span>This is the company the user will submit surveys under</span>
      <v-select
          class="mt-4"
          :items="companies"
          item-text="name"
          item-value="id"
          v-model="user.companyId"
          filled
          label="Edit user company"
          @change="companyChanged=true"
      ></v-select>
      <h3>Update other companies</h3>
      <span>This is a list of other companies where the user can see surveys from</span>
      <v-select
          class="mt-4"
          :items="companies"
          item-text="name"
          item-value="id"
          v-model="user.companies"
          filled
          label="Edit user companies"
          multiple
          @change="companyChanged=true"
      ></v-select>
    </div>

    <div class="pt-3 mb-3">
      <v-btn rounded color="primary" depressed :loading="isLoading" @click="onUpdateUser">Update user</v-btn>
      <v-btn text @click="goBack">Cancel</v-btn>
    </div>

    <v-alert type="info" dense v-if="isSaved">
      User updated!
    </v-alert>

    <v-alert type="error" icon="error_outline" v-if="isError">
      {{ errorText }}
    </v-alert>

  </v-form>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { updateUserRole, userUpdateCompany } from "@/graphql/mutations/users";
import { getAllCompaniesFromClient } from "@/graphql/queries/clients";

export default {
  name: "EditUserForm",
  props: {
    mode: {}
  },
  data: () => ({
    roleChanged: false,
    companyChanged: false,
    passwordConfirm: "",
    isLoading: false,
    isError: false,
    isSaved: null,
    errorText: "",
    showPass: false,
    showPass2: false,
    showPass3: false,
    rules: {
      required: value => !!value || "Field is required.",
      min: v => (v && v.length >= 8) || "At least 8 characters"
    },
    user: {
      email: "",
      password: "",
      oldPassword: "",
      permissionRole: {},
      companyId: "",
      companies: []
    },
    companies: []
  }),
  computed: {
    ...mapState("users", ["appUsers", "users"]),
    ...mapState("roles", ["rolesForClient"]),
    ...mapState("clients", ["currentClient"]),
    ...mapGetters("clients", ["isRootClient"]),
    passwordConfirmationRule() {
      return () => (this.user.password === this.passwordConfirm) || "Password must match";
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async onUpdateUser() {
      this.isLoading = true;
      let promises = [];
      if (this.roleChanged) {
        promises.push(this.$apollo.mutate({
          mutation: updateUserRole,
          variables: { id: this.user.id, roleName: this.user.roleName }
        }));
      }
      if (this.companyChanged) {
        promises.push(this.$apollo.mutate({
          mutation: userUpdateCompany,
          variables: { id: this.user.id, companyId: this.user.companyId, companies: this.user.companies }
        }));
      }
      await Promise.all(promises).then(() => {
        this.isSaved = true;
      }).finally(() => {
        this.isLoading = false;
      });
    }
  },
  created() {
    if (this.mode === "app-user") {
      let user = this.appUsers.find((user) => user.id === this.$route.params.id);
      this.user = {
        ...this.user,
        permissionRole: {},
        companies: [],
        ...user
      };
      this.user.companyId = this.user.company.id;
      if (this.currentClient) {
        this.$apollo.query({
          query: getAllCompaniesFromClient,
          variables: {
            clientId: this.currentClient.id
          }
        }).then(data => {
          this.companies = data.data.companies;
        });
        this.$store.dispatch("roles/getRolesForClientForUser", this.currentClient.id);
      }
    }
  }
};
</script>
