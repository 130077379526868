<template>
    <v-card flat class="mb-10">
        <v-layout align-content-start justify-start class="pa-5">
            <div class="d-flex mr-5">
                <active-indicator :is-active="true" :show-label="true" />
            </div>
            <div class="d-flex">
                <active-indicator :is-active="false" :show-label="true" />
            </div>
        </v-layout>
    </v-card>
</template>

<script>
export default {
  name: 'ActiveIndicatorsRow',
  props: {
    mode: {
      required: false
    }
  }
};
</script>
