<template>
    <div>
        <div class="d-flex" v-for="(option, index) in answer" :key="index">
            <div>
                {{option.costestimate}}
            </div>
            <div>
                <div v-for="(image, index) in options.media" :key="index" class="px-2">
                    <template v-if="image.fileType === 'image'">
                        <img :src="`${imagePath}w_300,c_scale/${image.name}`" style="max-width:300px"/>
                    </template>
                    <template v-if="image.fileType === 'video'">
                        <a :href="videoUrl + image.name + '.mp4'" target="_blank">
                            <img style="max-width:50px" src="https://www.freeiconspng.com/uploads/video-play-icon-11.png">
                        </a>
                    </template>
                </div>
                <div v-if="option.media.length === 0">
                    No files added to question
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import constants from '../../../helpers/constants';
export default {
  name: 'GenericAnswerDamage',
  props: {
    answer: {
      required: true
    }
  },
  data: () => ({
    imagePath: constants.cloudinaryUrlOriginal,
    videoUrl: constants.cloudinaryVideo
  })
};
</script>
