<template>
    <div style="background-color:#f5f5f5;" class="pa-3 my-4">
        <v-toolbar flat class="mb-3">
            <v-toolbar-title class="grey--text">Questions</v-toolbar-title>
            <v-spacer></v-spacer>
            <button-add-to-survey :remove-shadow="true" :page-id="pageId" :section-id="sectionId"
                                  button-title="Add question" add-mode="SurveyQuestion"/>
            <v-btn @click="evenWeight">Even all weights</v-btn>
        </v-toolbar>
        <v-list>
            <draggable v-model="questions" @start="startPrioritization" @end="setPrioritization">
                <v-list-item link v-for="(question, index) in cQuestions" :key="question.id"
                             class="u-border-bottom u-drag"
                             :class="{'u-border-bottom-none': (index + 1) === questions.length }">
                    <v-list-item-avatar>
                        <v-avatar
                                :tile="false"
                                :size="30"
                                color="primary"
                        >
                            <span class="white--text">Q</span>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>

                        <v-list-item-title>{{ question.question.en }}</v-list-item-title>
                        <v-text-field label="Weight" v-model="question.weight" @change="weightModified"
                                      type="number"></v-text-field>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-menu bottom left>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        light
                                        icon
                                        small
                                        v-on="on"
                                >
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item link @click="deleteQuestion(question)">
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="!currentQuestions || currentQuestions.length === 0">
                    <v-list-item-content>
                        No questions added
                    </v-list-item-content>
                </v-list-item>
            </draggable>
        </v-list>

        <span class="mt-2">Total weight: {{ totalWeight }}</span>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import EventBus from "../../../../eventBus";
import ButtonAddToSurvey from "../../../UI/Buttons/ButtonAddToSurvey";
import draggable from "vuedraggable";
import {} from "../../../../graphql/mutations/surveys";

export default {
  name: "survey-list-questions",
  props: {
    children: {
      required: true
    },
    sectionId: {
      required: true
    },
    pageId: {
      required: true
    }
  },
  data: () => ({
    showTooltip: false,
    questions: [],
    drag: false,
    cQuestions: []
  }),
  computed: {
    ...mapState("surveys", ["currentSurvey"]),
    ...mapGetters("surveys", ["currentQuestions"]),
    totalWeight() {
      return this.cQuestions.reduce((prev, curr) => prev + parseInt(curr.weight), 0);
    }
  },
  watch: {
    currentQuestions(newValue) {
      this.cQuestions = newValue;
    }
  },
  components: {
    ButtonAddToSurvey,
    draggable
  },
  methods: {
    weightModified() {
      this.$emit('input', this.cQuestions);
    },
    startPrioritization() {
      this.drag = true;
      this.questions = this.cQuestions;
    },
    setPrioritization() {
      this.drag = false;
      this.$emit('input', this.questions);
      this.cQuestions = this.questions;
    },
    evenWeight() {
      let maxWeight = 100;
      let numberOfQuestions = this.cQuestions.length;
      this.cQuestions = this.cQuestions.map((question) => ({
        ...question,
        weight: Math.floor(maxWeight / numberOfQuestions)
      }));
      this.$emit('input', this.cQuestions);
    },
    deleteQuestion(question) {
      this.$store.dispatch("surveys/onDeleteQuestion", question.id).then(() => {
        //Remove from local data
        this.questions = this.questions.filter((child) => {
          return child.id !== question.id;
        });
        EventBus.$emit("surveyUpdated");
      });
    }
  },
  created() {
    this.cQuestions = this.currentQuestions;
    this.questions = this.currentQuestions;
  }
};
</script>
