<template>
    <div class="highlighted-questions mt-4">
        <h3 class="mb-3">Highlighted questions</h3>
        <v-list>
            <v-list-item class="u-border-bottom" :class="{'u-border-bottom-none': (index + 1) === currentSurvey.highlightedQuestions.length }" v-for="(question, index) in currentSurvey.highlightedQuestions" :key="index">
                <v-list-item-content>
                    <v-list-item-title>{{getFieldValue(question.question)}}</v-list-item-title>
                    <generic-answer-options
                            v-if="question.inputType === 'CHECKBOX' || question.inputType === 'RADIO' || question.inputType === 'DROPDOWN'"
                            :answer="question.answer"/>
                    <generic-answer-media v-if="question.inputType === 'MEDIA'"
                                          :answer="question.answer" :show-count="false" :limit="1"/>
                    <generic-answer-date-time :answer="question.answer"
                                              v-if="question.inputType === 'DATETIME'"/>
                    <generic-answer-date :answer="question.answer"
                                         v-if="question.inputType === 'DATE'"/>
                    <generic-answer-cost-estimate :answer="question.answer"
                                                  v-if="question.inputType === 'COSTESTIMATE'"/>
                    <generic-answer-string v-if="question.inputType === 'STRING' ||
                                        question.inputType === 'BOOLEAN' ||
                                        question.inputType === 'CALLSIGN' ||
                                        question.inputType === 'IMO' ||
                                        question.inputType === 'NUMERIC' ||
                                        question.inputType === 'CONFIRM'"
                                           :answer="question.answer"/>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="currentSurvey.highlightedQuestions.length === 0">
                No highlighted questions in survey
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import GenericAnswerCostEstimate from '../../Answers/GenericAnswerCostEstimate';
import GenericAnswerDate from '../../Answers/GenericAnswerDate';
import GenericAnswerDateTime from '../../Answers/GenericAnswerDateTime';
import GenericAnswerMedia from '../../Answers/GenericAnswerMedia';
import GenericAnswerOptions from '../../Answers/GenericAnswerOptions';
import GenericAnswerString from '../../Answers/GenericAnswerString';

import {mapState} from 'vuex';
export default {
  name: 'HighlightedQuestions',
  components: {
    GenericAnswerCostEstimate,
    GenericAnswerDate,
    GenericAnswerDateTime,
    GenericAnswerMedia,
    GenericAnswerOptions,
    GenericAnswerString
  },
  computed: {
    ...mapState('submittedSurveys', ['currentSurvey'])
  },
  methods: {
    getFieldValue(field, options) {
      if (typeof field === 'string') {
        return field;
      }

      let defaultOptions = {defaultLocale: 'en', locale: 'en'};
      if (typeof options === 'string') {
        options = {locale: options};
      }
      options = {...defaultOptions, ...options};
      if (field && field[options.locale]) {
        return field[options.locale];
      }
      if (options.defaultLocale && field && field[options.defaultLocale]) {
        return field[options.defaultLocale];
      }
      if (options.default) {
        return options.default;
      }

      return null;
    },
  }
};
</script>
