<template>
    <v-container class="client-detail show-v-text-field__details">
        <v-layout row wrap>
            <v-flex grow class="pa-3">
                <loading v-if="isLoading"/>
                <v-card flat v-if="!isLoading && !isSaved">
                    <v-card-title>Create client {{client.name}}</v-card-title>
                    <v-divider/>
                    <v-alert type="info" dense tile>All fields are required!</v-alert>
                    <v-card-text>
                        <div class="mb-3">
                            <v-text-field :rules="[rules.required]" label="Client name" placeholder="Client name" v-model="client.name"/>
                        </div>
                        <div class="mb-3">
                            <v-text-field hint="Without https and www" :rules="[rules.required]" label="Domain" placeholder="Domain" v-model="client.subdomain"/>
                        </div>
                        <div class="mb-3">
                            <v-text-field :rules="[rules.required]" hint="eg: #f1f1f1" label="Primary color (hex code)" placeholder="Primary color (hex code)"
                                          v-model="client.color"/>
                        </div>
                        <div style="background-color:#f5f5f5" class="mt-3 mb-3">
                            <div class="text-center" v-if="client.logo">
                                <img :src="logoPath + client.logo" v-if="client.logo" class="my-3 mx-auto"/>
                            </div>
                            <div class="pa-3 text-sm-center">
                                <v-icon :size="80">cloud_upload</v-icon>
                                <br/>
                                <v-btn rounded outlined text depressed color="primary" @click="onUploadLogo">
                                    {{client.logo ? 'Replace logo' : 'Upload logo'}}
                                </v-btn>
                            </div>
                        </div>
                        <v-divider class="my-5"/>
                        <div class="mb-3">
                            <v-text-field label="Account holder" placeholder="Account holder"
                                          v-model="client.accountHolderName"  :rules="[rules.required]"/>
                        </div>
                        <div class="mb-3">
                            <v-text-field type="email" label="Account email" placeholder="Account Email"
                                          v-model="client.accountHolderEmail" :rules="[rules.required]"/>
                        </div>
                        <div class="mb-3">
                            <v-text-field :rules="[rules.required]" label="Account phone" placeholder="Account Phone"
                                          v-model="client.accountHolderPhone"/>
                        </div>
                        <div class="pt-4">
                            <v-btn rounded depressed color="primary" @click="onSaveClient">Save client</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
                <v-alert type="error" prominent v-if="isError">
                    Error: {{errorText}}<br />
                </v-alert>
                <v-alert type="success" prominent icon="error_outline" v-if="isSaved">
                   Client has been created. Next step is to configure the subdomain to the correct DNS
                </v-alert>
            </v-flex>
        </v-layout>
        <div id="upload"></div>
    </v-container>
</template>

<script>
import constants from '../../../helpers/constants';

export default {
  name: 'CreateClient',
  data: () => ({
    logoPath: constants.cloudinaryUrl,
    isSaved: false,
    isError: null,
    errorText: '',
    client: {},
    isLoading: false,
    rules: {
      required: value => !!value || 'Field is required.'
    }
  }),
  methods: {
    onSaveClient() {
      this.isLoading = true;
      this.$store.dispatch('clients/updateClientByID', {
        clientId: null,
        ...this.client
      }).then(() => {
        this.isSaved = true;
        this.isLoading = false;
        this.isError = false;
      }).catch((err) => {
        this.errorText = err;
        this.isError = true;
      });
    },
    onUploadLogo() {
      //eslint-disable-next-line no-undef
      const myWidget = cloudinary.createUploadWidget({
        cloudName: 'survey-associasion',
        apiKey: '634382941652235',
        apiSecret: '-ShNKXyXJHSbSRdNU_W1k9AygyU',
        secure: true,
        sources: ['local', 'url'],
        showPoweredBy: false,
        uploadPreset: 'v4mk0udz',
        folder: 'clients/logos',
        maxImageFileSize: 50000, //500kb
        maxImageWidth: 500,
        styles: {
          palette: {
            window: '#FFF',
            windowBorder: '#90A0B3',
            tabIcon: '#0E2F5A',
            menuIcons: '#5A616A',
            textDark: '#000000',
            textLight: '#FFFFFF',
            link: '#0078FF',
            action: '#FF620C',
            inactiveTabIcon: '#0E2F5A',
            error: '#F44235',
            inProgress: '#0078FF',
            complete: '#20B832',
            sourceBg: '#E4EBF1'
          },
          fonts: {
            "'Rajdhani'": 'https://fonts.googleapis.com/css?family=Rajdhani'
          }
        }
      }, (error, result) => {
        if (result && result.event === 'success') {
          this.$nextTick(() => {
            this.client.logo = result.info.public_id;
          });
        } else {
          window.console.log(error, result);
        }
      });

      myWidget.open();
    }
  }
};
</script>
