<template>
    <v-container>
        <v-layout row>
            <v-flex xs12>

                <loading v-if="isLoading"/>

                <v-card flat class="mx-auto" v-if="!isLoading">
                    <helper-offset :inline="true"
                                   :full-width="false"
                                   :offset="24">
                        <v-card color="primary"
                                class="v-card--material__header d-flex align-center mb-5"
                                dark
                                elevation="10"
                                min-height="80"
                        >
                            <v-card-title>
                                <h4 class="title font-weight-light mb-2">Team users</h4>
                            </v-card-title>
                        </v-card>
                    </helper-offset>
                    <v-divider></v-divider>
                    <v-simple-table width="100%" class="mb-10 table-list">
                        <thead>
                        <tr>
                            <th width="10"></th>
                            <th width="50"></th>
                            <th class="text-left table-list__heading-sortable"><span>User info <v-icon>expand_more</v-icon></span>
                            </th>
                            <th class="text-left table-list__heading-sortable"><span>Email <v-icon>expand_more</v-icon></span>
                            </th>
                            <!--<th class="text-left table-list__heading-sortable"><span>Status <v-icon>expand_more</v-icon></span>
                            </th>-->
                            <th class="text-left table-list__heading-sortable"><span>Created at <v-icon>expand_more</v-icon></span>
                            </th>
                            <th class="text-right"><span>Actions</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(user, index) in filteredUsers" :key="index">
                            <td width="10">
                                <active-indicator :isActive="user.isActivated"/>
                            </td>
                            <td width="50">
                                <v-avatar color="grey lighten-4" :tile="false" :size="40">
                                    <span>{{user.name.substring(0,1)}}</span>
                                </v-avatar>
                            </td>
                            <td>
                                {{user.name}}
                                <!--<router-link :to="{name: 'edit-user', params: {id: user.id, mode: 'admin'} }">

                                </router-link>-->
                            </td>
                            <td>{{user.email}}</td>
                            <td>{{user.createdAt | formatDateWithTime}}</td>
                            <td class="align-right d-flex justify-end align-center table-list__actions">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                                light
                                                icon
                                                small
                                                v-on="on"
                                        >
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item link :to="{name: 'edit-user', params: {id: user.id}}" v-if="loggedInUserId === user.id">
                                            <v-list-item-title>Edit user</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item link @click="onDeleteUser(user.id)">
                                            <v-list-item-title>Delete user</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-flex>
        </v-layout>
        <confirm-delete-user v-if="showConfirmDeleteUserModal" :userId="selectedUserId" @userDeleted="fetchUsers"/>
    </v-container>
</template>

<script>
import EventBus from '../../eventBus';
import ConfirmDeleteUser from './Modals/ConfirmDeleteUser';
import {mapState} from 'vuex';

export default {
  name: 'UserListOverview',
  data: () => ({
    isLoading: false,
    selectedUserId: '',
    loggedInUserId: localStorage.getItem('USER_ID')
  }),
  components: {ConfirmDeleteUser},
  computed: {
    ...mapState('users', ['users', 'searchQuery', 'currentUser']),
    ...mapState('modals', ['showConfirmDeleteUserModal']),
    filteredUsers() {
      return this.users.filter((user) => {
        if (this.searchQuery.length > 2 && user.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
          return user;
        } else {
          return user;
        }
      });
    }
  },
  methods: {
    onDeleteUser(id) {
      this.selectedUserId = id;
      this.$store.dispatch('modals/toggleModal', 'showConfirmDeleteUserModal');
    },
    fetchUsers() {
      this.$store.dispatch('users/getAllCMSUsers').then((response) => {
        this.isLoading = false;
      });
    }
  },
  created() {
    this.isLoading = true;
    this.fetchUsers();
  },
  mounted() {
    EventBus.$on('userInvited', () => {
      this.fetchUsers();
    });
  }
};
</script>
