import {apolloClient} from "../../apollo/init-apollo";
import {getRoleById, getRolesForClientForUser} from "@/graphql/queries/roles";
import {deleteRole} from "@/graphql/mutations/roles";

export const state = {
  rolesForClient: [],
  lastClientId: null,
  currentRole: null,
};

export const getters = {};

export const mutations = {
  setRolesForClient(state, payload) {
    state.rolesForClient = payload;
  },
  setLastClientId(state, payload) {
    state.lastClientId = payload;
  },
  setCurrentRole(state, role) {
    state.currentRole = role;
  },
};

export const actions = {
  async getRolesForClient({commit, rootState, state, dispatch}, clientId) {
    if (!clientId) {
      clientId = rootState.clients.currentClient.id;
    }

    dispatch("getRolesForClientForUser", clientId);
  },
  async getRolesForClientSurvey(
    {commit, rootState, state, dispatch},
    clientId
  ) {
    if (!clientId) {
      console.log({rootState: JSON.parse(JSON.stringify(rootState))});
      clientId = rootState.surveys.surveyEditClientId;
    }

    return dispatch("getRolesForClientForUser", clientId);
  },
  async getRolesForClientForUser({commit}, clientId) {
    commit("setLastClientId", clientId);
    commit("setRolesForClient", []);
    const response = await apolloClient.query({
      query: getRolesForClientForUser,
      variables: {
        clientId,
      },
      fetchPolicy: "no-cache",
    });
    if (response && !response.loading) {
      commit("setRolesForClient", response.data.roles);
    }
  },
  async deleteRole({commit, state, dispatch}, {id}) {
    try {
      let reloadRoles = false;
      if (state.rolesForClient.find((role) => role.id === id)) {
        reloadRoles = true;
      }
      apolloClient
        .mutate({
          mutation: deleteRole,
          variables: {
            roleId: id,
          },
          fetchPolicy: "no-cache",
        })
        .then(() => {
          if (reloadRoles && state.lastClientId) {
            dispatch("getRolesForClient", state.lastClientId);
          }
        });
    } catch (err) {
      return err;
    }
  },
  async getById({commit}, id) {
    const {data} = await apolloClient.query({
      query: getRoleById,
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    });

    try {
      commit("setCurrentRole", data.role);
      return data.role;
    } catch (e) {
      return e;
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
