<template>
  <v-list>
    <small style="font-weight:300;" class="pl-4">Answer</small>
    <template v-if="images && images.length">
      <v-list-item v-for="(image, index) in images" :key="index">
        <v-list-item-avatar>
          <template v-if="image.fileType === 'image'">
            <a :href="imageUrl + image.name" target="_blank">
              <img :src="imageUrl + image.name" style="max-width: 50px" alt="Open image" title="Open image" />
            </a>
          </template>
          <template v-if="image.fileType === 'pdf'">
            <a :href="imageUrl + image.name" target="_blank">
              <img :src="imageUrl + image.name + '.png'" style="max-width: 50px" alt="Open image" title="Open image" />
            </a>
          </template>
          <template v-if="image.fileType === 'video'">
            <a :href="videoUrl + image.name + '.mp4'" target="_blank">
              <img style="max-width:50px" src="https://www.freeiconspng.com/uploads/video-play-icon-11.png">
            </a>
          </template>
        </v-list-item-avatar>
        <v-list-item-content v-if="isRootClient">
          <v-text-field :value="getFieldValue(image.description)" label="Image text"
                        @blur="onUpdateImageText($event, image)"></v-text-field>
        </v-list-item-content>
        <v-list-item-action v-if="isRootClient">
          <v-switch v-model="image.inReport" label="In report" @change="onUpdateReport(image)"></v-switch>
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-list-item v-if="!images || images.length === 0">
      No files to this question
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import constants from "../../../../helpers/constants";
import GetFieldValueMixin from "@/mixins/GetFieldValueMixin";

export default {
  name: "AnswerMedia",
  mixins: [GetFieldValueMixin],
  props: {
    images: {
      required: true
    }
  },
  data: () => ({
    imageUrl: constants.cloudinaryUrl,
    videoUrl: constants.cloudinaryVideo
  }),
  computed: {
    ...mapState("submittedSurveys", ["currentSurvey"]),
    ...mapGetters("clients", ["isRootClient"])
  },
  methods: {
    onUpdateImageText(evt, image) {
      const description = evt.target.value;
      this.$store.dispatch("submittedSurveys/setDescriptionToImage", {
        instanceId: this.currentSurvey.instanceId,
        fileId: image.id,
        description: description
      }).then(() => {
        this.$emit("imageUpdated");
      }).catch(() => this.$emit("errorImageUpdated"));
    },
    onUpdateReport(img) {
      this.$store.dispatch("submittedSurveys/setImageInReport", {
        instanceId: this.currentSurvey.instanceId,
        fileId: img.id,
        inReport: img.inReport
      }).then(() => {
        this.$emit("imageUpdated");
      }).catch(() => this.$emit("errorImageUpdated"));
    }
  }
};
</script>
