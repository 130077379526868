export function serial(tasks) {
  console.log(tasks);
  return tasks.reduce((promiseChain, currentTask) => {
    return promiseChain.then((chainResults) => {
      if (typeof currentTask === "function") {
        currentTask = currentTask();
      }
      return currentTask.then((currentResult) => [
        ...chainResults,
        currentResult,
      ]);
    });
  }, Promise.resolve([]));
}
