<template>
  <div class="d-flex flex-wrap" :class="{'text-center': center}">
    <template v-if="showCount">
      <span v-if="answer">{{ answer.length }} file(s)</span>
      <span v-else>Not answered</span>
    </template>
    <template v-if="!showCount">
      <div v-for="(image, index) in answer" :key="index" class="px-2" :class="{'mx-auto': center}">
        <template v-if="image.inReport">
          <template v-if="limit && index === parseInt(limit - 1)">
            <template v-if="image.fileType === 'image'">
              <a :href="`${imagePath}${image.name}`" target="_blank">
                <img :src="`${imagePath}w_300,c_scale/${image.name}.png`"
                     style="max-width: 300px"  alt=""/>
              </a>
            </template>
            <template v-if="image.fileType === 'pdf'">
              <a :href="imagePath + image.name" target="_blank">
                <img :src="`${imagePath}w_300,c_scale/${image.name}.png`" style="max-width:300px"  alt=""/>
              </a>
            </template>
            <template v-if="image.fileType === 'video'">
              <a :href="videoUrl + image.name + '.mp4'" target="_blank">
                <img style="max-width:50px"
                     src="https://www.freeiconspng.com/uploads/video-play-icon-11.png" alt="">
              </a>
            </template>
          </template>
          <template v-else-if="!limit">
            <template v-if="image.fileType === 'image'">
              <a :href="imagePath + image.name" target="_blank">
                <img :src="`${imagePath}w_300,c_scale/${image.name}.png`" style="max-width:300px"  alt=""/>
              </a>
            </template>
            <template v-if="image.fileType === 'pdf'">
              <a :href="imagePath + image.name" target="_blank">
                <img :src="`${imagePath}w_300,c_scale/${image.name}.png`" style="max-width:300px"  alt=""/>
              </a>
            </template>
            <template v-if="image.fileType === 'video'">
              <a :href="videoUrl + image.name + '.mp4'" target="_blank">
                <img style="max-width:50px"
                     src="https://www.freeiconspng.com/uploads/video-play-icon-11.png" alt="">
              </a>
            </template>
          </template>
        </template>
      </div>
      <div v-if="!answer || answer.length === 0">
        No files added to question
      </div>
    </template>
  </div>
</template>

<script>
import constants from "../../../helpers/constants";

export default {
  name: "GenericAnswerMedia",
  props: {
    answer: {
      required: true
    },
    showCount: {
      default: false
    },
    limit: {
      required: false
    },
    center: {
      default: false
    }
  },
  data: () => ({
    imagePath: constants.cloudinaryUrlOriginal,
    videoUrl: constants.cloudinaryVideo
  })
};
</script>
