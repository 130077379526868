<template>
  <v-layout row wrap>
    <v-flex grow class="pa-3">
      <v-card flat class="mx-auto mb-5">
        <helper-offset :inline="true"
                       :full-width="false"
                       :offset="24">
          <v-card color="primary"
                  class="v-card--material__header d-flex align-center mb-5"
                  dark
                  elevation="10"
                  min-height="80"
          >
            <v-card-title>
              <h4 class="title font-weight-light mb-2">All {{status | formatStatus }} surveys</h4>
            </v-card-title>
          </v-card>
        </helper-offset>

        <v-simple-table width="100%" class="table-list" v-if="filteredSurveys.length > 0">
          <thead>
          <tr>
            <th width="10"></th>
            <th class="text-left table-list__heading-sortable"><span
                @click="sortBy('ship', sortingDirs['ship'])">SHIP/IMO</span>
              <v-icon v-if="sortingDirs['ship'] === 'DESC'">expand_less</v-icon>
              <v-icon v-else>expand_more</v-icon>
            </th>
            <th class="text-left table-list__heading-sortable"><span @click="sortBy('client', sortingDirs['client'])">Client</span>
              <v-icon v-if="sortingDirs['client'] === 'DESC'">expand_less</v-icon>
              <v-icon v-else>expand_more</v-icon>
            </th>
            <th class="text-left "><span>Survey Name</span></th>
            <th class="text-left"><span >Status</span></th>
            <th class="text-left"><span>Uploads</span></th>
            <th class="text-left table-list__heading-sortable"><span
                @click="sortBy('date',sortingDirs['date'] )">Submitted at</span>
              <v-icon v-if="sortingDirs['date'] === 'DESC'">expand_less</v-icon>
              <v-icon v-else>expand_more</v-icon>
            </th>
            <th class="text-right"><span>Actions</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in filteredSurveys" :key="index"
              :class="{'is-completed': item.status === 'CLOSED'}">
            <td>
              <status-indicator :status="item.status" />
            </td>
            <td>
              <router-link :to="{name: 'submitted-survey', params: {surveyId: item.id } }"
                           v-if="item.status !== 'OPEN'">
                {{ item.ship || "N/A Ship" }} - {{ item.imo || "N/A IMO" }}
              </router-link>
              <span v-else>                {{ item.ship || "N/A Ship" }} - {{ item.imo || "N/A IMO" }}
</span>
            </td>
            <td>
                            <span style="max-width:150px; display: block">
                            {{ item.client.name || "NA" }}
                            </span>
            </td>
            <td>
                            <span style="max-width:180px; display: block">
                                <router-link :to="{name: 'submitted-survey', params: {surveyId: item.id } }"
                                             v-if="item.status !== 'OPEN'">
                                    {{ getFieldValue(item.title, "en") || "NA" }}
                                </router-link>
                                <span v-if="item.status === 'OPEN'">
                                    <v-icon class="mr-2" color="primary">lock</v-icon>
                                    {{ getFieldValue(item.title, "en") || "NA" }}
                                </span>
                                <template v-if="item.lastSubmittedBy">
                                    <br />
                                    by <a
                                    :href="'mailto:'+item.lastSubmittedBy.email">{{
                                    item.lastSubmittedBy.firstName
                                  }} {{ item.lastSubmittedBy.lastName }}</a>
                                </template>
                            </span>
            </td>
            <td class="text-capitalize">{{ item.status | formatStatus }}</td>
            <td>
              <v-icon color="success" v-if="item.uploadStatus === 'DONE'">done</v-icon>
              <v-icon color="error" v-if="item.uploadStatus === 'PENDING'">error_outline</v-icon>
            </td>
            <td>{{ item.createdAt | formatDateWithTime }}</td>
            <td class="align-right d-flex justify-end align-center table-list__actions">
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn
                      light
                      icon
                      small
                      v-on="on"
                      class="mt-1">
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list >
                <v-list-item link @click="changeStatus(item.id, 'CANCELLED')"
                             v-if="!['CLOSED','CANCELLED','PENDING_FINALIZATION'].includes(item.status)&& isRootClient">
                  <v-list-item-title>Mark as cancelled</v-list-item-title>
                </v-list-item>

                  <template v-if="item.uploadStatus === 'DONE'">
                  <v-list-item link @click="onCompleteSurvey(item.id, item.status)"
                               v-if="item.status !== 'OPEN' && item.status !== 'CLOSED' && isRootClient">
                    <v-list-item-title>Mark as completed</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="onReopenSurvey(item.id)"
                               v-if="item.status !== 'OPEN' && item.status !== 'CLOSED' && isRootClient">
                    <v-list-item-title>Reopen survey</v-list-item-title>
                  </v-list-item>
                  <v-list-item link :to="{name: 'submitted-survey', params: {surveyId: item.id } }"
                               v-if="item.status !== 'CLOSED' && item.status !== 'OPEN' && isRootClient">
                    <v-list-item-title>View survey</v-list-item-title>
                  </v-list-item>
                  <v-list-item target="_blank" link
                               :to="{name: 'survey-report', params: {surveyId: item.id } }">
                    <v-list-item-title v-if="item.status !== 'CLOSED' && item.status !== 'OPEN'">
                      View report
                    </v-list-item-title>
                    <v-list-item-title v-else>Preview report</v-list-item-title>
                  </v-list-item>
                  </template>
                  <template v-else ><v-list-item>
                    <v-list-item-content>

                      <v-list-item-subtitle>
                        Uploads are still pending
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item></template>
                </v-list>

              </v-menu>
            </td>
          </tr>
          </tbody>
        </v-simple-table>

        <empty-state error-text="There are currently no surveys in this status" class="mt-10"
                     v-if="filteredSurveys.length === 0" />
      </v-card>
    </v-flex>
    <confirm-complete-survey v-if="showCompleteSurveyModal" :surveyId="currentSurveyId" :survey-status="currentSurveyStatus" />
    <confirm-reopen-survey v-if="showReopenSurveyModal" :surveyId="currentSurveyId" />
  </v-layout>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import ConfirmCompleteSurvey from "./Modals/ConfirmCompleteSurvey";
import ConfirmReopenSurvey from "./Modals/ConfirmReopenSurvey";
import EventBus from "@/eventBus";

export default {
  name: "surveys-submitted",
  data: () => ({
    currentSurveyId: null,
    currentSurveyStatus: null,
    sortingDirs: {
      date: 'ASC',
      ship: 'ASC',
      client: 'ASC',
    },
  }),
  components: { ConfirmReopenSurvey, ConfirmCompleteSurvey },
  computed: {
    ...mapGetters("submittedSurveys", ["filteredSurveys"]),
    ...mapGetters("clients", ["isRootClient"]),
    ...mapState("modals", ["showReopenSurveyModal", "showCompleteSurveyModal"]),
    ...mapState("submittedSurveys", ["status"]),
  },
  methods: {
    ...mapMutations("submittedSurveys", ["setSortBy"]),
    onCompleteSurvey(surveyId, status) {
      this.currentSurveyId = surveyId;
      this.currentSurveyStatus = status;
      this.$store.dispatch("modals/toggleModal", "showCompleteSurveyModal");
    },
    onReopenSurvey(surveyId) {
      this.currentSurveyId = surveyId;
      this.$store.dispatch("modals/toggleModal", "showReopenSurveyModal");
    },
    changeStatus(surveyId, status) {
      if (window.confirm(`Are you sure you want to ${status}`)) {
        this.$store.dispatch('submittedSurveys/changeStatus', {id: surveyId, status: status}).then(() => {
          EventBus.$emit('reloadSubmittedSurveys');
        }).catch((err) => {
          console.error(err);
        });
      }
    },
    sortBy(sorting, direction) {
      this.sortingDirs[sorting] = direction === 'ASC' ? 'DESC' : 'ASC';
      this.setSortBy({sorting, direction});
    },
    getFieldValue(field, options) {
      let defaultOptions = { defaultLocale: "en" };
      if (typeof options === "string") {
        options = { locale: options };
      }
      options = { ...defaultOptions, ...options };
      if (field && field[options.locale]) {
        return field[options.locale];
      }
      if (options.defaultLocale && field && field[options.defaultLocale]) {
        return field[options.defaultLocale];
      }
      if (options.default) {
        return options.default;
      }

      return null;
    }
  }
};
</script>
