<template>
    <transition
            :name="transitionName"
            :mode="transitionMode"
            :enter-active-class="transitionEnterActiveClass"
            @beforeLeave="beforeLeave"
            @enter="enter"
            @afterEnter="afterEnter"
    >
        <slot/>
    </transition>
</template>

<script>
const DEFAULT_TRANSITION = `slide`;
const DEFAULT_TRANSITION_MODE = `out-in`;

export default {
  name: `PageTransition`,
  data() {
    return {
      prevHeight: 0,
      transitionName: DEFAULT_TRANSITION,
      transitionMode: DEFAULT_TRANSITION_MODE,
      transitionEnterActiveClass: ``
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName || DEFAULT_TRANSITION;
      const toDepth = to.path.split(`/`).length;
      const fromDepth = from.path.split(`/`).length;

      if (transitionName === `slide`) {
        transitionName = toDepth < fromDepth ? `slide-right` : `slide-left`;
      }

      if (transitionName === 'fade') {
        transitionName = toDepth < fromDepth ? `fade` : `fade`;
      }

      this.transitionMode = DEFAULT_TRANSITION_MODE;
      this.transitionEnterActiveClass = `${transitionName}-enter-active`;

      this.transitionName = transitionName;
      next();
    });
  },
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const {height} = getComputedStyle(element);
      //eslint-disable-next-line no-param-reassign
      element.style.height = this.prevHeight;
      setTimeout(() => {
        //eslint-disable-next-line no-param-reassign
        element.style.height = height;
      });
    },
    afterEnter(element) {
      //eslint-disable-next-line no-param-reassign
      element.style.height = `auto`;
    }
  }
};
</script>

<style lang="scss">
    .slide-left-enter-active,
    .slide-left-leave-active,
    .slide-right-enter-active,
    .slide-right-leave-active {
        transition-duration: 0.5s;
        transition-property: height, opacity, transform;
        transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
        overflow: hidden;
    }
    .slide-left-enter,
    .slide-right-leave-active {
        opacity: 0;
        transform: translate(2em, 0);
    }
    .slide-left-leave-active,
    .slide-right-enter {
        opacity: 0;
        transform: translate(-2em, 0);
    }

    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: height, opacity;
        transition-timing-function: ease;
        overflow: hidden;
    }
    .fade-enter,
    .fade-leave-active {
        opacity: 0
    }
</style>
