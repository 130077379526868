import {
  userLoginMutation,
  userForgotPassword,
  userResetPassword,
  inviteUser,
  deleteUser, appUserReject, appUserDelete, appUserActivate
} from '../../graphql/mutations/users';
import {getAllAppUsers, getAllCMSUsers} from '../../graphql/queries/users';
import {apolloClient} from '../../apollo/init-apollo';
import {clearUserStorage, createUserStorage} from '../../helpers/authHelpers';

export const state = {
  currentUser: null,
  users: [],
  appUsers: [],
  searchQuery: ''
};

export const getters = {};

export const mutations = {
  setCurrentUser(state, data) {
    if (data) {
      state.currentUser = data.admin;
    }
  },
  setCurrentUserInStorage(state, data) {
    if (data) {
      createUserStorage(data);
    }
  },
  setCMSUsers(state, payload) {
    state.users = payload;
  },
  setSearchQuery(state, query) {
    state.searchQuery = query;
  },
  setAppUsers(state, users) {
    state.appUsers = users;
  }
};

export const actions = {
  async onLoginUser({commit}, userData) {
    clearUserStorage();

    const response = await apolloClient.mutate({
      mutation: userLoginMutation,
      variables: {
        email: userData.email,
        password: userData.password
      }
    });

    try {
      const user = response.data.adminLogin;
      if (user) {
        commit('setCurrentUser', user);
        commit('setCurrentUserInStorage', user);
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  //Triggers email to reset password
  async onForgotPassword({commit}, email) {
    const response = await apolloClient.mutate({
      mutation: userForgotPassword,
      variables: {
        email: email
      }
    });
    try {
      return response;
    } catch (e) {
      return e;
    }
  },
  async onResetPassword({commit}, data) {
    const response = await apolloClient.mutate({
      mutation: userResetPassword,
      variables: {
        email: data.email,
        token: localStorage.getItem('AUTH_TOKEN'),
        password: data.password
      }
    });
    try {
      return response;
    } catch (e) {
      return e;
    }
  },
  async getAllCMSUsers({commit}) {
    const response = await apolloClient.query({
      query: getAllCMSUsers,
      fetchPolicy: 'no-cache'
    });
    try {
      if (response && !response.loading) {
        commit('setCMSUsers', response.data.admins);
      }
    } catch (err) {
      return err;
    }
  },
  async inviteUser({commit}, data) {
    const response = await apolloClient.mutate({
      mutation: inviteUser,
      variables: {
        email: data.email,
        name: data.name
      }
    });

    try {
      return response;
    } catch (e) {
      return e;
    }
  },
  async deleteUserFromCMS({commit}, id) {
    const response = await apolloClient.mutate({
      mutation: deleteUser,
      variables: {
        id: id
      }
    });
    try {
      return response;
    } catch (e) {
      return e;
    }
  },
  async getAppUsers({commit}) {
    const {data} = await apolloClient.query({
      query: getAllAppUsers,
      fetchPolicy: 'no-cache'
    });

    try {
      commit('setAppUsers', data.users);
    } catch (e) {
      return e;
    }
  },
  async getAppUsersByClient({commit}, id) {
    const {data} = await apolloClient.query({
      query: getAllAppUsers,
      fetchPolicy: 'no-cache',
      variables: {
        clientId: id
      }
    });

    try {
      commit('setAppUsers', data.users);
    } catch (e) {
      return e;
    }
  },
  async appUserReject({commit}, id) {
    const {data} = await apolloClient.mutate({
      mutation: appUserReject,
      variables: {
        id: id
      }
    });

    try {
      return data;
    } catch (e) {
      return e;
    }
  },
  async appUserDelete({commit}, id) {
    const {data} = await apolloClient.mutate({
      mutation: appUserDelete,
      variables: {
        id: id
      }
    });

    try {
      return data;
    } catch (e) {
      return e;
    }
  },
  async appUserActivate({commit}, id) {
    const {data} = await apolloClient.mutate({
      mutation: appUserActivate,
      variables: {
        id: id
      }
    });

    try {
      return data;
    } catch (e) {
      return e;
    }
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
