<template>
    <v-tabs class="client-detail__content">
        <v-tab>
            Client
        </v-tab>
        <v-tab>
            Companies
        </v-tab>
        <v-tab v-if="isRootClient">
            Team users
        </v-tab>
        <v-tab v-if="isRootClient">
            App users
        </v-tab>
        <v-tab-item class="client-info">
            <client-info class="client-info__content" />
            <client-branding class="client-branding" />
        </v-tab-item>
        <v-tab-item>
            <client-companies />
        </v-tab-item>
        <v-tab-item v-if="isRootClient">
            <client-team />
        </v-tab-item>
        <v-tab-item v-if="isRootClient">
            <client-app-users />
        </v-tab-item>
    </v-tabs>
</template>

<script>
import {mapGetters} from 'vuex';
import ClientInfo from './ClientDetail/ClientInfo';
import ClientBranding from './ClientDetail/ClientBranding';
import ClientTeam from './ClientDetail/ClientTeam';
import ClientAppUsers from './ClientDetail/ClientAppUsers';
import ClientCompanies from './ClientDetail/ClientCompanies';

export default {
  name: 'ClientDetailContent',
  components: {ClientCompanies, ClientAppUsers, ClientTeam, ClientBranding, ClientInfo},
  computed: {
    ...mapGetters('clients', ['isRootClient'])
  }
};
</script>
