<template>
    <div>
        <v-progress-linear
                v-if="isLoading"
                :indeterminate="isLoading"
                :loading="isLoading"
                absolute
                top
                color="primary"
        ></v-progress-linear>
        <v-tabs>
            <!-- TABS -->
            <v-tab v-if="isRootClient">Summary</v-tab>
            <v-tab>Info</v-tab>
            <v-tab v-if="vesselData">Vessel data</v-tab>
            <v-tab v-if="isRootClient && currentSurvey.survey.type === 'CONDITION'">Ratings</v-tab>
            <v-tab v-if="isRootClient">Log</v-tab>
            <v-tab v-if="isRootClient">Attachments</v-tab>
            <!-- ITEMS CONTENT -->
            <v-tab-item v-if="isRootClient">
                <div class="pa-3 py-10">
                    <p>This is the survey. Here you can add/edit the summary and conclusion/closing remarks of the
                        survey, which
                        will in the end go in
                        the generated report.</p>
                    <v-form>
                        <v-textarea
                                v-model="summary"
                                auto-grow
                                filled
                                color="primary"
                                label="Summary"
                                rows="4"
                                class="mb-2"
                        ></v-textarea>
                        <v-textarea
                                v-model="conclusion"
                                auto-grow
                                filled
                                color="primary"
                                label="Conclusion"
                                rows="4"
                                class="mb-2"
                        ></v-textarea>
                        <v-btn color="primary" :disabled="isLoading" small :outlined="!this.hasChanges" rounded
                               @click="submitData">
                            Update
                        </v-btn>
                    </v-form>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-3">
                    <div style="background-color:#f1f1f1" class="pa-3 mb-3">
                        <info :survey="currentSurvey.survey"/>
                        <highlighted-questions/>
                        <div class="mt-4 ">
                            <v-btn link target="_blank"
                                   :to="{name: 'survey-report', params: {surveyId: currentSurvey.instanceId } }">View
                                Report
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-tab-item>
            <v-tab-item v-if="vesselData">
                <div class="pa-3">
                    <list-vessel-data :vessel-data="vesselData"/>
                </div>
            </v-tab-item>
            <v-tab-item v-if="isRootClient && currentSurvey.survey.type === 'CONDITION'">
                <div class="pa-3">
                    <rating/>
                </div>
            </v-tab-item>
            <v-tab-item v-if="isRootClient">
                <div class="pa-3">
                    <log/>
                </div>
            </v-tab-item>
            <v-tab-item v-if="isRootClient">
                <div class="pa-3 py-10">
                    <p>Here you can add attachments to the survey. They will be included in a separate section of the
                        report</p>
                    <v-btn small @click="addAttachment()">Add Attachment</v-btn>
                    <v-list>
                        <v-list-item
                                :style="`background-color: ${index % 2 === 0 ? 'rgba(0,0,0,0.05)' : 'transparent'}`"
                                v-for="(attachment,index) in attachments" :key="index">
                            <v-list-item-content>
                                <v-list-item-title>{{ attachment.title }}</v-list-item-title>
                                <v-text-field v-model="attachment.title"
                                              placeholder="You need to write a title for the attachment"
                                              aria-required="true" required label="Attachment title"/>
                                <v-textarea v-model="attachment.description" label="Attachment description"/>
                                <div class="text-center" v-if="attachment.file">
                                    <img :src="`${cloudinaryUrl}${attachment.file}.png`" style="max-width:350px;"
                                         class="my-3 mx-auto"/>
                                </div>
                                <v-btn @click="uploadAttachment(attachment)">
                                    <template v-if="attachment.file">Replace</template>
                                    <template v-else>Upload</template>
                                    Attachment
                                </v-btn>
                                <v-btn color="error" @click="removeAttachment(index)" class="mt-2">
                                    Remove Attachment
                                </v-btn>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-btn color="primary" :disabled="isLoading" small :outlined="!this.hasChanges" rounded
                           @click="submitData">
                        Update
                    </v-btn>
                </div>
            </v-tab-item>
        </v-tabs>

        <v-snackbar color="primary" :bottom="y === 'bottom'" v-model="isSaved">
            Details have been updated
            <v-btn color="white" text @click="isSaved = false">
                Close
            </v-btn>
        </v-snackbar>

        <v-snackbar color="error" :bottom="y === 'bottom'" v-model="isError">
            Error updating. Please try again or contact support
            <v-btn color="white" text @click="isError = false">
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import EventBus from "../../../eventBus";
import {mapGetters, mapState} from "vuex";
import Info from "./Survey/Info";
import HighlightedQuestions from "./Survey/HighlightedQuestions";
import Log from "./Survey/Log";
import Rating from "./Survey/Rating";
import ListVesselData from "../VesselData/ListVesselData";
import {ConfirmLeavingMixin} from "@/helpers/mixins/ConfirmLeaving";
import {serial} from "@/helpers/promise";
import constants from "@/helpers/constants";
import {surveyInstanceAddAttachment} from "@/graphql/mutations/submitted-surveys";
import {apolloClient} from "@/apollo/init-apollo";

export default {
  name: "survey-editor-survey",
  components: {ListVesselData, Rating, Log, HighlightedQuestions, Info},
  mixins: [ConfirmLeavingMixin],
  data: () => ({
    timeout: 6000,
    y: "top",
    summary: "",
    conclusion: "",
    isLoading: false,
    isSaved: null,
    isError: false,
    vesselData: null,
    attachments: [],
    changed: {
      summary: false,
      conclusion: false,
      attachments: false
    }
  }),
  watch: {
    summary(newVal) {
      this.changed.summary = this.currentSurvey?.survey?.summary !== newVal;
      this.emitIfDirty();
    },
    conclusion(newVal) {
      this.changed.conclusion = this.currentSurvey?.survey?.conclusion !== newVal;
      this.emitIfDirty();
    },
    attachments: {
      deep: true,
      handler(newVal) {
        console.log(JSON.stringify(this.currentSurvey?.survey?.attachments), JSON.stringify(newVal));
        this.changed.attachments = JSON.stringify(this.currentSurvey?.survey?.attachments) !== JSON.stringify(newVal);
        this.emitIfDirty();
      }
    }
  },
  computed: {
    ...mapState("submittedSurveys", ["currentSurvey"]),
    ...mapGetters("clients", ["isRootClient"]),
    hasChanges() {
      return !!Object.values(this.changed).find(f => f);
    },
    cloudinaryUrl() {
      return constants.cloudinaryUrl;
    }
  },
  methods: {
    addAttachment() {
      this.attachments.push({title: "", key: Date.now(), file: ""});
    },
    removeAttachment(index) {
      let newAttachments = [].concat(this.attachments);
      newAttachments.splice(index, 1);
      this.attachments = newAttachments;
    },
    uploadAttachment(attachment) {
      const myWidget = cloudinary.createUploadWidget({
        cloudName: "survey-associasion",
        apiKey: "634382941652235",
        apiSecret: "-ShNKXyXJHSbSRdNU_W1k9AygyU",
        secure: true,
        sources: ["local", "url"],
        showPoweredBy: false,
        uploadPreset: "v4mk0udz",
        folder: `surveyAttachments/${this.currentSurvey.instanceId}`,
        maxImageFileSize: 5000000, //50000kb
        maxImageWidth: 5000,
        styles: {
          palette: {
            window: "#FFF",
            windowBorder: "#90A0B3",
            tabIcon: "#0E2F5A",
            menuIcons: "#5A616A",
            textDark: "#000000",
            textLight: "#FFFFFF",
            link: "#0078FF",
            action: "#FF620C",
            inactiveTabIcon: "#0E2F5A",
            error: "#F44235",
            inProgress: "#0078FF",
            complete: "#20B832",
            sourceBg: "#E4EBF1"
          },
          fonts: {
            "'Rajdhani'": "https://fonts.googleapis.com/css?family=Rajdhani"
          }
        }
      }, (error, result) => {
        if (result && result.event === "success") {
          this.$nextTick(() => {
            attachment.file = result.info.public_id;
            switch (result.info.format) {
            case 'pdf':
              attachment.fileType = 'pdf';
              break;
            default:
              attachment.fileType = 'image';
            }
          });
        } else {
          window.console.log(error, result);
        }
      });

      myWidget.open();
    },
    emitIfDirty() {
      this.$emit("dirty", !!Object.values(this.changed).find(f => f));
    },
    setCompletedState() {
      EventBus.$emit("surveyUpdated");

      //A little hack as we cant fetch instantly, as server is doing in background...
      //TODO: Talk to BE about this. - Also see: SubmittedSurvey.vue
      setTimeout(() => {
        this.isLoading = false;
        this.isError = false;
        this.isSaved = true;
        this.$emit("dirty", false);
        this.setSummaryAndConclusion();
        this.setAttachments();
        this.setVesselData();
      }, 1000);
    },
    submitData() {
      this.isLoading = true;
      let promises = [];
      if (this.summary && this.changed.summary) {
        promises.push(() => {
          console.log("Adding Summary");
          return this.$store.dispatch("submittedSurveys/commentSet", {
            content: this.summary,
            type: "SUMMARY",
            instanceId: this.currentSurvey.instanceId
          }).then(() => {
            console.log("Added summary");
          });
        });
      }
      if (this.conclusion && this.changed.conclusion) {
        promises.push(() => {
          console.log("Adding conclusion");

          return this.$store.dispatch("submittedSurveys/commentSet", {
            content: this.conclusion,
            type: "CONCLUSION",
            instanceId: this.currentSurvey.instanceId
          }).then((data) => {
            console.log("Added conclusion");
            return data;
          });
        });
      }
      if (this.changed.attachments) {
        promises.push(() => {
          console.log("Adding attachments");

          return apolloClient.mutate({
            mutation: surveyInstanceAddAttachment,
            variables: {
              files: this.attachments.map(({id, file, title, description}) => ({
                id,
                file,
                title,
                description
              })),
              instanceId: this.currentSurvey.instanceId
            }
          }).then((data) => {
            console.log("Added attachments", data);
            return data;
          });
        });
      }
      serial(promises).then((taskResults) => {
        console.log("completed", taskResults);
        this.setCompletedState();
      }).catch(() => {
        this.isLoading = false;
        this.isError = true;
      });
    },
    setVesselData() {
      if (this.currentSurvey.vesselData) {
        this.vesselData = [JSON.parse(this.currentSurvey.vesselData)];
      }
    },
    setAttachments() {
      if (this.currentSurvey.survey.attachments) {
        this.attachments = JSON.parse(JSON.stringify(this.currentSurvey.survey.attachments || []));
      }
    },
    setSummaryAndConclusion() {
      if (this.currentSurvey.survey.summary) {
        this.summary = this.currentSurvey.survey.summary;
      }
      if (this.currentSurvey.survey.conclusion) {
        this.conclusion = this.currentSurvey.survey.conclusion;
      }
    }
  },
  created() {
    this.setSummaryAndConclusion();
    this.setAttachments();
    this.setVesselData();
  }
};
</script>
