<template>
  <div>
    <small style="font-weight:300;" class="pl-4">Answer</small>
    <div v-if="!answer">Not answered</div>
    <div v-else class="d-flex flex-wrap" v-for="(option, index) in answer" :key="index">
      <div>
        {{ option.answer }}
      </div>
      <div>
        <div v-for="(image, index) in option.media" :key="index" class="px-2">
          <template v-if="image.fileType === 'image'">
            <img :src="imagePath + image.name" style="max-width:300px" />
          </template>
          <template v-if="image.fileType === 'video'">
            <a :href="videoUrl + image.name + '.mp4'" target="_blank">
              <img style="max-width:50px" src="https://www.freeiconspng.com/uploads/video-play-icon-11.png">
            </a>
          </template>
        </div>
        <div v-if="option.media.length === 0">
          No files added to question
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import constants from "../../../../helpers/constants";

export default {
  name: "AnswerDamage",
  props: {
    answer: {
      required: true
    }
  },
  data: () => ({
    imagePath: constants.cloudinaryUrl,
    videoUrl: constants.cloudinaryVideo
  })
};
</script>
