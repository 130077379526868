<template>
    <div>
        <div v-for="(option, index) in answer" :key="index">
            {{ getAnswer(option.answer) }}
        </div>
    </div>
</template>

<script>
export default {
  name: 'GenericAnswerOptions',
  props: {
    answer: {
      required: true
    }
  },
  methods: {
    getAnswer(answer) {
      if (typeof answer === 'string') {
        return answer;
      }
      return answer.en;
    }
  }
};
</script>

<style scoped>

</style>
