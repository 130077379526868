<template>
    <div class="survey-rating">
        <div class="text-center">
            <div class="rating-overall">
                <span v-if="currentSurvey.survey.rating">{{currentSurvey.survey.rating.toFixed(2)}}</span>
                <span v-else>N/A</span>
            </div>
            <small>Overall rating</small>
        </div>
        <v-list>
            <template v-for="page in currentSurvey.survey.children">
              <v-list-item class="u-border-bottom" :key="page.id">
                <v-list-item-content>
                  <v-list-item-title>{{page.title.en}} <span v-if="page.weight">Weighted rating: {{page.weight}}/100</span></v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar>
                  <div class="rating-small">
                    <span v-if="page.rating">{{page.rating}}</span>
                    <span v-else-if="page.calculatedRating && page.calculatedRating.rating">{{page.calculatedRating.rating.toFixed(2)}}</span>
                    <span v-else>N/A</span>
                  </div>

                </v-list-item-avatar>

              </v-list-item>
              <v-list-item class="u-border-bottom" v-for="section in page.children" :key="section.id">
              <v-list-item-icon>
                    <v-icon color="yellow">star</v-icon>
                </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{section.title.en}} <span v-if="section.weight">Weighted rating: {{section.weight}}/{{getTotalPageWeight(page.calculatedRating)}}</span></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-avatar>
                        <div class="rating-small">
                            <span v-if="section.rating">{{section.rating}}</span>
                            <span v-else-if="section.calculatedRating && section.calculatedRating.rating">{{section.calculatedRating.rating.toFixed(2)}}</span>
                            <span v-else>N/A</span>
                        </div>
                    </v-list-item-avatar>
                </v-list-item>
            </template>
        </v-list>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'Rating',
  computed: {
    ...mapState('submittedSurveys', ['currentSurvey']),
    average() {
      let numberOfRatings = 0;
      let sum = this.currentSurvey.survey.children.reduce((acc, item) => {
        item.children.forEach((section) => {
          if (section.rating) {
            numberOfRatings++;
            acc += section.rating;
          } else {
            if (section.calculatedRating) {
              numberOfRatings++;
              acc += section.calculatedRating;
            }
          }
        });
        return acc;
      }, 0);
      let average = (sum / numberOfRatings).toFixed(1);
      return average;
    }
  },
  methods: {
    getTotalPageWeight(calculatedRating) {
      return calculatedRating.data?.totalWeight || 100;
    }
  }

};
</script>
