<template>
  <v-container class="page-editor">
    <v-layout row wrap>
      <v-flex grow class="pa-3">
        <v-card flat>
          <v-card-title>Create role</v-card-title>
          <v-divider />
          <v-card-text>
            <div>
              <v-label for="role-clientId">Client</v-label>

              <v-select id="role-clientId"
                        :items="clients"
                        item-value="id"
                        v-model="role.clientId"
                        item-text="name" />
            </div>
            <div class="mt-3">
              <v-label for="role-title">Role title</v-label>
              <div class="v-size--small">This is a human readable field that can be shown in logs etc.</div>
              <v-text-field id="role-title" placeholder="Role title" v-model="role.title['en']" />
            </div>
            <div class="mt-3">
              <v-label for="role-roleName">Role Name</v-label>
              <div class="v-size--small">This is to help select it across master templates</div>
              <v-text-field id="role-roleName" placeholder="Role name" v-model="role.roleName" />
            </div>
            <div class="mt-6">
              <v-label>Permissions</v-label>
              <div class="v-size--small mb-2">Which permissions do people of this role have</div>

              <v-layout wrap>
                <v-flex sm4 v-for="(roles, group) in splittedPermissions" :key="group">
                  <v-label> {{ group.replaceAll("_", ' ') }}</v-label>
                  <v-checkbox v-model="role.permissions" v-for="availableRole in roles" :key="availableRole.value"
                              :value="availableRole.value" :label="availableRole.label"></v-checkbox>
                </v-flex>

              </v-layout>
            </div>
            <div class="mt-6">
              <v-label>Notifications</v-label>
              <div class="v-size--small mb-2">Which notifications should people of this role automatically get</div>
              <v-layout wrap>
                <v-flex sm4 v-for="(notifications, group) in splittedNotifications" :key="group">
                  <v-label> {{ group.replaceAll("_", ' ') }}</v-label>
                  <v-checkbox v-model="role.notifications" v-for="availableNotification in notifications"
                              :key="availableNotification.value"
                              :value="availableNotification.value" :label="availableNotification.label"></v-checkbox>
                </v-flex>

              </v-layout>
            </div>
            <div class="pt-4">
              <v-btn rounded depressed color="primary" @click="onCreateRole">Create role</v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-alert type="error" prominent v-if="isError">
          Something went wrong trying to create the role. Please try again later or contact support if the problem
          persists.
          <div v-if="errorMessage">{{ errorMessage }}</div>

        </v-alert>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import { exploreRoleRelevantEnums } from "@/graphql/queries/roles";
import { createRole } from "@/graphql/mutations/roles";
import { mapMutations, mapState } from "vuex";

export default {
  name: "CreateRole",
  data: () => ({
    isLoading: false,
    isSaved: false,
    isError: false,
    errorMessage: null,
    role: {
      title: { en: "" },
      roleName: "",
      permissions: [],
      notifications: [],
      userType: "USER",
      clientId: ""
    },
    availableRoles: [],
    availableNotifications: []
  }),
  computed: {
    ...mapState("clients", { "clients": (state) => state.clientsWithRoles }),

    splittedPermissions() {
      let splittedRoles = {};
      this.availableRoles.forEach(role => {
        let parts = /(.+)_([A-Z]+)$/.exec(role.value);
        let arr = (splittedRoles[parts[1]] || []);
        splittedRoles[parts[1]] = arr.concat([role]);
      }
      );

      return splittedRoles;
    },
    splittedNotifications() {
      let splittedRoles = {};
      this.availableNotifications.forEach(role => {
        let parts = /(.+)_([A-Z]+)$/.exec(role.value);
        let arr = (splittedRoles[parts[1]] || []);
        splittedRoles[parts[1]] = arr.concat([role]);
      }
      );

      return splittedRoles;
    }
  },
  methods: {
    ...mapMutations("cms", ["updatePageTitle"]),
    onCreateRole() {
      this.isLoading = true;
      this.$apollo.mutate({
        mutation: createRole,
        variables: { role: { clientId: this.clientId, ...this.role } }
      }).then((response) => {
        if (response.data.success) {
          this.isError = true;
          this.errorMessage = response.data.message;
        } else {
          this.isError = false;
          this.isSaved = true;
          if (response.data.roleAdd?.role?.id) {
            this.$router.push({ name: "roles" });
          }
        }
        console.log(response);
      }).catch((error) => {
        console.error(error);
        this.isError = true;
        this.errorMessage = `${error}`;
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  created() {
    this.updatePageTitle("Create role");

    this.$apollo.query({
      query: exploreRoleRelevantEnums
    }).then(response => {
      let mapEnums = enumValue => {
        return { value: enumValue.name, label: enumValue.description || enumValue.name };
      };
      this.availableRoles = response.data?.RolePermissions?.enumValues?.map(mapEnums) || [];

      this.availableNotifications = response.data?.NotificationEvents?.enumValues?.map(mapEnums) || [];

      console.log(response);
    }
    ).catch(error => {
      alert(error);
    });
    this.role.clientId = this.$store.getters["clients/clientId"];
  }
}
;
</script>
