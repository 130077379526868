
export const state = {
  currentPageTitle: 'pageTitle'
};

export const getters = {
  currentPageTitle: state => state.currentPageTitle
};

export const mutations = {
  updatePageTitle(state, title) {
    state.currentPageTitle = title;
  }
};

export const actions = {
};

export default {
  state,
  mutations,
  actions,
  getters
};
