import gql from "graphql-tag";
import { LocalizedInputFragment } from "@/graphql/fragtments/localizedInput";

export const getSurveyById = gql`
    query survey($id: ID!) {
        survey(id: $id) {
            id
            type
            client {
                id
                logo
            }
            title {
                ...localizedFragment
            }
            description {
                ...localizedFragment
            }
            isActive
            icon
            createdAt
            updatedAt
            reportText
            reportLogo
            reportAddress
            category {
                id
                name {
                    ...localizedFragment
                }
            }
            children {
                id
                title {
                    ...localizedFragment
                }
                description {
                    ...localizedFragment
                }
                priority
                index
                roleName
                weight
                displayConditions {
                    id
                    conditionQuestionId
                    operator
                    value
                }
                children {
                    id
                    title {
                        ...localizedFragment
                    }
                    description {
                        ...localizedFragment
                    }
                    priority
                    index
                    page {
                        id
                    }
                    displayConditions {
                        id
                        conditionQuestionId
                        operator
                        value
                    }
                    roleName
                    weight
                    children {
                        id
                        inputType
                        helpShort {
                            ...localizedFragment
                        }
                        helpDetail {
                            ...localizedFragment
                        }
                        priority
                        title {
                            ...localizedFragment
                        }
                        question {
                            ...localizedFragment
                        }
                        isRequired
                        index
                        min
                        max
                        confirmText {
                            ...localizedFragment
                        }
                        confirmUrl
                        placeholder {
                            ...localizedFragment
                        }
                        isVisible
                        isHighlighted
                        highlightPriority
                        pageId
                        sectionId
                        surveyId
                        roleName
                        weight
                        data
                        displayConditions {
                            id
                            conditionQuestionId
                            operator
                            value
                        }
                        options {
                            id
                            option {
                                ...localizedFragment
                            }
                            priority
                            ratingValue
                        }
                    }
                }
            }
        }
    }
    ${LocalizedInputFragment}
`;

export const surveyCategories = gql`
  query surveyCategories($clientId: ID) {
    surveyCategories(clientId: $clientId) {
      id
      name {
        ...localizedFragment
      }
    }
  }
  ${LocalizedInputFragment}
`;
