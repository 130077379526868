<template>
    <div>
        <div v-for="(option, index) in answer" :key="index">
            <strong>{{option.answer}}</strong><br />
            <span>{{option.estimate}}</span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'GenericAnswerCostEstimate',
  props: {
    answer: {
      required: true
    }
  }
};
</script>
