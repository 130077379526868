import {
  getAllClients,
  getClientByID,
  getClientsAndRoles,
  getClientsAndSurveys,
  getCurrentClient,
} from "../../graphql/queries/clients";
import {
  deleteClient,
  inviteUserToClientCMS,
  updateClientByID,
} from "../../graphql/mutations/clients";
import { apolloClient } from "../../apollo/init-apollo";
import { sortingBy } from "../../helpers/sortingHelpers";
import { copyRoleToClient } from "@/graphql/mutations/roles";

export const state = {
  clientTheme: null,
  clients: [],
  clientsWithSurveys: [],
  clientsWithRoles: [],
  currentClient: null,
  filterClientId: null,
  searchQuery: "",
};

export const getters = {
  isRootClient: (state) => {
    if (state.clientTheme) {
      return state.clientTheme.isRoot;
    }
  },
  clientId: (state) => {
    if (state.clientTheme) {
      return state.clientTheme.id;
    } else {
      return localStorage.getItem("CLIENT_ID");
    }
  },
};

export const mutations = {
  setClients(state, payload) {
    state.clients = payload;
  },
  setClientsWithSurveys(state, payload) {
    state.clientsWithSurveys = payload;
  },
  setClientsWithRoles(state, payload) {
    state.clientsWithRoles = payload;
  },
  setCurrentClient(state, payload) {
    state.currentClient = payload;
  },
  setFilteredClient(state, id) {
    state.filterClientId = id;
  },
  setSearchQuery(state, query) {
    state.searchQuery = query;
  },
  setSortBy(state, payload) {
    const currentClient = state.clientsWithSurveys.filter(
      (client) => client.id === payload.clientId
    );
    sortingBy[payload.sorting](currentClient[0].surveys);
  },
  setSortByClientsSection(state, sorting) {
    state.clients = sortingBy[sorting](state.clients);
  },
  setCurrentClientTheme(state, data) {
    state.clientTheme = data;
  },
  setClientID(state, data) {
    localStorage.setItem("CLIENT_ID", data.id);
  },
};

export const actions = {
  async getCurrentClientTheme({ commit }) {
    const { data } = await apolloClient.query({
      query: getCurrentClient,
      fetchPolicy: "no-cache",
    });

    try {
      commit("setCurrentClientTheme", data.client);
      commit("setClientID", data.client);
      return data;
    } catch (e) {
      return e;
    }
  },

  async getAllClients({ commit }) {
    const { data } = await apolloClient.query({
      query: getAllClients,
      fetchPolicy: "no-cache",
    });

    try {
      if (data) {
        commit("setClients", data.clients);
      }
    } catch (e) {
      return e;
    }
  },

  async getClientsAndSurveys({ commit }) {
    const { data } = await apolloClient.query({
      query: getClientsAndSurveys,
      fetchPolicy: "no-cache",
    });
    try {
      if (data.clients) {
        commit("setClientsWithSurveys", data.clients);
      }
    } catch (e) {
      return e;
    }
  },
  async getClientsAndRoles({ commit }) {
    const { data } = await apolloClient.query({
      query: getClientsAndRoles,
      fetchPolicy: "no-cache",
    });
    try {
      if (data.clients) {
        commit("setClientsWithRoles", data.clients);
      }
    } catch (e) {
      return e;
    }
  },
  async getClientById({ commit }, id) {
    try {
      const { data } = await apolloClient.query({
        query: getClientByID,
        fetchPolicy: "no-cache",
        variables: {
          clientId: id,
        },
      });
      commit("setCurrentClient", data.client);
    } catch (e) {
      return e;
    }
  },

  async updateClientByID({ commit }, data) {
    const response = await apolloClient.mutate({
      mutation: updateClientByID,
      variables: {
        clientId: data.clientId,
        name: data.name,
        subdomain: data.subdomain,
        color: data.color,
        logo: data.logo,
        accountHolderName: data.accountHolderName,
        accountHolderEmail: data.accountHolderEmail,
        accountHolderPhone: data.accountHolderPhone,
      },
    });
    try {
      return response;
    } catch (e) {
      return e;
    }
  },
  async copyRoleToClient({ commit }, { id, clientId }) {
    return apolloClient.mutate({
      mutation: copyRoleToClient,
      variables: {
        toClient: clientId,
        roleId: id,
      },
    });
  },
  async inviteUserToClient({ commit }, data) {
    const response = await apolloClient.mutate({
      mutation: inviteUserToClientCMS,
      variables: {
        clientId: data.clientId,
        email: data.email,
        name: data.name,
      },
    });

    try {
      return response;
    } catch (e) {
      return e;
    }
  },

  async deleteClientFromCMS({ commit }, clientId) {
    const response = await apolloClient.mutate({
      mutation: deleteClient,
      variables: {
        id: clientId,
      },
    });

    try {
      return response;
    } catch (e) {
      return e;
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
