<template>
    <v-container style="background-color:#f5f5f5;" class="pa-10">
        <v-row>
            <v-flex>
                <h3 class="subtitle-2">Options: </h3>
            </v-flex>
        </v-row>
        <v-row v-for="(choice, index) in options" :key="index">
            <v-flex>
                <v-text-field label="Option Text" v-model="choice.option[locale]" :placeholder="locale !== 'en' ? choice.option['en'] : ''" validate-on-blur @blur="updateData"></v-text-field>
            </v-flex>
            <v-flex shrink class="align-end" v-if="showSort">
                <v-text-field label="Priority" type="number" small v-model="choice.priority"></v-text-field>
            </v-flex>
            <v-flex class="align-end pt-3">
                <v-btn class="mt-1" fab text @click="onDeleteChoice(index)">
                    <v-icon color="primary" :size="20">delete</v-icon>
                </v-btn>
            </v-flex>
        </v-row>
        <v-row>
            <v-flex>
                <v-btn color="primary" rounded depressed small @click="onAddChoice(locale)">Add new</v-btn>
                <v-btn :color="showSort ? 'secondary' : ''" class="mx-2" rounded depressed small @click="toggleSort">Sort based on priority</v-btn>

                <v-btn small rounded depressed text @click="onDeleteAll">Delete all</v-btn>
            </v-flex>
        </v-row>
    </v-container>
</template>

<script>
import {choicesDefaultMixin} from './mixins/choices-default';

export default {
  name: 'Damage',
  props: {
    data: {},
    locale: {
      type: String,
      default: 'en'
    }
  },
  mixins: [choicesDefaultMixin]
};
</script>
