<template>
    <v-layout row wrap>
        <v-flex sm12 md4 lg3 class="pl-3 mb-sm-5">
            <survey-menu @editMode="onEditMode" mode="admin"></survey-menu>
        </v-flex>
        <v-flex sm12 md8 lg9 class="px-3">
            <v-card flat>
                <v-card-text class="pa-0">
                    <loading v-if="isLoading"/>
                    <template v-if="!isLoading">
                        <survey-editor-survey v-if="editType === 'Survey'" @dirty="changeDirty"></survey-editor-survey>
                        <survey-editor-page :data="data" v-if="editType === 'SurveyPage'"
                                            @dirty="changeDirty"></survey-editor-page>
                        <survey-editor-section :data="data"
                                               v-if="editType === 'SurveySection'"
                                               @dirty="changeDirty"></survey-editor-section>
                        <survey-editor-question :data="data"
                                                v-if="editType === 'SurveyQuestion'"
                                                @dirty="changeDirty"></survey-editor-question>
                    </template>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import EventBus from "../../../eventBus";
import { mapMutations, mapState } from "vuex";
import SurveyMenu from "../../../components/Surveys/Builder/SurveyMenu";
import SurveyEditorPage from "../../../components/Surveys/Editors/SurveyEditorPage";
import SurveyEditorSection from "../../../components/Surveys/Editors/SurveyEditorSection";
import SurveyEditorQuestion from "../../../components/Surveys/Editors/SurveyEditorQuestion";
import SurveyEditorSurvey from "../../../components/Surveys/Editors/SurveyEditorSurvey";

export default {
  name: "SubmittedSurvey",
  data: () => ({
    data: null,
    editType: "survey",
    isLoading: false
  }),
  components: {
    SurveyEditorSurvey,
    SurveyMenu,
    SurveyEditorPage,
    SurveyEditorSection,
    SurveyEditorQuestion
  },
  computed: {
    ...mapState("submittedSurveys", ["currentSurvey"]),
    ...mapState(["isDirty"])
  },
  methods: {
    ...mapMutations("cms", ["updatePageTitle"]),
    onEditMode(data) {
      this.editType = null;
      this.data = null;
      this.$nextTick(() => {
        this.editType = data.__typename;
        this.data = data;
      });
    },
    fetchSurvey() {
      this.$store.dispatch("submittedSurveys/getSubmittedSurvey", this.$route.params.surveyId).then(() => {
        this.isLoading = false;
        let surveyTitle = this.currentSurvey.survey.title.en;
        if (this.currentSurvey.survey.ship) {
          surveyTitle = `${this.currentSurvey.survey.ship} - ${surveyTitle}`;
        }
        this.updatePageTitle(surveyTitle);
      });
    },
    changeDirty($dirty) {
      this.$store.commit('setIsDirty', $dirty);
    }
  },
  created() {
    this.isLoading = true;
    this.fetchSurvey();
    this.editType = "Survey";
  },
  beforeDestroy() {
    EventBus.$off('surveyUpdated');
  },
  mounted() {
    EventBus.$on("surveyUpdated", () => {
      this.fetchSurvey();
    });
  }
};
</script>
