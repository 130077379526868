const constants = {
  editModes: {
    survey: "Survey",
    page: "SurveyPage",
    section: "SurveySection",
    question: "SurveyQuestion",
  },
  deleteMode: {
    confirmText: "DELETE",
  },
  questionFieldTypesConstants: {
    radio: "RADIO",
    checkbox: "CHECKBOX",
    multiple: "MULTIPLE",
    dropdown: "DROPDOWN",
    date: "DATE",
    datetime: "DATETIME",
    boolean: "BOOLEAN",
    media: "MEDIA",
    numeric: "NUMERIC",
    text: "STRING",
    IMO: "IMO",
    cost: "COSTESTIMATE",
    damage: "DAMAGE",
    callsign: "CALLSIGN",
    shipname: "SHIPNAME",
    confirm: "CONFIRM",
  },
  screenSizes: {
    xs: 480,
    sm: 768,
    md: 1024,
    lg: 1240,
    xl: 1440,
  },
  cloudinaryUrlOriginal:
    "https://res.cloudinary.com/survey-associasion/image/upload/", //See docs: https://cloudinary.com/documentation/upload_widget#api_events
  cloudinaryUrl:
    "https://res.cloudinary.com/survey-associasion/image/upload/w_0.60/", //See docs: https://cloudinary.com/documentation/upload_widget#api_events
  cloudinaryUrlSmall:
    "https://res.cloudinary.com/survey-associasion/image/upload/w_80/",
  cloudinaryVideo:
    "https://res.cloudinary.com/survey-associasion/video/upload/",
  clientHostNames: {
    localhost: "localhost",
    adminCms: "cms.saremote",
    saremote: "sac",
    athena: "athena",
    tokiomarine: "tokiomarine",
    fairway: "fairway\.ca",
    insurwave: "insurwave",
    test: "sacms.test",
    netlify: "priceless-agnesi-9b03a4.netlify.app",
    //sac: 'sac'
  },
};

//Cloudimnary rotation: https://support.cloudinary.com/hc/en-us/articles/207045765-Why-does-Cloudinary-Incorrectly-rotate-my-image-
//Cloudinary check rotation: https://cloudinary-url-api.glitch.me/optimized.html
//Cloudimary check rotation: https://cloudinary-url-api.glitch.me/index.html

export default constants;
