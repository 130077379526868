import gql from 'graphql-tag';
import {LocalizedInputFragment} from '../fragtments/localizedInput';

export const getSubmittedSurveysList = gql`
    query surveyInstancesCMS {
        surveyInstancesCMS{
            id
            title { ... localizedFragment }
            status
            ship,
            imo
            client {
                name
            }
            instanceId
            createdAt
            updatedAt
            closedAt,
            uploadStatus,
            lastSubmittedBy {
                firstName,
                lastName,
                email
            }
        }
    }
    ${LocalizedInputFragment}
`;

export const getSubmittedSurveyInstance = gql`
    query surveyInstanceData($instanceId: ID, $dataId: ID) {
        surveyInstanceData(
            instanceId: $instanceId,
            dataId: $dataId
        ) {
            success,
            message
        }
    }
`;

export const getLogForSurvey = gql`
    query surveyInstanceEvents($instanceId: ID!) {
        surveyInstanceEvents(
            instanceId: $instanceId
        ) {
            createdAt,
            type,
            userName
            userType,
            content
        }
    }
`;
