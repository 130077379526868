import gql from 'graphql-tag';
import {LocalizedInputFragment} from '@/graphql/fragtments/localizedInput';

export const getStaticPages = gql`
    query staticPages {
        staticPages{
            id,
            title { ... localizedFragment }
            content { ... localizedFragment }
        }
    }
    ${LocalizedInputFragment}
`;

export const getStaticPageByID = gql`
    query staticPage($id: ID!) {
        staticPage(id: $id) {
            id,
            title { ... localizedFragment },
            content { ... localizedFragment }
        }
    }
    ${LocalizedInputFragment}
`;

export const getStaticPagesByClient = gql`
    query clients {
        clients{
            id,
            name,
            isRoot,
            staticPages { id, title { ... localizedFragment } }
        }
    }
    ${LocalizedInputFragment}
`;
