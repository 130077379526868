import gql from "graphql-tag";

export const userLoginMutation = gql`
  mutation adminLogin($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      success
      message
      admin {
        id
        email
        name
      }
    }
  }
`;

export const userForgotPassword = gql`
  mutation adminForgotPassword($email: String!) {
    adminForgotPassword(email: $email) {
      success
      message
    }
  }
`;

export const userResetPassword = gql`
  mutation adminResetPassword(
    $email: String!
    $token: String!
    $password: String!
  ) {
    adminResetPassword(email: $email, token: $token, password: $password) {
      success
      message
    }
  }
`;

export const userLogOut = gql`
  mutation adminLogout {
    adminLogout
  }
`;

export const userChangePassword = gql`
  mutation adminChangePassword(
    $email: String!
    $oldPassword: String!
    $newPassword: String!
  ) {
    adminChangePassword(
      email: $email
      oldPassword: $oldPassword
      newPassword: $newPassword
    ) {
      success
      message
    }
  }
`;

export const userUpdateCompany = gql`
  mutation userUpdateCompany($id: ID!, $companyId: ID!, $companies: [ID!]) {
    userUpdateCompany(id: $id, companyId: $companyId, companies: $companies) {
      success
      message
    }
  }
`;
export const inviteUser = gql`
  mutation adminAdd($name: String!, $email: String!) {
    adminAdd(name: $name, email: $email) {
      success
      message
    }
  }
`;

export const deleteUser = gql`
  mutation adminDelete($id: ID!) {
    adminDelete(id: $id) {
      success
      message
    }
  }
`;

export const appUserActivate = gql`
  mutation userApprove($id: ID!, $clientId: ID) {
    userApprove(id: $id, clientId: $clientId) {
      success
      message
    }
  }
`;

export const appUserDelete = gql`
  mutation userDelete($id: ID!) {
    userDelete(id: $id) {
      success
      message
    }
  }
`;

export const appUserReject = gql`
  mutation userReject($id: ID!) {
    userReject(id: $id) {
      success
      message
    }
  }
`;

export const adminActivate = gql`
  mutation adminActivate($email: String!, $token: String!, $password: String!) {
    adminActivate(email: $email, token: $token, password: $password) {
      success
      message
    }
  }
`;

export const adminResetPassword = gql`
  mutation adminResetPassword(
    $email: String!
    $token: String!
    $password: String!
  ) {
    adminResetPassword(email: $email, token: $token, password: $password) {
      success
      message
    }
  }
`;

export const updateUserRole = gql`
  mutation updateUserRole($id: ID!, $roleName: String!) {
    userUpdateRole(id: $id, roleName: $roleName) {
      success
      message
    }
  }
`;
