import {deleteStaticPage} from '../../graphql/mutations/static';

export const listPagesMixin = {
  data: () => ({
    selectedPage: null
  }),
  methods: {
    onAssignPage(page) {
      this.$emit('selectedPage', page);
      this.$store.dispatch('modals/toggleModal', 'showAssignPageModal');
    },
    onDeletePage(id) {
      this.isLoading = true;
      this.$apollo.mutate({
        mutation: deleteStaticPage,
        variables: {
          id: id
        }
      }).then(data => {
        if (data.data.staticDelete) {
          this.$emit('pageDeleted');
        } else {
          this.isError = true;
          this.errorText = 'Cant delete page';
        }
      }).catch(err => {
        this.isError = true;
        this.errorText = err;
      });
    }
  }
};
