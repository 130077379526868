<template>
    <div v-if="currentSurvey">
        <v-alert type="info" class="mb-10" prominent tile>
            <p>
                Generate a PDF by by clicking the button below. It will either ask what you want to do (download / view)
                or
                download it automatically.
            </p>
            <v-btn color="white" rounded depressed light @click="printDocument" :disabled="printDocumentDisabled">
                {{ printDocumentText }}
            </v-btn>
        </v-alert>

        <!-- Survey start -->
        <div class="text-center pb-10">
            <h2>Report preview</h2>
            <h3 class="subtitle-2">Preview will be missing the page header, footer and page breaks - Remember to turn on
                colors, backgrounds, including enabling headers and footers in your print settings</h3>
        </div>
        <div ref="print" class="survey-report">
            <div class="page-header" style="text-align:right; padding:20px;">
                <img :src="imagePath + currentSurvey.survey.reportLogo" alt="" style="max-height:40px;"
                     v-if="currentSurvey.survey.reportLogo"/>
                <img src="../../../assets/img/saremote-logo.png" style="max-height:40px;" alt=""
                     v-if="!currentSurvey.survey.reportLogo"/>
            </div>

            <div class="page-footer">
                <table width="100%" style="width:100%; font-size:12px">
                    <tr>
                        <td style="vertical-align: top;">
                            <table width="100%">
                                <tr>
                                    <td style="text-align:left;">
                                        <div
                                                style="white-space:pre-line; font-family: 'Rajdhani', 'Arial', sans-serif !important; font-size:12px; font-weight: normal; text-align:left;">
                                            <span v-if="currentSurvey.survey.reportAddress">{{
                                                    currentSurvey.survey.reportAddress.trim()
                                                }}</span>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td style="vertical-align: top; text-align:right;">
                            <table width="100%">
                                <tr>
                                    <td style="text-align:right;">
                                        Survey master: {{ getFieldValue(currentSurvey.survey.title) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align:right;">
                                        Survey type: {{ currentSurvey.survey.type }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align:right;">
                                        Revision:
                                        <span style="font-size:12px;"
                                              v-if="currentSurvey.survey.closedAt">{{
                                                currentSurvey.survey.closedAt | formatDateWithTime
                                            }}</span>
                                        <span style="font-size:12px;"
                                              v-else>{{ currentSurvey.survey.updatedAt | formatDateWithTime }}</span>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>

            <table style="width:100%; background-color:#fff;">
                <tr>
                    <td>
                        <table style="width:100%">

                            <thead>
                            <tr>
                                <td>
                                    <!--place holder for the fixed-position header-->
                                    <div class="page-header-space"></div>
                                </td>
                            </tr>
                            </thead>

                            <tbody>
                            <tr>
                                <td class="survey-report">
                                    <!--*** CONTENT GOES HERE ***-->
                                    <div class="survey-report__page-one page">
                                        <!--<div class="survey-report__title" style="text-align:center;">
                                            <h1 style="text-transform: capitalize; text-align:center">
                                                HEADLINE (Highlighted question 1)
                                            </h1>
                                        </div>-->
                                        <div class="survey-report__sub-title" style="text-align:center;">
                                            <h2 style="text-align:center">
                                                {{ getFieldValue(currentSurvey.survey.title) }}
                                            </h2>
                                        </div>
                                        <div class="survey-report__description text-center py-3"
                                             v-if="getFieldValue(currentSurvey.survey.description)">
                                            <p style="text-align:center">
                                                {{ getFieldValue(currentSurvey.survey.description) }}</p>
                                        </div>

                                        <div class="survey-report__main-image"
                                             v-if="currentSurvey.highlightedQuestions">
                                            <div class="text-center"
                                                 v-for="(question, index) in currentSurvey.highlightedQuestions"
                                                 :key="index">
                                                <template v-if="question.inputType === 'MEDIA'">
                                                    <img :src="`${imagePath}${question.answer[0].name}.png`"
                                                         style="min-width:40%; max-width:70%; max-height: 100mm;"/>
                                                </template>
                                            </div>
                                        </div>

                                        <div class="survey-report__highlighted"
                                             v-if="currentSurvey.highlightedQuestions">
                                            <table width="70%" style="margin: 0 auto">
                                                <tbody>
                                                <tr v-for="(question, index) in currentSurvey.highlightedQuestions"
                                                    :key="index">
                                                    <td v-if="question.inputType !== 'MEDIA'"
                                                        style="width: 150px; vertical-align: top; padding:10px 0; border-bottom:1px solid #ccc">
                                                        {{ getFieldValue(question.question) }}
                                                    </td>
                                                    <td style="text-align:right; padding:10px 0; border-bottom:1px solid #ccc"
                                                        v-if="question.inputType !== 'MEDIA'">
                                                        <generic-answer-options
                                                                v-if="question.inputType === 'CHECKBOX' || question.inputType === 'RADIO' || question.inputType === 'DROPDOWN'"
                                                                :answer="question.answer"/>
                                                        <!-- <generic-answer-media
                                                                 v-if="question.inputType === 'MEDIA'"
                                                                 :answer="question.answer"
                                                                 :show-count="false" limit="1"/> -->
                                                        <generic-answer-date-time
                                                                :answer="question.answer"
                                                                v-if="question.inputType === 'DATETIME'"/>
                                                        <generic-answer-date :answer="question.answer"
                                                                             v-if="question.inputType === 'DATE'"/>
                                                        <generic-answer-cost-estimate
                                                                :answer="question.answer"
                                                                v-if="question.inputType === 'COSTESTIMATE'"/>
                                                        <generic-answer-boolean
                                                                :answer="question.answer"
                                                                v-if="question.inputType === 'BOOLEAN'"
                                                        />
                                                        <generic-answer-string v-if="question.inputType === 'STRING' ||
                                        question.inputType === 'CALLSIGN' ||
                                        question.inputType === 'IMO' ||
                                        question.inputType === 'NUMERIC' ||
                                        question.inputType === 'CONFIRM'"
                                                                               :answer="question.answer"/>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div style="text-align:center; padding-top:20px;"
                                             class="survey-report__report-text"
                                             v-if="currentSurvey.survey.reportText">
                                            <p>{{ currentSurvey.survey.reportText }}</p>
                                        </div>
                                    </div>

                                    <!-- Table of contents -->
                                    <div class="survey-report__table-of-contents  mt-10 page print-toc">
                                        <div class="survey-report__page-title">
                                            <h2>Table of contents</h2>
                                        </div>
                                        <ul class="pa-5 appendix table-of-contents"
                                            v-if="currentSurvey.survey.children">
                                            <li v-if="currentSurvey.survey.summary"><a class="toc-line" href="#summary"><span
                                                    class="toc-title">Summary</span><span
                                                    class="page-number"
                                                    :data-page-number="mustache(`_tocMap.summary.page`)"></span></a>
                                            </li>
                                            <li v-if="currentSurvey.survey.conclusion"><a href="#conclusion"
                                                                                          class="toc-line"><span
                                                    class="toc-title">Conclusion and closing remarks</span><span
                                                    class="page-number"
                                                    :data-page-number="mustache(`_tocMap.conclusion.page`)"></span></a>
                                            </li>
                                            <li v-if="currentSurvey.survey.type === 'CONDITION'"><a class="toc-line"
                                                                                                    href="#ratings"><span
                                                    class="toc-title">Ratings</span><span
                                                    class="page-number"
                                                    :data-page-number="mustache(`_tocMap.ratings.page`)"></span></a>
                                            </li>
                                            <li v-for="(page, indexPage) in currentSurvey.survey.children"
                                                :key="indexPage">
                                                <a class="toc-line" :title="getFieldValue(page.title)"
                                                   :href="`#page_${page.id}`"><span
                                                        class="toc-title">{{ `${indexPage + 1}` }} - {{
                                                        getFieldValue(page.title)
                                                    }}</span><span class="page-number"
                                                                   :data-page-number="mustache(`_tocMap.page_${page.id}.page`)"></span></a>
                                                <template v-for="(section, indexSection) in page.children">
                                                    <ul :key="indexSection"
                                                        v-if="shouldShowSection(section.children) === true">
                                                        <li><a class="toc-line"
                                                               :href="`#section_${section.id}`">
                              <span class="toc-title">{{
                                      `${indexPage + 1}.${indexSection + 1}`
                                  }} - {{
                                      getFieldValue(section.title)
                                  }}</span><span class="page-number"
                                                 :data-page-number="mustache(`_tocMap.section_${section.id}.page`)"></span>
                                                        </a>
                                                        </li>
                                                    </ul>
                                                </template>
                                            </li>

                                            <li v-if="surveyAttachments.length > 0 "><a class="toc-line"
                                                                                        href="#attachments"><span
                                                    class="toc-title">Attachments</span><span
                                                    class="page-number"
                                                    :data-page-number="mustache(`_tocMap.attachments.page`)"></span></a>
                                            </li>
                                            <li v-if="recommendations.length > 0 "><a class="toc-line"
                                                                                      href="#recommendations"><span
                                                    class="toc-title">Recommendations</span><span
                                                    class="page-number"
                                                    :data-page-number="mustache(`_tocMap.recommendations.page`)"></span></a>
                                            </li>
                                            <li><a href="#vessel-data" class="toc-line"><span class="toc-title">Vessel Data</span><span
                                                    class="page-number"
                                                    :data-page-number="mustache(`_tocMap.vessel-data.page`)"></span></a>
                                            </li>
                                        </ul>
                                    </div>

                                    <!-- SUMMARY -->
                                    <div class="survey-report__summary page"
                                         v-if="currentSurvey.survey.summary">
                                        <div class="survey-report__description py-3">
                                            <h2 id="summary">Summary</h2>
                                            <pre
                                                    style="font-family: 'Arial', sans-serif; white-space: pre-line">{{
                                                    currentSurvey.survey.summary.trim()
                                                }}</pre>
                                        </div>
                                    </div>
                                    <div class="survey-report__conclusion page"
                                         v-if="currentSurvey.survey.conclusion">
                                        <div class="survey-report__description py-3">
                                            <h2 id="conclusion">Conclusion and closing remarks</h2>
                                            <pre
                                                    style="font-family: 'Arial', sans-serif; white-space: pre-line">{{
                                                    currentSurvey.survey.conclusion.trim()
                                                }}</pre>
                                        </div>
                                    </div>
                                    <!-- Rating -->
                                    <div class="survey-report__ratings page"
                                         v-if="currentSurvey.survey.type === 'CONDITION'">
                                        <div class="survey-report__page-title" style="margin-bottom:20px">
                                            <h2 id="ratings">Ratings</h2>
                                        </div>
                                        <rating-report :survey="currentSurvey.survey"/>
                                    </div>

                                    <!-- Report content -->
                                    <div class="survey-report__content" v-if="currentSurvey.survey">
                                        <!-- Pages -->
                                        <template v-if="currentSurvey.survey.children">
                                            <div :id="'page'+indexPage" class="survey-report__page page"
                                                 v-for="(page, indexPage) in currentSurvey.survey.children"
                                                 :key="indexPage">
                                                <div class="survey-report__page-title">
                                                    <h2 :id="`page_${page.id}`">{{ page.index }} -
                                                        {{ getFieldValue(page.title) }}</h2>
                                                </div>
                                                <div class="survey-report__page-description pt-3"
                                                     v-if="getFieldValue(page.description)">
                                                    <p>{{ getFieldValue(page.description) }}</p>
                                                </div>
                                                <!-- Section -->
                                                <template v-if="page.children">
                                                    <div class="survey-report__section"
                                                         v-for="(section, indexSection) in page.children"
                                                         :key="indexSection">
                                                        <template v-if="shouldShowSection(section.children) === true">
                                                            <div class="survey-report__section-title"
                                                                 style="display:flex"
                                                                 v-if="section.children && section.children.length > 0">

                                                                <div>
                                                                    <h3 :id="`section_${section.id}`">
                                                                        {{ section.index }}
                                                                        {{ getFieldValue(section.title) }}</h3>
                                                                    <pre
                                                                            style="font-family: 'Arial', sans-serif; white-space: pre-line; color:#fff !important;"
                                                                            v-if="section.comments">
                                                                        {{ section.comments[0].content }}
                                                                    </pre>
                                                                </div>
                                                                <div style="display:flex; justify-content: flex-end; flex-grow:1;"
                                                                     v-if="currentSurvey.survey.type === 'CONDITION' && section.rating">
                                                                    <template
                                                                            v-for="(star, index) in parseInt(section.rating)">
                                                                        <v-icon :key="index" color="white"
                                                                                style="color:#fff;">star
                                                                        </v-icon>
                                                                    </template>
                                                                </div>

                                                            </div>
                                                            <div class="survey-report__section-description"
                                                                 v-if="getFieldValue(section.description)">
                                                                <p>{{ getFieldValue(section.description) }}</p>
                                                            </div>
                                                        </template>
                                                        <!-- QUESTIONS -->
                                                        <template v-if="section.children">
                                                            <div v-for="(question, indexQuestion) in section.children"
                                                                 :key="indexQuestion">
                                                                <div class="survey-report__question"
                                                                     style="border-bottom:1px solid #000"
                                                                     v-if="question.answer || question.inputType === 'BOOLEAN'">
                                                                    <table width="100%">
                                                                        <tbody>
                                                                        <tr>
                                                                            <td
                                                                                    style="width: 100px; vertical-align:top; text-align:left; padding:5px 0;border-bottom:1px dotted #ccc;">
                                                                                <strong>{{ question.index }}</strong>
                                                                            </td>
                                                                            <td
                                                                                    style="text-align:left; vertical-align:top; padding:5px 0; border-bottom:1px dotted #ccc;">
                                                                                <b>{{
                                                                                        getFieldValue(question.title)
                                                                                    }}</b>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="100px">
                                                                                Answer
                                                                            </td>
                                                                            <td style="text-align:left; padding:10px 0;">
                                                                                <generic-answer-options
                                                                                        v-if="question.inputType === 'CHECKBOX' || question.inputType === 'RADIO' || question.inputType === 'DROPDOWN'"
                                                                                        :answer="question.answer"/>
                                                                                <generic-answer-damage
                                                                                        v-if="question.inputType === 'DAMAGE'"
                                                                                        :answer="question.answer"/>
                                                                                <generic-answer-media
                                                                                        v-if="question.inputType === 'MEDIA'"
                                                                                        :answer="question.answer"/>
                                                                                <generic-answer-date-time
                                                                                        :answer="question.answer"
                                                                                        v-if="question.inputType === 'DATETIME'"/>
                                                                                <generic-answer-date
                                                                                        :answer="question.answer"
                                                                                        v-if="question.inputType === 'DATE'"/>
                                                                                <generic-answer-cost-estimate
                                                                                        :answer="question.answer"
                                                                                        v-if="question.inputType === 'COSTESTIMATE'"/>
                                                                                <generic-answer-boolean
                                                                                        :answer="question.answer"
                                                                                        v-if="question.inputType === 'BOOLEAN'"
                                                                                />
                                                                                <generic-answer-string v-if="question.inputType === 'STRING' ||

                                                                                            question.inputType === 'CALLSIGN' ||
                                                                                            question.inputType === 'IMO' ||
                                                                                            question.inputType === 'NUMERIC' ||
                                                                                            question.inputType === 'CONFIRM'"
                                                                                                       :answer="question.answer"/>

                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <table style="width:100%; background-color:#ccc;"
                                                                           v-for="(comment, index) in filterRecommendation(question.comments)"
                                                                           :key="'rec' + index">
                                                                        <tbody>
                                                                        <tr>
                                                                            <td style="width: 100px; vertical-align:top; padding: 5px 0;">
                                                                                <span v-if="comment.type === 'RECOMMENDATION'">Surveyor recommendation</span>
                                                                            </td>
                                                                            <td style="vertical-align: top;  padding: 5px 0;">
                                                                                <pre
                                                                                        style="font-family: 'Arial', sans-serif; white-space: pre-line">
                                                                                {{ comment.content }}
                                                                                </pre>
                                                                                <span v-if="comment.dueDate">Due date: {{
                                                                                        comment.dueDate
                                                                                    }}</span>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <table style="width:100%; background-color:#ccc;"
                                                                           v-for="(comment, index) in filterComment(question.comments)"
                                                                           :key="'com' + index">
                                                                        <tbody>
                                                                        <tr>
                                                                            <td style="width: 100px; vertical-align:top; padding: 5px 0;">
                                        <span
                                                v-if="comment.type === 'COMMENT' && currentSurvey.survey.type === 'DAMAGE'">Surveyor comment</span>
                                                                                <span
                                                                                        v-if="comment.type === 'COMMENT' && currentSurvey.survey.type === 'CONDITION'">Surveyor comment</span>
                                                                            </td>
                                                                            <td style="vertical-align: top;  padding: 5px 0;">
                                                                                <pre
                                                                                        style="font-family: 'Arial', sans-serif; white-space: pre-line">
                                                                                {{ comment.content }}
                                                                                </pre>
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </template>
                                            </div>
                                        </template>
                                    </div>


                                    <div class="survey-report__attachments page" v-if="surveyAttachments.length > 0 ">
                                        <div class="survey-report__page-title" style="margin-bottom:20px">
                                            <h2 id="attachments">Attachments</h2>
                                        </div>
                                        <ul style="list-style: none; margin: 0; padding:0;">
                                            <li v-for="attachment in surveyAttachments" :key="attachment.id">
                                                <a :href="`${originalPath}${attachment.file}`"
                                                   style="display:block; text-align:center;"><strong>{{
                                                        attachment.title
                                                    }}</strong><br>
                                                    <img :src="`${imagePath}${attachment.file}.png`"
                                                         style="min-width:40%; max-width:95%; max-height: 90mm"/>
                                                    <p v-if="attachment.description">{{ attachment.description }}</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="survey-report__recommendations page" v-if="recommendations.length > 0 ">
                                        <div class="survey-report__page-title" style="margin-bottom:20px">
                                            <h2 id="recommendations">Recommendations</h2>
                                        </div>
                                        <div v-for="recommendation in recommendations" :key="recommendation.id"
                                             class="mb-2">
                                            <div style="display:flex;">
                                                <div style="flex:4"><strong>{{ recommendation.index }}
                                                    {{ getFieldValue(recommendation.question) }}</strong></div>
                                                <div style="flex:1; display:flex;" v-if="recommendation.dueDate"><strong
                                                        style="width:50%; display:block; text-align:right">Due
                                                    date:</strong> <span
                                                        style="width:50%; display:block; text-align:right">{{
                                                        recommendation.dueDate
                                                    }}</span>
                                                </div>
                                            </div>
                                            <div>Recommendation: {{ recommendation.content }}</div>

                                        </div>

                                    </div>
                                    <div class="survey-vessel__particulars" style="margin-top:50px;" v-if="vesselData">
                                        <h2 id="vessel-data">Vessel data</h2>
                                        <list-vessel-data :vessel-data="vesselData"/>
                                    </div>
                                </td>
                            </tr>
                            </tbody>

                            <tfoot>
                            <tr>
                                <td>
                                    <!--place holder for the fixed-position footer-->
                                    <div class="page-footer-space"></div>
                                </td>
                            </tr>
                            </tfoot>

                        </table>
                    </td>
                </tr>
            </table>
        </div>

        <!-- END LALALALA -->

    </div>
</template>

<script>
import { mapState } from "vuex";
import RatingReport from "../../../components/Surveys/Report/RatingReport";
import GenericAnswerString from "../../../components/Surveys/Answers/GenericAnswerString";
import GenericAnswerCostEstimate from "../../../components/Surveys/Answers/GenericAnswerCostEstimate";
import GenericAnswerDate from "../../../components/Surveys/Answers/GenericAnswerDate";
import GenericAnswerDateTime from "../../../components/Surveys/Answers/GenericAnswerDateTime";
import GenericAnswerMedia from "../../../components/Surveys/Answers/GenericAnswerMedia";
import GenericAnswerOptions from "../../../components/Surveys/Answers/GenericAnswerOptions";
import constants from "../../../helpers/constants";
import GenericAnswerDamage from "../../../components/Surveys/Answers/GenericAnswerDamage";
import ListVesselData from "../../../components/Surveys/VesselData/ListVesselData";
import GenericAnswerBoolean from "../../../components/Surveys/Answers/GenericAnswerBoolean";
import { chooseDBasedOnHostname } from "../../../helpers/config-helpers";

export default {
  name: "SurveyReport",
  data: () => ({
    imagePath: constants.cloudinaryUrl,
    originalPath: constants.cloudinaryUrlOriginal,
    vesselData: null,
    printDocumentText: "Generate PDF report",
    printDocumentDisabled: false
  }),
  components: {
    GenericAnswerBoolean,
    ListVesselData,
    GenericAnswerDamage,
    GenericAnswerOptions,
    GenericAnswerMedia,
    GenericAnswerDateTime,
    GenericAnswerDate,
    GenericAnswerCostEstimate,
    GenericAnswerString,
    RatingReport
  },
  computed: {
    ...mapState("submittedSurveys", ["currentSurvey"]),
    recommendations() {
      let recommendations = [];
      this.currentSurvey?.survey?.children?.forEach?.(page => {
        page.children?.forEach?.(section => {
          section.children?.forEach?.(question => {
            let filter = question.comments?.filter?.(comment => comment.type === 'RECOMMENDATION') || [];
            let mappedRecommendations = filter.map(entry => {
              return { ...entry, index: question.index, question: question.question };
            });
            if (mappedRecommendations.length) {
              recommendations.push(mappedRecommendations[0]);
            }
          });
        });
      });
      console.log({ recommendations });
      return recommendations;
    },
    surveyAttachments() {
      return this.currentSurvey?.survey?.attachments || [];
    }
  },
  methods: {
    getFieldValue(field, options) {
      if (typeof field === "string") {
        return field;
      }

      let defaultOptions = { defaultLocale: "en", locale: "en" };
      if (typeof options === "string") {
        options = { locale: options };
      }
      options = { ...defaultOptions, ...options };
      if (field && field[options.locale]) {
        return field[options.locale];
      }
      if (options.defaultLocale && field && field[options.defaultLocale]) {
        return field[options.defaultLocale];
      }

      if (options.default) {
        return options.default;
      }

      return null;
    },
    filterRecommendation(items) {
      let updatedArr = [];
      if (items && items.length > 0) {
        let updatedItems = items.filter(item => item.type === "RECOMMENDATION");
        if (updatedItems.length > 0) {
          updatedArr.push(updatedItems[0]);
        }
        return updatedArr;
      } else {
        return [];
      }
    },
    filterComment(items) {
      let updatedArr = [];
      if (items && items.length > 0) {
        let updatedItems = items.filter(item => item.type === "COMMENT");
        if (updatedItems.length > 0) {
          updatedArr.push(updatedItems[0]);
        }
        return updatedArr;
      } else {
        return [];
      }
    },
    shouldShowSection(children) {
      let shouldShow;
      shouldShow = children.some((question) => question.answer);
      return shouldShow;
    },
    setVesselData() {
      if (this.currentSurvey.vesselData) {
        this.vesselData = [JSON.parse(this.currentSurvey.vesselData)];
      }
    },
    fetchReport() {
      this.isLoading = true;
      this.$store.dispatch("submittedSurveys/getSubmittedSurvey", this.$route.params.surveyId).then(() => {
        this.isLoading = false;
        this.setVesselData();
      });
    },
    goBack() {
      this.$router.back();
    },
    mustache(ref) {
      return `{{${ref}}}`;
    },
    printDocument() {
      //Get HTML to print from element
      let prtHtml = this.$refs.print.innerHTML;
      prtHtml = prtHtml.replace(/href="#(?<ref>page_[^"]+)"/g, (match, p1, offset, string, groups) => {
        return `href="#{{_tocMap.${groups.ref}.href}}"`;
      })
      prtHtml = prtHtml.replace(/href="#(?<ref>section_[^"]+)"/g, (match, p1, offset, string, groups) => {
        return `href="#{{_tocMap.${groups.ref}.href}}"`;
      })
      let i = 0;
      this.printDocumentDisabled = true;
      this.printDocumentText = "Generating report";
      let interval = setInterval(() => {
        if (i % 5 === 0) {
          this.printDocumentText = "Generating report";
        } else {
          this.printDocumentText = `${this.printDocumentText}.`;
        }
        i++;
      }, 500);
      //Get all stylesheets HTML
      let stylesHtml = [];
      document.querySelectorAll("link[rel=\"stylesheet\"], style").forEach(node => {
        if (node.nodeName.toLowerCase() === "link") {
          node.href = node.sheet.href;
          if (!/kaspersky/.test(node.href)) {
            stylesHtml.push(node.outerHTML);
          }
        } else {
          if (!/nonce=/.test(node.outerHTML)) {
            stylesHtml.push(node.outerHTML);
          }
        }
      });
      let nameParts = this.currentSurvey.highlightedQuestions.filter(question => question.inputType === "IMO" || question.inputType === "CALLSIGN").map(question => question.answer);
      let host = chooseDBasedOnHostname(window.location.hostname);
      fetch(`${host}/generate`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json"
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",

        body: JSON.stringify({
          content: `<!DOCTYPE html>
            <html>
              <head>
                ${stylesHtml.join("\n")}
              <body>
                ${prtHtml}
              </body>
            </html>`,
          footer: "{{{ pageNumber }}} / {{{ totalPages }}}"
        })
      }).then(async response => {
        if (response.status === 200) {
          //Try to find out the filename from the content disposition `filename` value

          //The actual download
          let blob = await response.blob();
          let link = window.document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          let reportName = ["report", this.getFieldValue(this.currentSurvey.survey.title), ...nameParts];
          link.download = `${reportName.join("-")}.pdf`;
          window.document.body.appendChild(link);

          link.click();

          window.document.body.removeChild(link);
        } else {
          response.json().then(resp => {
            alert(resp.message);
          });
        }
      }).finally(() => {
        clearInterval(interval);
        this.printDocumentText = "Generate PDF Report";
        this.printDocumentDisabled = false;
      });
    }
  },
  created() {
    this.fetchReport();
  }
};
</script>

<style lang="scss" scoped>

pre, code {
  color: #000 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.survey-report {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
  margin-bottom: 50px;
  background-color: #fff;
  font-size: 14px;

  .table-of-contents li a {
    display: flex;

    &.toc-line {
      border-bottom: 1px dashed #e5e5e5;
    }

    .toc-title {
      flex-grow: 1;
    }

    & span.page-number {
      text-align: right;
    }
  }

  .text-center {
    text-align: center !important;
  }

  &__ratings {
    margin: 50px 0;
  }

  &__page-one,
  &__rating,
  &__table-of-contents,
  &__summary,
  &__conclusion,
  &__attachments,
  &__recommendations {
    page-break-after: always;
  }

  &__page-title,
  &__report-title {
    background-color: #1867c0;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;

    h2 {
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__section-title {
    background-color: lighten(#1867c0, 10%);
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 0 20px 0;

    h2 {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  &__page-title,
  &__section-title,
  &__report-title {
    padding: 15px;
  }

  &__page-description,
  &__section-description {
    padding: 0px 15px;
  }

  &__table-of-contents {
    list-style: none;

    ul {
      padding: 5px 0 5px 10px !important;
      list-style: none;
    }

    li {
      font-size: 14px;
      margin: 0;
      display: block;
      padding: 8px 0;

      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}

.page-header {
  display: block;
}

.page-footer {
  display: none; //none;
}

.page-header {
  height: 80px;
}

.page-header-space {
  height: 80px;
}

.page-footer, .page-footer-space {
  height: 100px;

}

@media print {

  //@page {
  //  counter-increment: page;
  //  font-family: 'Arial', sans-serif;
  //  @bottom-right {
  //    font-family: 'Arial', sans-serif;
  //    content: "Page " counter(page) " of " counter(pages);
  //  }
  //}

  //ul.appendix a:after {
  //  font-family: 'Arial', sans-serif;
  //  content: leader('.') target-counter(attr(href url), page);
  //}

  .survey-report {
    max-width: 100%;
    margin: auto;
    padding: 0;
    margin-bottom: 0;
  }

  .page-number {
    text-align: right;

    &:before {
      content: attr(data-page-number);
    }
  }

  .page-footer,
  .page-header {
    display: block;
  }

  .survey-report__content,
  .survey-report__page {
    page-break-after: always;
  }

  .page-footer {
    left: 0;
    right: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #f1f1f1; /* for demo */
    background: white; /* for demo */
  }

  .page-header {
    left: 0;
    right: 0;
    position: fixed;
    top: 0mm;
    width: 100%;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  body {
    margin: 0;
  }
}

</style>
