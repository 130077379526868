<template>
  <div>
    <v-progress-linear
        v-if="isLoading"
        :indeterminate="isLoading"
        :loading="isLoading"
        absolute
        top
        color="primary"
    ></v-progress-linear>
    <v-tabs>
      <v-tab v-if="isRootClient">Section</v-tab>
      <v-tab>Info</v-tab>
      <v-tab-item v-if="isRootClient">
        <div class="pa-3 py-10">
          <p>You are now at section: <strong>{{ section.title.en }}</strong>. <br />Add a comment or/and
            recommendation to the section.</p>
          <survey-editor-condition :condition="data.displayConditions"
                                   :role-name="data.roleName"></survey-editor-condition>

          <v-form>
            <div class="mb-4">
              <v-textarea
                  v-model="comment"
                  auto-grow
                  filled
                  color="primary"
                  label="Comment"
                  rows="4"
                  class="mb-2"
              ></v-textarea>
              <v-btn :disabled="isLoading" color="primary" small :outlined="!this.changed.comment" rounded
                     @click="onUpdateComment">Update
              </v-btn>
            </div>
            <div class="mb-4" v-if="conditionType === 'CONDITION'">
              <v-textarea
                  v-model="recommendation"
                  auto-grow
                  filled
                  color="primary"
                  :label="conditionType === 'DAMAGE' ? 'Recommendations only available for condition surveys' : 'Recommendation'"
                  :disabled="conditionType === 'DAMAGE'"
                  rows="4"
                  class="mb-2"
              ></v-textarea>
              <v-menu
                  ref="menu"
                  v-model="dueDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="dueDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-if="recommendation.length > 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="dueDate"
                      label="Due Date"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      prepend-icon="calendar_today"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dueDate"
                    no-title
                    scrollable
                    show-adjacent-months
                    @change="$refs.menu.save($event)"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="dueDateMenu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(dueDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-btn color="primary" :disabled="isLoading" rounded small
                     :outlined="!(this.changed.recommendation || this.changed.dueDate)"
                     @click="onUpdateRecommendation">Save
              </v-btn>
            </div>

            <v-container v-if="conditionType === 'CONDITION'">
              <v-row>
                <v-flex>
                  <h3 class="subtitle-2 pt-3 pl-2">Rate this section:</h3>
                </v-flex>
                <v-flex>
                  <div class="d-flex justify-end text-xs-right">
                    <v-btn @click="onUpdateRating(0)">Clear rating</v-btn>
                    <v-rating
                        :value="rating"
                        background-color="indigo lighten-3"
                        half-icon="star_half"
                        :half-increments="false"
                        :hover="true"
                        color="primary"
                        size="20"
                        @input="onUpdateRating"
                    ></v-rating>
                  </div>
                </v-flex>
              </v-row>
              <v-row v-if="calculatedRating !== false">
                <v-flex>
                  <h3 class="subtitle-2 pt-3 pl-2">Calculated rating for this section:</h3>
                </v-flex>
                <v-flex>
                  <div class="d-flex justify-end text-xs-right">
                    <v-rating
                        :value="calculatedRating.rating"
                        background-color="indigo lighten-3"
                        half-icon="star_half"
                        :half-increments="false"
                        :hover="true"
                        color="primary"
                        size="20"
                        disabled
                        readonly
                    ></v-rating>
                  </div>
                </v-flex>
              </v-row>
            </v-container>

            <survey-editor-list-questions :children="data.children" />
          </v-form>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="pa-3">
          <info :section="section" />
        </div>
      </v-tab-item>
    </v-tabs>

    <v-snackbar color="primary" :bottom="y === 'bottom'" v-model="isSaved">
      Details have been updated
      <v-btn color="white" text @click="isSaved = false">
        Close
      </v-btn>
    </v-snackbar>

    <v-snackbar color="error" :bottom="y === 'bottom'" v-model="isError">
      Error updating. Please try again or contact support: {{ errorText }}
      <v-btn color="white" text @click="isError = false">
        Close
      </v-btn>
    </v-snackbar>

  </div>
</template>

<script>
import EventBus from "../../../eventBus";
import { mapGetters, mapState } from "vuex";
import Info from "./Section/Info";
import SurveyEditorListQuestions from "./Components/SurveyEditorListQuestions";
import SurveyEditorCondition from "@/components/Surveys/Editors/SurveyEditorCondition";

export default {
  name: "survey-editor-section",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    section: null,
    timeout: 6000,
    y: "top",
    rating: 0,
    calculatedRating: false,
    commentId: null,
    comment: "",
    recommendationId: null,
    recommendation: "",
    isSaved: false,
    isError: false,
    errorText: "",
    isLoading: false,
    conditionType: null,
    dueDate: "",
    dueDateMenu: false,
    changed: {
      dueDate: false,
      comment: false,
      reopenedComment: false,
      recommendation: false
    },
    original: {
      dueDate: "",
      comment: "",
      reopenedComment: "",
      recommendation: ""
    }
  }),
  components: { SurveyEditorCondition, SurveyEditorListQuestions, Info },
  computed: {
    ...mapState("submittedSurveys", ["currentSurvey"]),
    ...mapGetters("clients", ["isRootClient"])
  },
  watch: {
    comment(newVal) {
      this.changed.comment = this.original.comment !== newVal;
      this.emitIfDirty();
    },
    reopenedComment(newVal) {
      this.changed.reopenedComment = this.original.reopenedComment !== newVal;
      this.emitIfDirty();
    },
    recommendation(newVal) {
      this.changed.recommendation = this.original.recommendation !== newVal;
      this.emitIfDirty();
    },
    dueDate(newVal) {
      this.changed.dueDate = this.original.dueDate !== newVal;
      this.emitIfDirty();
    }
  },
  methods: {
    emitIfDirty() {
      this.$emit("dirty", !!Object.values(this.changed).find(f => f));
    },
    setCompletedState() {
      EventBus.$emit("surveyUpdated");
      this.isLoading = false;
      this.isError = false;
      this.isSaved = true;
      this.changed = {
        dueDate: false,
        comment: false,
        reopenedComment: false,
        recommendation: false
      };
      this.$emit("dirty", false);
    },
    onUpdateComment() {
      this.isLoading = true;
      this.$store.dispatch("submittedSurveys/commentSet", {
        content: this.comment,
        commentId: this.commentId,
        type: "COMMENT",
        sectionId: this.data.id,
        instanceId: this.currentSurvey.instanceId
      }).then(() => {
        this.setCompletedState();
      }).catch((err) => {
        this.isError = true;
        this.errorText = err.message;
      });
    },
    onUpdateRecommendation() {
      this.isLoading = true;
      this.$store.dispatch("submittedSurveys/commentSet", {
        content: this.recommendation,
        commentId: this.recommendationId,
        dueDate: this.dueDate || "",
        type: "RECOMMENDATION",
        sectionId: this.data.id,
        instanceId: this.currentSurvey.instanceId
      }).then(() => {
        this.setCompletedState();
      }).catch((err) => {
        this.isError = true;
        this.errorText = err.message;
      });
    },
    onUpdateRating(rating) {
      this.isLoading = true;
      this.$store.dispatch("submittedSurveys/setRating", {
        instanceId: this.currentSurvey.instanceId,
        sectionId: this.data.id,
        //ratingId: payload.ratingId,
        rating: rating
      }).then(() => {
        this.setCompletedState();
        this.rating = rating;
      }).catch((err) => {
        this.isError = true;
        this.errorText = err.message;
      });
    },
    setComment() {
      if (this.data.comments) {
        const findComment = this.data.comments.filter(comment => comment.type === "COMMENT");
        if (findComment.length > 0) {
          this.commentId = findComment[0].id;
          this.comment = findComment[0].content;
          this.original.comment = findComment[0].content;
        }
      }
    },
    setRecommendation() {
      if (this.data.comments) {
        const findRecommendation = this.data.comments.find(comment => comment.type === "RECOMMENDATION");
        if (findRecommendation) {
          this.recommendationId = findRecommendation.id;
          this.recommendation = findRecommendation.content;
          this.original.recommendation = findRecommendation.content;
          this.original.dueDate = findRecommendation.dueDate || "";
          this.dueDate = this.original.dueDate;
        }
      }
    },
    setReopenedComment() {
      if (this.data.comments) {
        const findReopenedComment = this.data.comments.filter(comment => comment.type === "REOPEN" && comment.status === "OPEN");
        if (findReopenedComment.length > 0) {
          this.reopenedCommentId = findReopenedComment[0].id;
          this.isReopened = true;
          this.reopenedComment = findReopenedComment[0].content;
          this.original.reopenedComment = findReopenedComment[0].content;
        }
      }
    },
    setRating() {
      if (this.section.rating) {
        this.rating = this.section.rating;
      }
      if (this.section.calculatedRating) {
        this.calculatedRating = this.section.calculatedRating;
      }
    },
    setConditionType() {
      this.conditionType = this.currentSurvey.survey.type;
    }
  },
  created() {
    this.section = this.data;
    this.setComment();
    this.setConditionType();
    this.setRating();
    this.setRecommendation();
  }
};
</script>
