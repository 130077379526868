<template>
    <v-container>
        <v-layout row wrap class="mb-7">
            <v-flex xs6>
                <div class="pa-3">
                    <v-text-field label="Filter by client name" placeholder="Type to filter by client names" rounded clearable solo flat @input="onUpdateSearch" />
                </div>
            </v-flex>
            <v-flex xs6 class="d-flex justify-end">
                <div class="pt-5 pr-3" v-if="isRootClient">
                    <v-btn rounded depressed color="primary" :to="{name: 'create-client'}">Add new client</v-btn>
                </div>
            </v-flex>
        </v-layout>

        <client-list-overview />
    </v-container>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import ClientListOverview from '../../components/Clients/ClientListOverview';

export default {
  name: 'ClientManagement',
  computed: {
    ...mapGetters('clients', ['isRootClient'])
  },
  components: {ClientListOverview},
  methods: {
    ...mapMutations('cms', ['updatePageTitle']),
    ...mapMutations('clients', ['setSearchQuery']),
    onUpdateSearch(query) {
      this.setSearchQuery(query);
    }
  },
  mounted() {
    this.updatePageTitle('Client management');
  }
};
</script>
