<template>
    <v-container style="background-color:#f5f5f5;" class="pa-10">
        <v-row>
            <v-flex>
                <h3 class="subtitle-2">Add min/max values</h3>
            </v-flex>
        </v-row>
        <v-row>
            <v-flex xs12>
                <v-text-field type="number" min="0" label="Min" v-model="minimum" style="max-width:200px;"
                              @blur="updateMinData"></v-text-field>
            </v-flex>
            <v-flex xs12>
                <v-text-field type="number" min="0" label="Max" v-model="maximum" @blur="updateMaxData"
                              style="max-width:200px;"></v-text-field>
            </v-flex>
        </v-row>
    </v-container>
</template>

<script>
import {minMaxMixin} from './mixins/min-max';

export default {
  name: 'Numeric',
  mixins: [minMaxMixin]
};
</script>
