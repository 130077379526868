<template>
    <div class="pa-3">
        <v-card flat>
            <v-card-title>
                Welcome to the application
            </v-card-title>
            <v-card-text>
                Start of by managing your surveys, team etc... by using the menu of your left.
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {mapMutations} from 'vuex';

export default {
  name: 'Dashboard',
  methods: {
    ...mapMutations('cms', ['updatePageTitle'])
  },
  created() {
    this.updatePageTitle('Dashboard');
  }
};
</script>
