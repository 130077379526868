<template>
    <div>
        <v-tabs>
            <v-tab>Section</v-tab>
            <v-tab>Conditions</v-tab>
            <locale-picker :selected="locale" type="tab"></locale-picker>
            <v-tab-item transition="fade" reverse-transition="fade">
                <v-form>
                    <v-card flat>
                        <v-card-text>
                            <v-text-field
                                    :counter="30"
                                    label="Section title"
                                    required
                                    :placeholder="locale !== 'en' ? section.title['en'] : ''"
                                    v-model="section.title[locale]"
                            ></v-text-field>
                            <v-textarea
                                    name="input-7-1"
                                    label="Section description"
                                    value="What is this section about?"
                                    auto-grow
                                    filled
                                    :placeholder="locale !== 'en' ? section.description['en'] : ''"
                                    v-model="section.description[locale]"
                            ></v-textarea>

                            <survey-list-questions :pageId="section.pageId" :sectionId="section.sectionId"
                                                   :children="section.children" v-model="questions"/>

                            <v-btn color="primary" :outlined=!isModified rounded @click="onSave">Update</v-btn>

                        </v-card-text>
                    </v-card>
                </v-form>
            </v-tab-item>
            <v-tab-item transition="fade" reverse-transition="fade">
                <v-card flat>
                    <v-card-text>
                        <p>Please select the condition you want to add to this question</p>
                        <v-divider class="my-4"/>
                        <h3 class="subtitle-2">Visible if</h3>
                        <v-container grid-list-md>
                            <v-layout row wrap>
                                <v-flex xs5>
                                    <v-autocomplete
                                            type="search"
                                            v-model="selectedQuestion"
                                            :items="surveyQuestions"
                                            item-value="id"
                                            item-text="`${data.item.priority}, ${data.item.title.en}`"
                                            filled
                                            clearable
                                            :filter="questionFilter"
                                            return-object
                                            label="Choose question"
                                            style="max-width: 100%">
                                        <template slot="item" slot-scope="data">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ data.item.index }}- {{ data.item.title.en }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.index }} - {{ data.item.title.en }}
                                        </template>
                                    </v-autocomplete>
                                </v-flex>

                                <v-flex xs3>
                                    <v-select
                                            :items="conditions"
                                            v-model="sectionConditions.operator"
                                            item-text="text"
                                            item-value="value"
                                            filled
                                            label="Choose condition"
                                    ></v-select>
                                </v-flex>

                                <v-flex xs4>
                                    <template v-if="selectedQuestion.inputType === questionFieldTypesConstants.dropdown ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.multiple ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.cost ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.checkbox ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.radio">
                                        <v-autocomplete
                                                type="search"
                                                :items="selectedQuestion.options"
                                                item-text="option.en"
                                                item-value="id"
                                                filled
                                                clearable
                                                label="Value"
                                                v-model="sectionConditions.value"
                                        ></v-autocomplete>
                                    </template>
                                    <template
                                            v-else-if="selectedQuestion.inputType === questionFieldTypesConstants.boolean">
                                        <v-select
                                                :items="optionsTrueFalse"
                                                filled

                                                label="Value"
                                                v-model="sectionConditions.value"
                                        ></v-select>
                                    </template>
                                    <template v-else>
                                        <v-text-field
                                                filled
                                                label="Value"
                                                required
                                                v-model="sectionConditions.value"
                                        ></v-text-field>
                                    </template>
                                </v-flex>
                            </v-layout>
                            <v-layout row v-if="roles.length > 0">
                                <v-flex xs12>
                                    <v-autocomplete
                                            v-model="selectedRole"
                                            :items="roles"
                                            filled
                                            multiple
                                            clearable
                                            @click:clear="selectedRole=[]"
                                            item-value="roleName"
                                            label="Choose Role"
                                            style="max-width:100%">
                                        <template slot="item" slot-scope="data">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ data.item.title.en }} ({{ data.item.roleName }})
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.title.en }} ({{ data.item.roleName }})
                                        </template>
                                    </v-autocomplete>
                                </v-flex>
                            </v-layout>
                            <div class="d-block pt-2">
                                <div class="d-flex">
                                    <v-btn small rounded outlined color="primary" class="mr-3"
                                           @click="onSaveConditions">
                                        {{ conditionButtonText }}
                                    </v-btn>
                                    <v-btn small rounded outlined color="primary" @click="onDeleteConditions"
                                           v-if="showDeleteConditionButton">
                                        Delete condition
                                    </v-btn>
                                </div>
                            </div>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>

        <div class="px-7 pb-4">

            <v-alert type="error" icon="error_outline" v-if="isError" class="mt-3">
                There was an error saving. Please try again later, or contact SA Remote
            </v-alert>

            <v-alert type="success" icon="error_outline" v-if="isSaved" class="mt-3">
                Details have been saved
            </v-alert>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import EventBus from "../../../../eventBus";
import SurveyListQuestions from "../Components/SurveyListQuestions";
import surveys from "../../../../helpers/surveys";
import constants from "../../../../helpers/constants";
import {surveyDisplayConditionDelete, surveyUpdateList} from "../../../../graphql/mutations/surveys";
import LocalePicker from "../../../../components/I18N/LocalePicker";
import _ from "lodash";
import {serial} from "../../../../helpers/promise";

export default {
  name: "survey-section",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showDeleteConditionButton: false,
    conditionButtonText: "Save condition",
    selectedQuestion: [],
    conditions: surveys.conditions,
    questionFieldTypesConstants: constants.questionFieldTypesConstants,
    isSaved: false,
    isError: false,
    isLoading: false,
    section: null,
    optionsTrueFalse: ["true", "false"],
    selectedRole: [],
    sectionConditions: {
      conditionQuestionId: null,
      operator: null,
      value: null
    },
    resetStatusAfterSeconds: 2000,
    conditionChanged: false,
    questions: [],
    changed: {
      questions: false,
      section: false
    },
    original: {
      questions: [],
      section: {}
    }
  }),
  computed: {
    ...mapState("surveys", ["currentSurvey" ]),
    ...mapGetters("surveys", ["surveyQuestions", 'currentSurveyId']),
    ...mapState("roles", {
      "roles": (state) => {
        return state.rolesForClient || [];
      }
    }),
    ...mapState(["locale"]),
    isModified() {
      return Object.values(this.changed).find(f => f === true);
    }
  },
  watch: {
    selectedQuestion(val, oldVal) {
      if (val === undefined) {
        this.selectedQuestion = {};
        this.sectionConditions.conditionQuestionId = null;
        this.sectionConditions.value = null;
      } else {
        if (val !== oldVal) {
          this.sectionConditions.conditionQuestionId = val.id;
        }
      }
    },
    sectionConditions: {
      deep: true,
      handler() {
        this.conditionChanged = true;
      }
    },
    questions(val) {
      this.changed.questions = val !== this.original.questions;
    },
    section: {
      deep: true,
      handler(val) {
        let titleEqual = _.isEqual(val.title, this.original.section.title);
        let descriptionEqual = _.isEqual(val.description, this.original.section.description);
        this.changed.section = !titleEqual || !descriptionEqual;
      }
    }
  },
  components: {
    SurveyListQuestions,
    LocalePicker
  },
  methods: {
    ...mapMutations("surveys", ["setCurrentPageId"]),
    questionFilter(item, search, itemText) {
      let [firstPart, remainder] = search.split(" ", 2);
      let searchRegexp = new RegExp(`^${firstPart}`);
      if (searchRegexp.test(item.index)) {
        if (remainder) {
          return item.title.en.toLocaleLowerCase().indexOf(remainder.toLocaleLowerCase()) > -1;
        } else {
          return true;
        }
      }
      return item.title.en.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
    },
    resetSavedStatus() {
      //Reset status
      setTimeout(() => {
        this.isSaved = false;
        this.conditionChanged = false;
      }, this.resetStatusAfterSeconds);
    },
    onSaveConditions() {
      let promises = [];

      if (this.conditionChanged) {
        promises.push(this.$store.dispatch("surveys/onDisplayConditionsUpdate", {
          id: this.sectionConditions.id || null,
          conditionQuestionId: this.sectionConditions.conditionQuestionId,
          operator: this.sectionConditions.operator,
          value: this.sectionConditions.value,
          sectionId: this.section.id
        }).then((conditionId) => {
          this.sectionConditions.id = conditionId;
          this.conditionButtonText = "Update condition";
          this.showDeleteConditionButton = true;
        }));
      }
      if (this.data.roleName !== this.selectedRole) {
        promises.push(this.$store.dispatch("surveys/updateRole", {
          roleName: this.selectedRole,
          sectionId: this.section.id
        }).then((role) => {
          this.conditionButtonText = "Update condition";
        }));
      }
      if (promises.length === 0) {
        return false;
      }
      Promise.all(promises).then(() => {
        this.isLoading = false;
        this.isSaved = true;
        this.isError = false;
        EventBus.$emit("surveyUpdated");

        this.resetSavedStatus();
      }).catch((error) => {
        console.error(error);
        this.isError = true;
      });
    },
    onSave() {
      let promises = [];
      if (this.changed['questions']) {
        promises.push(() => {
          return this.$apollo.mutate({
            mutation: surveyUpdateList,
            variables: {
              surveyId: this.currentSurveyId,
              type: 'question', //We are updating a list of questions
              listMap: this.questions.map((entry, priority) => {
                let weight = parseInt(entry.weight);
                if (isNaN(weight)) {
                  weight = 1;
                }
                return {id: entry.id, weight: weight, priority};
              })
            }
          });
        });
      }
      if (this.changed.section) {
        const sectionData = {
          sectionId: this.section.sectionId,
          title: this.section.title,
          description: this.section.description,
          pageId: this.section.pageId
        };
        promises.push(() => {
          return this.$store.dispatch("surveys/onUpdateSectionToPage", sectionData);
        });
      }
      if (promises.length === 0) {
        return;
      }
      this.isLoading = true;

      serial(promises).then(() => {
        this.isLoading = false;
        this.isSaved = true;
        this.isError = false;
        EventBus.$emit("surveyUpdated");

        //Reset status
        this.resetSavedStatus();
      }).catch(() => {
        this.isError = true;
        this.isLoading = false;
      });
    },
    onDeleteConditions() {
      this.$apollo.mutate({
        mutation: surveyDisplayConditionDelete,
        variables: {
          surveyId: this.currentSurvey.id,
          id: this.sectionConditions.id
        }
      }).then(data => {
        this.showDeleteConditionButton = false;
        this.conditionButtonText = "Save condition";
        this.isSaved = true;
        this.selectedQuestion = [];
        this.sectionConditions = {
          conditionQuestionId: null,
          operator: null,
          value: null
        };
        EventBus.$emit("surveyUpdated");

        this.resetSavedStatus();
      }).catch(() => {
        this.isError = true;
      });
    },
    setSection() {
      this.section = {
        ...this.data,
        sectionId: this.data.id,
        title: this.data.title,
        description: this.data?.description?.en ? this.data.description : {"en": ""},
        children: this.data.children,
        pageId: this.data.page.id
      };
      if (this.data.displayConditions && this.data.displayConditions.length > 0) {
        this.data.displayConditions.map((displayCondition) => {
          if (displayCondition.conditionQuestionId) {
            if (this.surveyQuestions.length > 0) {
              this.selectedQuestion = this.surveyQuestions.find((question) => question.id === this.data.displayConditions[0].conditionQuestionId);
            }
            this.sectionConditions = {
              id: displayCondition.id,
              conditionQuestionId: displayCondition.conditionQuestionId,
              operator: displayCondition.operator,
              value: displayCondition.value
            };
          }
        });

        this.conditionButtonText = "Update condition";
        this.showDeleteConditionButton = true;
      }
      if (this.data.roleName) {
        this.selectedRole = this.data.roleName;
      }
      this.original.section = _.cloneDeep(this.section);
    }
  },
  created() {
    this.setCurrentPageId(this.data.page.id);
    this.setSection();
    this.$store.dispatch("roles/getRolesForClientSurvey");
  }
};
</script>
