<template>
    <div class="status-label" style="display:inline-block;"
         :class="{
        'status-label--warning': status === 'pendingAdmin' || status === 'pendingUser',
        'status-label--success': status === 'active',
        'status-label--error': status === 'rejected' || status === 'deleted'}">
        <span>
            {{status}}
        </span>
    </div>
</template>

<script>
export default {
  name: 'StatusLabel',
  props: {
    status: {
      type: String,
      default: 'pendingUser'
    }
  }
};
</script>
