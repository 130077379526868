import { apolloClient } from "../../../../../apollo/init-apollo";
import { surveyQuestionOptionDelete } from "../../../../../graphql/mutations/surveys";

export const choicesDefaultMixin = {
  data: () => ({
    showSort: false,
    validationRules: [(v) => !!v || "The input is required"],
    options: [
      {
        id: null,
        priority: 1,
        option: { en: "" },
      },
      {
        id: null,
        priority: 2,
        option: { en: "" },
      },
    ],
  }),
  watch: {
    options: {
      deep: true,
      handler() {
        this.updateData();
      },
    },
  },
  methods: {
    onDeleteAll() {
      this.options = [];
      this.options = [
        {
          priority: 1,
          option: { en: "" },
        },
      ];
      this.updateData();
    },
    onAddChoice(locale) {
      this.options.push({
        priority: this.options.length + 1,
        option: { en: "", [locale]: "" },
        ratingValue: 0,
      });
    },
    onSort() {
      let options = [...this.options];
      options.sort((a, b) => a.priority - b.priority);
      this.options = options.map((option, index) => {
        return { ...option, priority: index + 1 };
      });
    },
    toggleSort() {
      if (this.showSort) {
        this.onSort();
        this.showSort = false;
      } else {
        this.showSort = true;
      }
    },
    onImport(options, locale) {
      options.forEach((optionText) => {
        this.options.push({
          priority: this.options.length + 1,
          option: { en: optionText, [locale]: optionText },
        });
      });
      this.updateData();
    },
    async onDeleteChoice(index, id = null) {
      this.options.splice(index, 1);
      if (id) {
        try {
          await apolloClient.mutate({
            mutation: surveyQuestionOptionDelete,
            variables: {
              optionId: id,
            },
          });
          this.updateData();
        } catch (e) {
          return e;
        }
      } else {
        this.updateData();
      }
    },
    updateData() {
      this.$emit("updateOptions", [...this.options]);
    },
  },
  created() {
    if (this.data) {
      let options = this.data.map((item, index) => {
        console.log(item);
        return {
          id: item.id,
          priority: index,
          option: item.option,
          ratingValue: item.ratingValue,
        };
      });
      this.options = [...options];
      this.updateData();
    }
  },
};
