import gql from 'graphql-tag';

export const LocalizedInputFragment = gql`
fragment localizedFragment on LocaleString {
    da
    en
    es
    pt
    pt_BR
    ru
}`;
