<template>
    <div style="background-color:#f5f5f5;" class="pa-3 my-4">
        <v-toolbar flat class="mb-3">
            <v-toolbar-title class="grey--text">Pages</v-toolbar-title>
            <v-spacer></v-spacer>
            <button-add-to-survey :remove-shadow="true" button-title="Add page" add-mode="SurveyPage"/>
        </v-toolbar>
        <v-list>
            <draggable v-model="currentSurvey.children" @start="drag=true" @end="setPrioritization">
                <v-list-item v-for="(element, index) in currentSurvey.children" :key="element.id" link
                             class="u-border-bottom u-drag"
                             :class="{'u-border-bottom-none': (index + 1) === currentSurvey.children.length }">
                    <v-list-item-avatar>
                        <v-avatar
                                :tile="false"
                                :size="30"
                                color="primary"
                        >
                            <span class="white--text">P</span>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{element.title.en}}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-menu bottom left>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        light
                                        icon
                                        small
                                        v-on="on"
                                >
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item link @click="deletePage(element)">
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="currentSurvey.children.length === 0">
                    <v-list-item-content>
                        No pages added
                    </v-list-item-content>
                </v-list-item>
            </draggable>
        </v-list>
    </div>
</template>

<script>
import EventBus from '../../../../eventBus';
import ButtonAddToSurvey from '../../../UI/Buttons/ButtonAddToSurvey';
import {mapState} from 'vuex';
import draggable from 'vuedraggable';
import {surveyPagePrioritize} from '../../../../graphql/mutations/surveys';

export default {
  name: 'survey-list-pages',
  data: () => ({
    showTooltip: false,
    drag: false
  }),
  computed: {
    ...mapState('surveys', [
      'currentSurvey'
    ])
  },
  components: {
    draggable,
    ButtonAddToSurvey
  },
  methods: {
    setPrioritization() {
      this.drag = false;
      const pages = this.currentSurvey.children.reduce(function(acc, page) {
        return acc.concat(page.id);
      }, []);
      this.$apollo.mutate({
        mutation: surveyPagePrioritize,
        variables: {
          surveyId: this.currentSurvey.id,
          pages: JSON.stringify(pages)
        }
      }).then(data => {
        EventBus.$emit('surveyUpdated');
      }).catch(error => {
        alert(error);
      });
    },
    deletePage(page) {
      this.$store.dispatch('surveys/onDeletePage', page.id).then(() => {
        EventBus.$emit('surveyUpdated');
      });
    }
  }
};
</script>

<style>
    .u-opacity-80 {
        opacity: .8;
    }
</style>
