<template>
    <v-dialog
            v-model="dialog"
            max-width="400">
        <v-card>
            <v-card-title class="headline">Invite user</v-card-title>
            <v-card-text>
                <p>Enter the email of the person you wish to invite</p>
                <v-form @submit.prevent="onAdd">
                    <v-text-field
                            v-model="name"
                            label="Name"
                            name="name"
                            type="text"
                            autofocus
                    ></v-text-field>
                    <v-text-field
                            v-model="email"
                            label="Email"
                            name="email"
                            type="text"
                            autofocus
                    ></v-text-field>
                    <div class="text-center mt-4">
                        <v-btn color="primary" :loading="isLoading" min-width="150" outlined rounded type="submit">
                            Invite user
                        </v-btn>
                    </div>
                    <v-alert type="success" v-if="isSuccess" class="mt-3">
                        <small>User has been invited</small>
                    </v-alert>
                    <v-alert type="error" icon="error_outline" v-if="isError" class="mt-3">
                        <small>There was an error sending invite. The user might already exist. Try again later or contact SA Remote</small>
                    </v-alert>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
import EventBus from '../../../eventBus';

export default {
  name: 'InviteUser',
  data: () => ({
    isError: false,
    isSuccess: false,
    email: '',
    name: '',
    dialog: true,
    isLoading: false
  }),
  watch: {
    dialog(val) {
      this.onCloseModal();
    }
  },
  methods: {
    onAdd() {
      this.isLoading = true;
      this.$store.dispatch('users/inviteUser', {
        name: this.name,
        email: this.email
      }).then((res) => {
        EventBus.$emit('userInvited');
        this.isLoading = false;
        this.isSuccess = true;
        this.name = '';
        this.email = '';
        setTimeout(() => {
          this.isSuccess = false;
        }, 2000);
      }).catch((e) => {
        this.isLoading = false;
        this.isError = true;
      });
    },
    onCloseModal() {
      this.$store.dispatch('modals/closeModal');
    }
  },
  destroyed() {
    //EventBus.$off('userInvited');
  }
};
</script>
