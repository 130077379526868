import gql from "graphql-tag";
import { LocalizedInputFragment } from "../fragtments/localizedInput";

export const getCurrentClient = gql`
  query client {
    client {
      id
      name
      subdomain
      color
      logo
      isRoot
    }
  }
`;

export const getAllClients = gql`
  query clients {
    clients {
      id
      name
      subdomain
      color
      logo
      adminCount
      userCount
      companies {
        id
        name
        accountHolderName
        accountHolderEmail
        accountHolderPhone
      }
      admins {
        id
        email
        name
      }
    }
  }
`;

export const getClientsAndSurveys = gql`
  query clients {
    clients {
      id
      name
      isRoot
      surveys {
        id
        title {
          ...localizedFragment
        }
        isActive
        category {
          id
        }
        priority
        createdAt
        type
        updatedAt
      }
    }
  }
  ${LocalizedInputFragment}
`;

export const getClientsAndRoles = gql`
  query clientsAndRoles {
    clients {
      id
      name
      isRoot
      roles {
        id
        title {
          ...localizedFragment
        }
        roleName
        userType
      }
    }
  }
  ${LocalizedInputFragment}
`;

export const getClientByID = gql`
  query client($clientId: ID!) {
    client(clientId: $clientId) {
      id
      name
      subdomain
      color
      logo
      accountHolderEmail
      accountHolderName
      accountHolderPhone
      createdAt
      adminCount
      userCount
      companies {
        id
        name
        accountHolderName
        accountHolderEmail
        accountHolderPhone
      }
      admins {
        id
        email
        name
      }
    }
  }
`;

//From ClientId in http header
export const getAllCompaniesFromClient = gql`
  query companies($clientId: ID!) {
    companies(clientId: $clientId) {
      id
      name
      accountHolderName
      accountHolderEmail
      accountHolderPhone
    }
  }
`;
