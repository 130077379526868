import {apolloClient} from '../../apollo/init-apollo';
import {getStaticPageByID, getStaticPages, getStaticPagesByClient} from '../../graphql/queries/static';
import {createStaticPage, updateStaticPage} from '../../graphql/mutations/static';

export const state = {
  pages: [],
  clientsAndPages: [],
  currentPage: null
};

export const getters = {
  rootPages: state => {
    return state.clientsAndPages.filter((client) => client.isRoot);
  },
  clientPages: state => {
    return state.clientsAndPages.filter((client) => !client.isRoot);
  }
};

export const mutations = {
  setPages(state, pages) {
    state.pages = pages;
  },
  setCurrentPage(state, page) {
    state.currentPage = page;
  },
  setClientsPages(state, clients) {
    state.clientsAndPages = clients;
  }
};

export const actions = {
  async getAllPages({commit}) {
    const {data} = await apolloClient.query({
      query: getStaticPages,
      fetchPolicy: 'no-cache'
    });
    try {
      commit('setPages', data.staticPages);
    } catch (e) {
      return e;
    }
  },
  async getClientsPages({commit}) {
    const {data} = await apolloClient.query({
      query: getStaticPagesByClient,
      fetchPolicy: 'no-cache'
    });

    try {
      commit('setClientsPages', data.clients);
    } catch (e) {
      return e;
    }
  },
  async getPageById({commit}, id) {
    const {data} = await apolloClient.query({
      query: getStaticPageByID,
      fetchPolicy: 'no-cache',
      variables: {
        id: id
      }
    });

    try {
      commit('setCurrentPage', data.staticPage);
    } catch (e) {
      return e;
    }
  },
  async createPage({commit}, payload) {
    const {data} = apolloClient.mutate({
      mutation: createStaticPage,
      variables: {
        title: {en: payload.title},
        content: {en: payload.content}
      }
    });

    try {
      return data;
    } catch (e) {
      return e;
    }
  },

  async updatePageById({commit}, payload) {
    const {data} = apolloClient.mutate({
      mutation: updateStaticPage,
      variables: {
        staticId: payload.id,
        title: payload.title,
        content: payload.content
      }
    });

    try {
      return data;
    } catch (e) {
      return e;
    }
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
