<template>
  <div :class="{'status--text': showLabel, 'status--active': isActive}">
    <strong v-if="prefix">{{prefix}}</strong>
    <div class="status"
         :class="{
      'status--open': status === 'OPEN',
      'status--closed': status === 'CLOSED',
      'status--submitted': status === 'SUBMITTED',
      'status--partially-submitted': status === 'PARTIALLY_SUBMITTED',
      'status--cancelled': status === 'CANCELLED',
      'status--finalized': status === 'FINALIZED',
      'status--pending-finalization': status === 'PENDING_FINALIZATION',
      'status--label': showLabel}"></div>
    <span v-if="showLabel" class="title-case">
      {{ statusText }}
        </span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import formatStatus from '../../filters/format-status';
export default {
  name: "StatusIndicator",
  props: {
    status: {
      type: String,
      required: true
    },
    showLabel: {
      type: Boolean,
      default() {
        return false;
      }
    },
    handleCurrent: {
      type: Boolean,
      default() {
        return true;
      }
    },
    prefix: {
      type: String,
      default() {
        return "";
      }
    }
  },
  computed: {
    ...mapState("submittedSurveys", {
      "activeStatus": (state) => {
        return state.status;
      }
    }),
    isActive() {
      return this.handleCurrent && this.status === this.activeStatus;
    },
    statusText() {
      return formatStatus(this.status);
    }
  }
};
</script>

<style scoped lang="scss">
.status {
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &--text {
    font-size: 13px;

    .title-case {
      text-transform: capitalize;
    }
  }
  &--active {
    font-weight: bold;

  }
  &--open {
    background-color: var(--warning-color);
  }

  &--pending-finalization {
    background-color: rgb(234, 132, 32);
  }

  &--finalized {
    background-color: rgb(30, 132, 32);
  }

  &--submitted {
    background-color: var(--info-color);
  }

  &--partially-submitted {
    background-color: var(--info-color);
  }

  &--closed {
    background-color: var(--success-color);
  }

  &--success {
    background-color: var(--success-color);
  }

  &--warning {
    background-color: var(--warning-color);
  }

  &--cancelled {
    background-color: var(--alert-color);
  }

  &--error {
    background-color: red;
  }

  &--label {
    position: relative;
    top: 5px;
    float: left;
    margin-right: 10px;
  }

}

</style>
