<template>
    <div>
        <div>{{ question.question.en }}</div>
        <div class="" v-if="question.helpShort.en">{{ question.helpShort.en }}</div>
        <div class="" v-if="question.helpDetail.en">{{ question.helpDetail.en }}</div>
        <div class="text-line" v-for="line of lines" :key="line"
             :class="[line > 1 && line === lastLine ? 'bottom-line' : '']"></div>
    </div>
</template>

<script>
export default {
  name: 'GenericQuestionString',
  props: {
    question: {
      required: true
    }
  },
  computed: {
    lines() {
      if (this.question.inputType === 'IMO' || this.question.inputType === 'NUMERIC') {
        return 1;
      }
      return 3;
    },
    lastLine() {
      return this.lines;
    }
  }
};
</script>

<style scoped>
.text-line {
    height: 1.5rem;
    border-bottom: 1px solid black;
}

.bottom-line {
    margin-bottom: 1.5rem;
}
</style>
