import gql from 'graphql-tag';

export const createStaticPage = gql`
    mutation staticSet($title: LocaleStringInput!, $content: LocaleStringInput!) {
        staticSet(
            title: $title,
            content: $content
        ) {
            success,
            message
        }
    }
`;

export const updateStaticPage = gql`
    mutation staticSet($staticId: ID, $title: LocaleStringInput!, $content: LocaleStringInput!) {
        staticSet(
            staticId: $staticId,
            title: $title,
            content: $content
        ) {
            success,
            message
        }
    }
`;

export const cloneStaticPage = gql`
    mutation staticClone($id: ID!, $clientId: ID!) {
        staticClone(
            id: $id,
            clientId: $clientId
        )
    }
`;

export const deleteStaticPage = gql`
    mutation staticDelete($id: ID!) {
        staticDelete(
            id: $id
        )
    }
`;
