<template>
    <v-app>
        <page-transition>
            <router-view/>
        </page-transition>
    </v-app>
</template>

<script>
import './assets/scss/main.scss';
import 'vue-wysiwyg/dist/vueWysiwyg.css';

export default {
  name: 'App',
  created() {
    this.$store.dispatch('clients/getCurrentClientTheme').then((data) => {
      this.$vuetify.theme.themes.light.primary = data.client.color;
    });
  }
};
</script>
