<template>
    <span class="text-capitalize">{{answer ? 'yes' : 'no'}}</span>
</template>

<script>
export default {
  name: 'GenericAnswerBoolean',
  props: {
    answer: {
      required: true
    }
  }
};
</script>
