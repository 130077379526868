<template>
    <div class="survey-info">
        <h3>Info</h3>
        <v-text-field
                :value="survey.title.en"
                label="Survey title"
                disabled
        ></v-text-field>
        <v-textarea
                name="input-7-1"
                label="Description of the survey"
                auto-grow
                filled
                disabled
                :value="getFieldValue(survey.description)"
        ></v-textarea>
        <v-text-field
                :value="survey.type"
                label="Type"
                disabled
        ></v-text-field>
    </div>
</template>

<script>
import GetFieldValueMixin from "../../../../mixins/GetFieldValueMixin";

export default {
  name: 'Info',
  mixins: [GetFieldValueMixin],
  props: {
    survey: {
      required: true
    }
  }
};
</script>
