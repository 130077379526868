<template>
    <v-container>
        <v-layout row wrap class="mb-7">
            <v-flex xs6>
                <div class="pa-3">
                    <v-text-field label="Filter users" @input="onUpdateSearch" placeholder="Type to filter by name"
                                  rounded clearable solo flat/>
                </div>
            </v-flex>
            <v-flex xs6 class="d-flex justify-end">
                <div class="pt-5 pr-3">
                    <v-btn rounded depressed color="primary" @click="onInvite">Invite user to team</v-btn>
                </div>
            </v-flex>
        </v-layout>
        <div class="users__cms">
            <active-indicators-row/>
            <user-list-overview/>
        </div>
        <div class="users__app" v-if="!isRootClient">
            <user-list-app-overview/>
        </div>
        <invite-user v-if="showInviteUserModal"/>
    </v-container>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex';
import ActiveIndicatorsRow from '../../components/UI/ActiveIndicatorsRow';
import UserListOverview from '../../components/Users/UserListOverview';
import InviteUser from '../../components/Users/Modals/InviteUser';
import UserListAppOverview from '../../components/Users/UserListAppOverview';

export default {
  name: 'UserManagement',
  components: {UserListAppOverview, InviteUser, UserListOverview, ActiveIndicatorsRow},
  computed: {
    ...mapGetters('clients', ['isRootClient']),
    ...mapState('modals', ['showInviteUserModal'])
  },
  methods: {
    ...mapMutations('cms', ['updatePageTitle']),
    ...mapMutations('users', ['setSearchQuery']),
    onInvite() {
      this.$store.dispatch('modals/toggleModal', 'showInviteUserModal');
    },
    onUpdateSearch(query) {
      this.setSearchQuery(query);
    }
  },
  mounted() {
    this.updatePageTitle('User management');
  }
};
</script>
