<template>
    <v-card flat>
        <v-card-title class="client-info__heading">
            <div class="d-flex" style="width:100%">
                <div>
                    Details
                </div>
                <div class="text-right grow" v-if="isRootClient">
                    <v-btn rounded outlined small color="primary" :to="{name: 'edit-client', params: {clientId: currentClient.id } }">Edit</v-btn>
                </div>
            </div>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-container fluid>
                <v-layout row class="mb-4">
                    <v-flex md2>
                        <v-icon color="dark" class="d-inline-block mr-2" :size="20">account_circle</v-icon>
                        <h4 class="d-inline-block">Client</h4>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex md6 class="pl-7">
                        <div class="mb-3">
                            <div class="u-label">Name</div>
                            <div class="u-value" v-if="currentClient.name">{{currentClient.name}}</div>
                            <div class="u-value" v-else>-</div>
                        </div>

                        <div class="mb-3">
                            <div class="u-label">Web</div>
                            <div class="u-value" v-if="currentClient.subdomain">https://{{currentClient.subdomain}}.saremote.com</div>
                            <div class="u-value" v-else>-</div>
                        </div>

                        <div class="mb-3">
                            <div class="u-label">Team users</div>
                            <div class="u-value">{{currentClient.adminCount || '0'}}</div>
                        </div>

                        <div class="mb-3">
                            <div class="u-label">App users</div>
                            <div class="u-value">{{currentClient.userCount || '0' }}</div>
                        </div>
                    </v-flex>
                    <v-flex md6>
                        <div class="mb-3">
                            <div class="u-label">Account holder</div>
                            <div class="u-value" v-if="currentClient.accountHolderName">{{currentClient.accountHolderName}}</div>
                            <div class="u-value" v-else>-</div>
                        </div>
                        <div class="mb-3">
                            <div class="u-label">Account holder</div>
                            <div class="u-value" v-if="currentClient.accountHolderEmail">{{currentClient.accountHolderEmail}}</div>
                            <div class="u-value" v-else>-</div>
                        </div>
                        <div class="mb-3">
                            <div class="u-label">Account holder</div>
                            <div class="u-value" v-if="currentClient.accountHolderPhone">{{currentClient.accountHolderPhone}}</div>
                            <div class="u-value" v-else>-</div>
                        </div>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

export default {
  name: 'ClientInfo',
  computed: {
    ...mapState('clients', ['currentClient']),
    ...mapState('users', ['appUsers']),
    ...mapGetters('clients', ['isRootClient'])
  }
};
</script>
