<template>
    <div style="background-color:#f5f5f5;" class="pa-3 my-4">
        <v-toolbar flat class="mb-3">
            <v-toolbar-title class="grey--text">Sections</v-toolbar-title>
            <v-spacer></v-spacer>
            <button-add-to-survey :remove-shadow="true" :page-id="pageId" button-title="Add section"
                                  add-mode="SurveySection"/>
            <v-btn @click="evenWeight">Even all weights</v-btn>
        </v-toolbar>
        <v-list>
            <draggable v-model="sections" @start="startPrioritization" @end="setPrioritization">
                <v-list-item link v-for="(section, index) in cSections" :key="index"
                             class="u-border-bottom u-drag"
                             :class="{'u-border-bottom-none': (index + 1) === cSections.length }">
                    <v-list-item-avatar>
                        <v-avatar
                                :tile="false"
                                :size="30"
                                color="primary"
                        >
                            <span class="white--text">S</span>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ section.title.en }}</v-list-item-title>
                        <v-text-field label="Weight" v-model="section.weight" type="number"
                                      @change="weightModified"></v-text-field>

                    </v-list-item-content>
                    <v-list-item-action>
                        <v-menu bottom left>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        light
                                        icon
                                        small
                                        v-on="on"
                                >
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item link @click="deleteSection(section)">
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item v-if="!currentSections || currentSections.length === 0">
                    <v-list-item-content>
                        No sections added
                    </v-list-item-content>
                </v-list-item>
            </draggable>
        </v-list>
        <span class="mt-2">Total weight: {{ totalWeight }}</span>

    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import EventBus from "../../../../eventBus";
import ButtonAddToSurvey from "../../../UI/Buttons/ButtonAddToSurvey";
import draggable from "vuedraggable";

export default {
  name: "survey-list-sections",
  data: () => ({
    sections: [],
    cSections: [],
    drag: false
  }),
  props: {
    children: {
      required: true
    },
    pageId: {
      required: true
    }
  },
  computed: {
    ...mapState("surveys", ["currentSurvey"]),
    ...mapGetters("surveys", ["currentSections"]),
    totalWeight() {
      return this.cSections.reduce((prev, curr) => prev + parseInt(curr.weight || 0), 0);
    }
  },
  components: {
    ButtonAddToSurvey,
    draggable
  },
  watch: {
    currentSections(newValue) {
      this.cSections = newValue;
    }
  },
  methods: {
    evenWeight() {
      let maxWeight = 100;
      let childCount = this.cSections.length;
      this.cSections = this.cSections.map((entry) => ({
        ...entry,
        weight: Math.floor(maxWeight / childCount)
      }));
      this.$emit('input', this.sections);
    },
    startPrioritization() {
      this.sections = this.cSections;
    },
    setPrioritization() {
      this.drag = false;
      this.$emit('input', this.sections);
      this.cSections = this.sections;
    },
    deleteSection(section) {
      this.$store.dispatch("surveys/onDeleteSection", section.id).then(() => {
        //Remove from local data
        this.sections = this.sections.filter((child, index, arr) => {
          return child.id !== section.id;
        });
        EventBus.$emit("surveyUpdated");
      });
    },
    weightModified() {
      this.$emit('input', this.cSections);
    },
  },
  created() {
    this.cSections = this.currentSections;
    this.sections = this.currentSections;
  }
};
</script>
