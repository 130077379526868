import {clearUserStorage} from '../helpers/authHelpers';

export default {
  user: {
    isAuthenticated: false
  },
  checkAuthentication() {
    const accessToken = this.getAccessToken();
    if (accessToken !== null) {
      //axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
      this.user.isAuthenticated = true;
      return true;
    } else {
      this.user.isAuthenticated = false;
      return false;
    }
  },

  getAccessToken() {
    return localStorage.getItem('access_token');
  },

  logOutUser() {
    clearUserStorage();
    this.user.isAuthenticated = false;
    window.location.href = '/';
  }
};
