<template>
  <v-list>
    <small style="font-weight:300;" class="pl-4">Answer</small>
    <v-list-item v-if="!answer || answer.length === 0">
      No answer to this question
    </v-list-item>
    <v-list-item v-else v-for="(option, index) in answer" :key="index">
      <v-list-item-content>
        {{ getFieldValue(option.answer) }}
      </v-list-item-content>
    </v-list-item>

  </v-list>
</template>

<script>
import GetFieldValueMixin from "@/mixins/GetFieldValueMixin";

export default {
  name: "AnswerOptions",
  mixins: [GetFieldValueMixin],
  props: {
    answer: {
      required: true
    }
  }
};
</script>
