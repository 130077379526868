<template>
    <div class="app-view show-v-text-field__details">
        <v-container class="pt-0 mt-0 mx-auto" style="max-width:400px;">
            <v-card flat class="mx-auto mt-10">
                <v-card-title>
                    Activate user
                </v-card-title>
                <v-card-text class="py-5 text-center">
                    <v-text-field
                            v-model="user.password"
                            label="Choose your password"
                            name="password"
                            type="password"
                            :rules="[rules.required, rules.min]"
                            class="mb-5"
                    ></v-text-field>
                    <v-btn rounded outlined color="primary" @click="onActivateUser">Set password</v-btn>
                </v-card-text>
            </v-card>

            <loading v-if="isLoading" />

            <v-alert type="info" dense v-if="isActivated">
                Your password have been set, and your user is now active
                <br />
                <v-btn rounded small outlined color="white" class="mt-2" @click="onGotoLogin">Login now</v-btn>
            </v-alert>

            <v-alert type="error" dense icon="error_outline" v-if="isError" class="mt-2">
                {{errorText}}
            </v-alert>

        </v-container>
    </div>
</template>

<script>
import {adminActivate} from '../graphql/mutations/users';

export default {
  name: 'EmailValidate',
  data: () => ({
    isLoading: false,
    isActivated: false,
    isError: false,
    errorText: '',
    user: {
      email: '',
      token: '',
      password: ''
    },
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters'
    }
  }),
  methods: {
    onGotoLogin() {
      this.$router.push('/');
    },
    onActivateUser() {
      this.isLoading = true;
      this.$apollo.mutate({
        mutation: adminActivate,
        variables: {
          email: this.user.email,
          token: this.user.token,
          password: this.user.password
        }
      }).then(data => {
        this.isLoading = false;
        this.isError = false;
        this.isActivated = true;
      }).catch(error => {
        this.errorText = error;
        this.isLoading = false;
        this.isError = true;
        this.isActivated = false;
      });
    }
  },
  created() {
    const url = window.location.href;
    const array = url.split('/');
    const token = array[array.length - 2];
    const email = array[array.length - 1];
    this.user.token = token;
    this.user.email = email;
  }
};
</script>
