<template>
    <v-container class="page-editor">
        <v-layout row wrap>
            <v-flex grow class="pa-3" v-if="page">
                <v-card flat class="mb-3">
                  <v-card-title class="justify-space-between">
                    <span>Edit page</span>
                    <locale-picker :selected="locale"></locale-picker>
                  </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <div class="mb-3">
                            <v-text-field label="Page name" placeholder="Page name" v-model="page.title[locale]"/>
                        </div>
                        <div class="mb-3">
                            <wysiwyg v-model="page.content[locale]"></wysiwyg>
                        </div>
                        <div class="pt-4">
                            <v-btn rounded depressed color="primary" @click="onUpdatePage">Save page</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
                <v-alert tile type="success" prominent v-if="isSaved">
                   The page have been updated.
                </v-alert>
                <v-alert tile type="error" prominent v-if="isError">
                    Error: {{errorText}}
                </v-alert>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import LocalePicker from '../../../components/I18N/LocalePicker';

export default {
  name: 'EditPage',
  components: {LocalePicker},
  data: () => ({
    page: {
      title: {},
      content: {}
    },
    isSaved: false,
    isLoading: false,
    isError: false,
    errorText: '',
    isErrorSaving: false,
  }),
  computed: {
    ...mapState('static', ['currentPage']),
    ...mapState(['locale'])
  },
  methods: {
    ...mapMutations('cms', ['updatePageTitle']),
    onUpdatePage() {
      this.isLoading = true;
      this.$store.dispatch('static/updatePageById', this.page).then(() => {
        const hideSuccessMessageAfterXMS = 3000;
        this.isLoading = false;
        this.isSaved = true;
        setTimeout(() => {
          this.isSaved = false;
        }, hideSuccessMessageAfterXMS);
      }).catch((err) => {
        this.isError = true;
        this.errorText = err;
        this.isLoading = false;
      });
    }
  },
  beforeCreate() {
    this.$store.dispatch('static/getPageById', this.$route.params.id).then((response) => {
      const page = Object.assign({}, this.currentPage);
      this.page.id = page.id;
      this.page.title = {...page.title};
      this.page.content = {...page.content};
      delete this.page.title.__typename;
      delete this.page.content.__typename;
      this.updatePageTitle('Edit page: ' + this.page.title.en);
      this.isLoading = false;
      this.isError = false;
    }).catch(() => {
      this.isLoading = false;
      this.isError = true;
    });
  }
};
</script>
