<template>
    <div>
        <v-form @submit.prevent="onSignIn">
            <v-text-field
                    ref="email"
                    label="Login"
                    name="login"
                    type="text"
                    v-model="user.email"
                    autofocus
            ></v-text-field>

            <v-text-field
                    autocomplete="true"
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    v-model="user.password"
            ></v-text-field>

            <v-alert type="error" icon="error_outline" v-if="isError">
                {{errorText}}
            </v-alert>

            <div class="text-center mt-4">
                <v-btn color="primary" type="submit" :loading="isLoading" min-width="150" outlined rounded>Sign in
                </v-btn>
            </div>
        </v-form>
        <div class="text-center pt-3">
            <forgot-password></forgot-password>
        </div>
    </div>
</template>

<script>
import ForgotPassword from './ForgotPassword';

export default {
  name: 'login-form',
  data() {
    return {
      isLoading: false,
      isError: false,
      errorText: '',
      user: {
        email: '',
        password: ''
      }
    };
  },
  components: {
    ForgotPassword
  },
  methods: {
    onSignIn() {
      const user = this.user;
      this.isLoading = true;
      this.$store.dispatch('users/onLoginUser', user).then((response) => {
        this.isLoading = false;
        this.$router.push('/admin');
      }).catch((err) => {
        this.$refs.email.focus();
        this.isLoading = false;
        this.isError = true;
        const errorText = err.graphQLErrors[0].message;
        if (errorText.includes('Admin not activated')) {
          this.errorText = 'Admin not activated. Email has not been confirmed.';
        } else {
          this.errorText = err.graphQLErrors[0].message;
        }
      });
    }
  }
};
</script>
