<template>
    <v-dialog
            v-model="dialog"
            max-width="400">
        <v-card>
            <v-card-title class="headline text-center d-block">Delete: {{survey.title.en}}</v-card-title>

            <v-card-text>
                <div class="text-center">
                    <v-form ref="form" @submit.prevent="onDelete">
                        <v-icon :size="60" color="primary" class="py-3">delete</v-icon>
                        <p>To delete this survey, please confirm by typing DELETE in the field below</p>
                        <v-text-field
                                v-model="deleteText"
                                label="Confirm"
                                name="confirm"
                                type="text"
                                :rules="validationRule"
                        ></v-text-field>
                        <div class="text-center">
                            <v-btn color="primary" type="submit" :loading="isLoading" min-width="150" outlined rounded>
                                Delete
                            </v-btn>
                        </div>
                    </v-form>
                </div>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
import constants from '../../../helpers/constants';

export default {
  name: 'ConfirmDeleteSurvey',
  props: {
    survey: {
      required: true
    }
  },
  data: () => ({
    validationRule: [v => (v) === constants.deleteMode.confirmText || 'Error in text'],
    dialog: true,
    deleteText: '',
    isLoading: false
  }),
  watch: {
    dialog(val) {
      this.onCloseModal();
    }
  },
  methods: {
    onValidateConfirm() {
      if (this.deleteText !== constants.deleteMode.confirmText) {
        this.$refs.form.validate();
        return false;
      } else {
        return true;
      }
    },
    onDelete() {
      this.isLoading = true;

      if (this.onValidateConfirm()) {
        this.$store.dispatch('surveys/surveyDelete', this.survey.id).then(() => {
          this.isLoading = false;
          this.$emit('surveyDeleted');
          this.onCloseModal();
        });
      } else {
        this.isLoading = false;
      }
    },
    onCloseModal() {
      this.$store.dispatch('modals/closeModal');
    }
  }
};
</script>

<style scoped>

</style>
