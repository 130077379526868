<template>
    <div :class="{'v-tab': type=== 'tab', [type]:true}">
        <v-select v-if="type === 'select'" class="locale-picker" :items="availableLocales" outlined dense
                  @change="changeLocale($event)"
                  item-text="label" item-value="key" :value="selected" :value-comparator="comperator"></v-select>
        <v-menu bottom left v-if="type === 'tab'">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        text
                        class="align-self-center"
                        v-bind="attrs"
                        v-on="on"
                >
                    {{ currentLanguage.label }}
                    <v-icon right>
                        mdi-menu-down
                    </v-icon>
                </v-btn>
            </template>

            <v-list class="grey lighten-3">
                <v-list-item
                        v-for="item in availableLocales"
                        :key="item.key"
                        @click="changeLocale(item.key)"
                >
                    {{ item.label }}
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
  name: 'LocalePicker',
  props: {
    selected: {
      type: String,
      default: 'en'
    },
    type: {
      type: String,
      default: 'select'
    }
  },
  data() {
    return {
      availableLocales: [
        {key: 'en', label: 'English'},
        {key: 'da', label: 'Danish'},
        {key: 'ru', label: 'Russian'},
        //{key: 'es', label: 'Spanish'}
      ]
    };
  },
  computed: {

    currentLanguage() {
      return this.availableLocales.find(locale => locale.key === this.selected);
    }
  },
  methods: {
    comperator(a, b) {
      return a === b;
    },
    changeLocale(locale) {
      this.$store.commit('changeLanguage', locale);
      this.$emit('change', locale);
    }
  }
};
</script>

<style scoped lang="scss">
.locale-picker {
  max-width: 14rem;
  flex-shrink: 1;
}

.tab {
  margin-left: auto;
  margin-right: 0;
  padding: 0;

  .v-btn {
    height: 100%;
    background: transparent;
  }
}
</style>
