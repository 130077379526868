import constants from "./constants";
import apolloConfig from "../../apollo.config";

export const clientIdBasedOnHostname = (host) => {
  let clientId;

  if (host.includes(constants.clientHostNames.localhost)) {
    clientId = apolloConfig.clientId;
  }
  if (host.includes(constants.clientHostNames.test)) {
    return apolloConfig.clientId;
  }
  if (host.includes(constants.clientHostNames.athena)) {
    clientId = apolloConfig.clientId;
  }
  if (host.includes(constants.clientHostNames.adminCms)) {
    clientId = apolloConfig.clientId;
  }
  if (host.includes(constants.clientHostNames.saremote)) {
    clientId = apolloConfig.clientIdSAC;
  }
  if (host.includes(constants.clientHostNames.tokiomarine)) {
    clientId = apolloConfig.clientIdTokioMarine;
  }
  if (host.includes(constants.clientHostNames.insurwave)) {
    clientId = apolloConfig.clientIdInsurwave;
  }
  if (host.includes(constants.clientHostNames.fairway)) {
    clientId = apolloConfig.clientIdFairway;
  }
  if (host.includes(constants.clientHostNames.netlify)) {
    clientId = apolloConfig.clientId;
  }
  /*if (host.includes(constants.clientHostNames.sac)) {
        clientId = apolloConfig.clientIdSAC;
    }*/
  return clientId;
};

export const chooseDBasedOnHostname = (host) => {
  let db = apolloConfig.envUrls.production;

  if (host.includes(".test")) {
    return apolloConfig.envUrls.test;
  }
  if (host.includes(constants.clientHostNames.localhost)) {
    db = apolloConfig.envUrls.local;
  }
  if (host.includes(constants.clientHostNames.athena)) {
    db = apolloConfig.envUrls.production;
  }
  if (host.includes(constants.clientHostNames.adminCms)) {
    db = apolloConfig.envUrls.production;
  }
  if (host.includes(constants.clientHostNames.saremote)) {
    db = apolloConfig.envUrls.production;
  }
  if (host.includes(constants.clientHostNames.tokiomarine)) {
    db = apolloConfig.envUrls.production;
  }
  if (host.includes(constants.clientHostNames.insurwave)) {
    db = apolloConfig.envUrls.production;
  }
  if (host.includes(constants.clientHostNames.netlify)) {
    db = apolloConfig.envUrls.staging;
  }
  /*if (host.includes(constants.clientHostNames.sac)) {
        db = apolloConfig.envUrls.production;
    }*/

  return db;
};
