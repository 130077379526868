<template>
    <v-container>
        <v-layout row wrap class="mb-7">
            <v-flex xs6>
                <div class="pa-3">
                    <v-text-field label="Filter items" placeholder="Search for survey name" @keyup="triggerSearchQuery" v-model="searchQuery" rounded clearable solo flat/>
                </div>
            </v-flex>
        </v-layout>
        <loading v-if="isLoading" />
        <div v-if="!isLoading && !isError">
            <status-indicators-row/>
            <surveys-submitted></surveys-submitted>
        </div>
        <v-alert type="error" icon="error_outline" v-if="isError">
            There was an error getting the surveys. Please try again later, or contact SA Remote
        </v-alert>
    </v-container>
</template>

<script>
import EventBus from '../../eventBus';
import {mapMutations} from 'vuex';
import SurveysSubmitted from '../../components/Surveys/SurveysSubmitted';
import StatusIndicatorsRow from '../../components/UI/Navigation/StatusIndicatorsRow';

export default {
  name: 'SurveyManagement',
  data: () => ({
    isLoading: false,
    isError: false,
    searchQuery: ''
  }),
  components: {
    StatusIndicatorsRow,
    SurveysSubmitted
  },
  methods: {
    ...mapMutations('cms', ['updatePageTitle']),
    ...mapMutations('submittedSurveys', ['setSearchQuery', 'status']),
    fetchSurveys(keepStatus = true) {
      this.$store.dispatch('submittedSurveys/getSubmittedSurveys', keepStatus).then(() => {
        this.isLoading = false;
        this.isError = false;
      }).catch(() => {
        this.isLoading = false;
        this.isError = true;
      });
    },
    triggerSearchQuery() {
      this.setSearchQuery(this.searchQuery);
    }
  },
  created() {
    this.isLoading = true;
    this.fetchSurveys(false);
    this.updatePageTitle('Survey management');
  },
  mounted() {
    EventBus.$on('reloadSubmittedSurveys', () => {
      setTimeout(() => {
        this.fetchSurveys(true);
      }, 500);
    });
  }
};
</script>
