<template>
    <v-dialog
            v-model="dialog"
            max-width="390"
    >
        <v-card>
            <v-card-title class="headline">
                {{company ? 'Edit company' : 'Create company'}}
            </v-card-title>

            <v-card-text>
                <p>Please provide the following information}}</p>
                <v-form ref="form">
                    <v-text-field
                            v-model="currentCompany.name"
                            label="Company name"
                            name="company_name"
                            type="text"
                    ></v-text-field>

                    <v-text-field
                            v-model="currentCompany.accountHolderName"
                            label="Account holder name"
                            name="name"
                            type="text"
                    ></v-text-field>

                    <v-text-field
                            v-model="currentCompany.accountHolderEmail"
                            label="Account holder email"
                            name="name"
                            type="text"
                    ></v-text-field>

                    <div class="text-center mt-4">
                        <v-btn color="primary" :loading="isLoading" min-width="150" outlined rounded @click="onSubmit">
                            {{company ? 'Update company' : 'Create company'}}
                        </v-btn>
                    </div>

                    <v-alert type="error" icon="error_outline" v-if="isError" class="mt-3">
                        <small>There was an error creating company. Try again later or contact SA Remote</small>
                    </v-alert>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {companySet} from '../../../graphql/mutations/clients';

export default {
  name: 'EditCreateCompany',
  props: {
    company: {
      required: false
    },
    clientId: {
      required: true
    }
  },
  data: () => ({
    dialog: true,
    isLoading: false,
    isError: false,
    currentCompany: {}
  }),
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.$apollo.mutate({
        mutation: companySet,
        variables: {
          name: this.currentCompany.name,
          accountHolderName: this.currentCompany.accountHolderName,
          accountHolderEmail: this.currentCompany.accountHolderEmail,
          companyId: this.currentCompany.id || null,
          clientId: this.clientId || null
        }
      }).then(data => {
        this.isLoading = false;
        this.$emit('companyUpdated');
        this.onCloseModal();
      }).catch(() => {
        this.isLoading = false;
        this.isError = true;
      });
    }
  },
  created() {
    if (this.company) {
      this.currentCompany = Object.assign({}, this.company);
    }
  }
};
</script>
