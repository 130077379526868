<script>

export default {
  name: "GetFieldValueMixin",
  methods: {
    getFieldValue(field, options) {
      let defaultOptions = { defaultLocale: "en" };
      if (typeof options === "string") {
        options = { locale: options };
      }
      options = { ...defaultOptions, ...options };
      if (field && field[options.locale]) {
        return field[options.locale];
      }
      if (options.defaultLocale && field && field[options.defaultLocale]) {
        return field[options.defaultLocale];
      }
      if (options.default) {
        return options.default;
      }

      return null;
    }
  }
};
</script>
