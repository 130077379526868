<template>
    <div>
        <div class="mb-3">
        <v-text-field
                label="Confirm text"
                v-model="text[locale]"
                :placeholder="locale !== 'en' ? text['en'] : ''"
                @blur="onUpdate"
        ></v-text-field>
        </div>
        <div class="mb-3">
            <v-text-field
                    label="Confirm url"
                    :placeholder="locale !== 'en' ? url['en'] : ''"
                    v-model="url[locale]"
                    @blur="onUpdate"
            ></v-text-field>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Confirm',
  props: {
    locale: {
      type: String,
      default: 'en'
    }
  },
  data: () => ({
    text: {},
    url: {}
  }),
  methods: {
    onUpdate() {
      let confirmObject = {
        text: this.text,
        url: this.url
      };
      this.$emit('confirmUpdated', confirmObject);
    }
  }
};
</script>
