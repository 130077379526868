import "core-js/stable";
import { apolloProvider } from "./apollo/init-apollo";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import wysiwyg from "vue-wysiwyg";

//Global components
import PageTransition from "./components/Transitions/PageTransition";
import ActiveIndicator from "./components/UI/ActiveIndicator";
import StatusIndicator from "./components/UI/StatusIndicator";
import HelperOffset from "./components/Helpers/HelperOffset";
import EmptyState from "./components/UI/EmptyState";
import Loading from "./components/UI/Loading";

//Filters
import formatStatus from "./filters/format-status";
import formatDate from "./filters/format-date";
import formatDateWithTime from "./filters/format-date-with-time";
import formatLogType from "./filters/format-log-type";

Vue.use(wysiwyg, {
  hideModules: { image: true, link: true, code: true, table: true },
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresRootClient)) {
    let isRootClient = store.getters["clients/isRootClient"];
    if (isRootClient) {
      next();
    } else {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });
    }
  } else {
    next();
  }
});
router.beforeEach((to, from, next) => {
  const AUTH_TOKEN = localStorage.getItem("AUTH_TOKEN");
  const USER_ID = localStorage.getItem("USER_ID");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (AUTH_TOKEN === null) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (USER_ID && AUTH_TOKEN) {
        next();
      }
    }
  } else {
    next();
  }
});

//Global components
Vue.component("PageTransition", PageTransition);
Vue.component("ActiveIndicator", ActiveIndicator);
Vue.component("StatusIndicator", StatusIndicator);
Vue.component("HelperOffset", HelperOffset);
Vue.component("EmptyState", EmptyState);
Vue.component("Loading", Loading);
//End Global components

Vue.filter("formatDate", formatDate);
Vue.filter("formatDateWithTime", formatDateWithTime);
Vue.filter("formatStatus", formatStatus);
Vue.filter("formatLogType", formatLogType);

Vue.config.productionTip = false;

new Vue({
  router,
  apolloProvider,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
