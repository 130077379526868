<template>
  <v-container>
    <v-layout row wrap class="mb-10">
      <v-flex xs6>
        <div class="pa-3" v-if="clientsWithRoles">
          <v-select label="Filter clients" placeholder="Filter by client" rounded clearable solo flat
                    :items="clientsWithRoles"
                    item-value="id"
                    v-model="selectedClient"
                    item-text="name" @change="setFilteredClient" />
        </div>
      </v-flex>
      <v-flex xs6 class="d-flex justify-end">
        <div class="pt-5 pr-3">
          <v-btn @click="newRole">New Role</v-btn>
        </div>
      </v-flex>
    </v-layout>
    <manage-roles></manage-roles>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import ManageRoles from "@/components/Roles/ManageRoles";

export default {
  name: "RoleManagement",
  components: { ManageRoles },
  data: () => ({
    selectedClient: null
  }),
  computed: {
    ...mapGetters("clients", ["isRootClient"]),
    ...mapState("clients", ["clientsWithRoles"])
  },
  methods: {
    ...mapMutations("cms", ["updatePageTitle"]),
    ...mapMutations("clients", ["setFilteredClient"]),
    newRole() {
      this.$router.push({name: 'create-role'});
    }

  },
  mounted() {
    this.updatePageTitle("Role management");
  }
};
</script>
