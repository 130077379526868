<template>
  <v-layout row>
    <v-flex xs12 class="pa-3">
      <loading v-if="isLoading" />

      <div v-if="!isLoading">
        <template v-if="isRootClient">
          <v-card flat class="mx-auto" style="margin-bottom:50px;" v-for="(client) in rootClients"
                  :key="client.id">
            <helper-offset :inline="true"
                           :full-width="false"
                           :offset="24">
              <v-card color="primary"
                      class="v-card--material__header d-flex align-center mb-5"
                      dark
                      elevation="10"
                      min-height="80"
              >
                <v-card-title>
                  <h4 class="title font-weight-light mb-2">{{ client.name }} roles</h4>
                </v-card-title>
              </v-card>
            </helper-offset>
            <v-divider></v-divider>
            <v-simple-table width="100%" class="mb-10 table-list">
              <thead>
              <tr>
                <th class="text-left table-list__heading-sortable" @click="sortBy('title-deep', client.id)"><span>Role name <v-icon>expand_more</v-icon></span>
                </th>
                <th class="text-left table-list__heading-sortable" @click="sortBy('type', client.id)"><span>Type <v-icon>expand_more</v-icon></span>
                </th>
                <th class="text-right"><span>Actions</span></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in client.roles" :key="index">

                <td>
                  <a @click="onEditRole(item, client.id)">
                    {{ item.title.en }} ({{item.roleName}})
                  </a>
                </td>
                <td>{{ item.type }}</td>
                <td class="align-right d-flex justify-end align-center table-list__actions">
                  <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          light
                          icon
                          small
                          v-on="on"
                      >
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item link @click="onCopy(item)">
                        <v-list-item-title>Copy role to another client</v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="onDelete(item)">
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              <tr v-if="client.roles.length === 0">
                <td colspan="5">No roles created</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </template>
        <!-- Start Clients -->
        <v-card flat class="mx-auto" v-for="client in isNotRootClients" :key="client.id">
          <helper-offset :inline="true"
                         :full-width="false"
                         :offset="24">
            <v-card color="primary"
                    class="v-card--material__header d-flex align-center mb-5"
                    dark
                    elevation="10"
                    min-height="80"
            >
              <v-card-title>
                <h4 class="title font-weight-light mb-2">{{ client.name }} roles</h4>
              </v-card-title>
            </v-card>
          </helper-offset>
          <v-divider></v-divider>
          <v-simple-table width="100%" class="mb-10 table-list">
            <thead>
            <tr>
              <th class="text-left table-list__heading-sortable" @click="sortBy('title-deep', client.id)"><span>Role name <v-icon>expand_more</v-icon></span>
              </th>
              <th class="text-right"><span>Actions</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in client.roles" :key="index">
              <td>
                <a @click="onEditRole(item, client.id)">
                  {{ item.title.en }} ({{item.roleName}})
                </a>
              </td>
              <td class="align-right d-flex justify-end align-center table-list__actions">
                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                        light
                        icon
                        small
                        v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item link @click="onCopy(item)">
                      <v-list-item-title>Copy role to another client</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="onDelete(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            <tr v-if="client.roles.length === 0">
              <td colspan="5">No roles created</td>
            </tr>
            </tbody>
          </v-simple-table>

          <v-alert type="error" icon="error_outline" v-if="isError">
            {{ errorText }}
          </v-alert>
        </v-card>
        <template v-if="isNotRootClients.length === 0">
          <empty-state
              error-text="Currently no roles are available. Please contact SA Remote or create a new one" />
        </template>

      </div>
    </v-flex>
    <confirm-delete-modal v-if="showConfirmDeleteModal" :item-title="selectedRole.title"
                          @closed="deleteConfirmed($event)" />
    <copy-modal v-if="showCopyModal" :item-title="selectedRole.title" :item-id="selectedRole.id"
                dispatch-action="clients/copyRoleToClient"
                @closed="copyConfirmed($event)" />
  </v-layout>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import ConfirmDeleteModal from "@/components/Modals/ConfirmDelete";
import CopyModal from "@/components/Modals/CopyModal";

export default {
  name: "manage-roles",
  data: () => ({
    isLoading: false,
    errorText: "",
    isError: false,
    surveyId: null,
    showConfirmDeleteModal: false,
    showCopyModal: false,
    selectedRole: null
  }),
  computed: {
    ...mapState("clients", ["clientsWithRoles", "filterClientId"]),
    ...mapGetters("clients", ["isRootClient"]),
    rootClients() {
      return this.clientsWithRoles.filter((client) => {
        return client.isRoot;
      });
    },
    isNotRootClients() {
      return this.clientsWithRoles.filter((client) => {
        if (this.filterClientId) {
          return !client.isRoot && client.id.indexOf(this.filterClientId) > -1;
        } else {
          return !client.isRoot;
        }
      });
    }
  },
  components: { CopyModal, ConfirmDeleteModal },
  methods: {
    ...mapMutations("clients", ["setSortBy"]),

    onEditRole(item, clientId) {
      this.$store.commit("roles/setCurrentRole", item);
      this.$router.push({
        name: "edit-role",
        params: { id: item.id }
      });
    },
    onDelete(item) {
      this.selectedRole = item;
      this.showConfirmDeleteModal = true;
    },

    onCopy(item) {
      this.selectedRole = item;
      this.showCopyModal = true;
    },
    deleteConfirmed(isConfirmed) {
      this.showConfirmDeleteModal = false;
      if (isConfirmed) {
        this.isLoading = true;
        this.$store.dispatch("roles/deleteRole", { id: this.selectedRole.id }).finally(() => {
          this.fetchAllClientsAndRoles();
        });
      }
      this.selectedRole = null;
    },
    copyConfirmed(isConfirmed) {
      this.showCopyModal = false;
      if (isConfirmed) {
        this.fetchAllClientsAndRoles();
      }
      this.selectedRole = null;
    },
    fetchAllClientsAndRoles() {
      this.isLoading = true;
      this.$store.dispatch("clients/getClientsAndRoles").then(() => {
        this.isLoading = false;
      }).catch((err) => {
        this.errorText = err;
        this.isError = true;
        this.isLoading = false;
      });
    },
    sortBy(sorting, clientId) {
      const payload = {
        sorting: sorting,
        clientId: clientId
      };
      this.setSortBy(payload);
    }
  },
  created() {
    this.fetchAllClientsAndRoles();
  }
};
</script>
