const surveys = {
  questionFieldTypes: [
    {
      id: 1,
      name: 'RADIO',
      prettyName: 'Radio'
    },
    {
      id: 2,
      name: 'CHECKBOX',
      prettyName: 'Checkbox'
    },
    {
      id: 4,
      name: 'DROPDOWN',
      prettyName: 'Dropdown list'
    },
    {
      id: 5,
      name: 'DATE',
      prettyName: 'Date'
    },
    {
      id: 6,
      name: 'DATETIME',
      prettyName: 'Date with time'
    },
    {
      id: 7,
      name: 'BOOLEAN',
      prettyName: 'True/False'
    },
    {
      id: 10,
      name: 'MEDIA',
      prettyName: 'Media'
    },
    {
      id: 11,
      name: 'NUMERIC',
      prettyName: 'Numeric'
    },
    {
      id: 12,
      name: 'STRING',
      prettyName: 'Text'
    },
    {
      id: 13,
      name: 'IMO',
      prettyName: 'IMO field'
    },
    {
      id: 14,
      name: 'CONFIRM',
      prettyName: 'Confirm'
    },
    {
      id: 15,
      name: 'COSTESTIMATE',
      prettyName: 'Cost estimate'
    },
    {
      id: 16,
      name: 'CALLSIGN',
      prettyName: 'Call sign'
    },
    {
      id: 17,
      name: 'DAMAGE',
      prettyName: 'Damage'
    }, {
      id: 18,
      name: 'SHIPNAME',
      prettyName: 'Ship name'
    },
  ],
  conditions: [
    {
      id: 20,
      text: 'is',
      value: 'IS'
    },
    {
      id: 21,
      text: 'is not',
      value: 'ISNOT'
    },
    {
      id: 22,
      text: 'is greater than',
      value: 'GREATERTHAN'
    },
    {
      id: 23,
      text: 'is less than',
      value: 'LESSTHAN'
    }
  ]
};

export default surveys;
