<template>
    <div>
        <div>{{ question.question.en }}</div>
        <div class="" v-if="question.helpShort.en">{{ question.helpShort.en }}</div>
        <div class="" v-if="question.helpDetail.en">{{ question.helpDetail.en }}</div>
        <div class="text-line"></div>
        <div class="">Please write the date and time</div>

    </div>
</template>

<script>
export default {
  name: "GenericQuestionDateTime",
  props: {
    question: {
      required: true
    }
  }
};
</script>

<style scoped>
.text-line {
    height: 1.5rem;
    border-bottom: 1px solid black;
}
</style>
