import Vue from "vue";
import {ApolloClient} from "apollo-client";
import {HttpLink} from "apollo-link-http";
import {setContext} from "apollo-link-context";
import {onError} from "apollo-link-error";
import {InMemoryCache} from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import authHelpers from "../auth/auth";
import {
  chooseDBasedOnHostname,
  clientIdBasedOnHostname,
} from "../helpers/config-helpers";
import {ApolloLink} from "apollo-link";
import omitDeep from "omit-deep-lodash";
import {getMainDefinition} from "apollo-utilities";

let serverUrl = chooseDBasedOnHostname(window.location.hostname).trim("/");
const httpLink = new HttpLink({
  uri: serverUrl,
});
let clientId = clientIdBasedOnHostname(window.location.hostname);
console.log({clientId});
let subdomain = window.location.hostname.split(".").slice(1, -2).join(".");

if (!clientId) {
  fetch(`${serverUrl}/clientId/${subdomain}`).then((response) => {
    let resp = response.json();
    clientId = resp.clientId;
  });
}
console.log("choosing db", serverUrl);
console.log("choosing clientId", clientId);

const authLink = setContext((_, {headers}) => {
  if (clientId) {
    return {
      headers: {
        clientId: clientId || "",
        adminToken: localStorage.getItem("AUTH_TOKEN") || "",
        adminId: localStorage.getItem("USER_ID") || "",
      },
    };
  } else {
    return {
      headers: {
        "x-subdomain": subdomain,
        adminToken: localStorage.getItem("AUTH_TOKEN") || "",
        adminId: localStorage.getItem("USER_ID") || "",
      },
    };
  }
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ["__typename"]; //more keys like timestamps could be included here
  const def = getMainDefinition(operation.query);
  if (def && def.operation === "mutation") {
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }
  return forward ? forward(operation) : null;
});

//Error Handling
const errorLink = onError(({graphQLErrors, networkError}) => {
  if (networkError && graphQLErrors) {
    if (graphQLErrors[0].message.includes("Not authenticated")) {
      authHelpers.logOutUser();
    }
  }

  if (networkError && networkError.statusCode === 401) {
    authHelpers.logOutUser();
  }

  if (graphQLErrors) {
    graphQLErrors.map(({message, locations, path}) =>
      window.console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
});

export const apolloClient = new ApolloClient({
  link: cleanTypenameLink.concat(authLink.concat(errorLink.concat(httpLink))),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

Vue.use(VueApollo);

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: "loading",
  },
});
