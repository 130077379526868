export default function(value) {
  switch (value) {
  case "OPEN":
    return "Re-opened";
  case "CLOSED":
    return "Finalized";
  default:
    return value.replace(/_/, " ").toLowerCase();
  }
}
