<template>
    <div>
        <v-tabs>
            <v-tab>Info</v-tab>
            <v-tab-item>
                <div class="pa-3 py-10" v-if="isRootClient">
                    <info :page="data" />
                  <survey-editor-condition :condition="data.displayConditions" :role-name="data.roleName"></survey-editor-condition>

                  <survey-editor-list-sections :children="data.children" />
                </div>
                <div class="pa-3 py-10" v-if="!isRootClient">
                    <p>No info available</p>
                </div>
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Info from './Page/Info';
import SurveyEditorListSections from './Components/SurveyEditorListSections';
import SurveyEditorCondition from "@/components/Surveys/Editors/SurveyEditorCondition";

export default {
  name: 'survey-editor-page',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    summary: ''
  }),
  computed: {
    ...mapGetters('clients', ['isRootClient'])
  },
  components: { SurveyEditorCondition, SurveyEditorListSections, Info },
  methods: {
    onSave() {
      alert('save');
    }
  }
};
</script>
