<template>
    <v-container>
        <v-layout row wrap class="mb-10">
            <v-flex xs6>
                <!--<div class="pa-3">
                    <v-text-field label="Filter items" placeholder="Search for item" rounded clearable solo flat/>
                </div>-->
            </v-flex>
            <v-flex xs6 class="d-flex justify-end pt-4 pr-3">
                <v-btn rounded depressed color="primary" :to="{name: 'create-page'}">Create new page</v-btn>
            </v-flex>
        </v-layout>
        <loading v-if="isLoading"/>
        <div v-if="!isLoading && !isError">
            <!--<list-pages @pageAssigned="reloadPages" @pageDeleted="reloadPages"/>-->
            <list-clients-and-pages @selectedPage="setSelectedPage" :is-root="true"
                                    :pages="rootPages" @pageDeleted="onPageDeleted"/>
            <v-divider/>
            <list-clients-and-pages :pages="clientPages" @pageDeleted="onPageDeleted"/>
        </div>

        <assign-page-modal @pageAssigned="onPageAssigned" v-if="showAssignPageModal" :page="selectedPage"/>
        <v-snackbar color="info" :bottom="y === 'bottom'" v-model="showSuccessMessage">
                {{successMessage}}
            <v-btn color="white" text @click="isError = false">
                Close
            </v-btn>
        </v-snackbar>
        <v-snackbar color="error" :bottom="y === 'bottom'" v-model="isError">
            Error: {{errorText}}. Please try again or contact support
            <v-btn color="white" text @click="isError = false">
                Close
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex';
import ListClientsAndPages from '../../components/Pages/ListClientsAndPages';
import AssignPageModal from '../../components/Pages/Modals/AssignPageModal';

export default {
  name: 'Pages',
  data: () => ({
    isLoading: false,
    isError: false,
    selectedPage: null,
    errorText: '',
    showSuccessMessage: false,
    successMessage: '',
    y: 'top'
  }),
  computed: {
    ...mapState('modals', ['showAssignPageModal']),
    ...mapGetters('static', ['rootPages', 'clientPages'])
  },
  components: {AssignPageModal, ListClientsAndPages},
  methods: {
    ...mapMutations('cms', ['updatePageTitle']),
    setSelectedPage(val) {
      this.selectedPage = val;
    },
    onPageDeleted() {
      this.showSuccessMessage = true;
      this.successMessage = 'Page deleted';
      this.reloadPages();
    },
    onPageAssigned() {
      this.showSuccessMessage = true;
      this.successMessage = 'Page was assigned successfully';
      this.reloadPages();
    },
    fetchClientsAndPages() {
      this.$store.dispatch('static/getClientsPages').then(() => {
        this.isError = false;
        this.isLoading = false;
      }).catch(() => {
        this.isError = true;
        this.isLoading = false;
      });
    },
    reloadPages() {
      this.fetchClientsAndPages();
    }
  },
  created() {
    this.isLoading = true;
    this.fetchClientsAndPages();
  },
  mounted() {
    this.updatePageTitle('Pages');
  }
};
</script>
