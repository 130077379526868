import gql from "graphql-tag";

export const createAndSaveSurvey = gql`
  mutation surveyAdd(
    $type: SurveyType
    $title: LocaleStringInput!
    $description: LocaleStringInput
  ) {
    surveyAdd(type: $type, title: $title, description: $description) {
      success
      message
    }
  }
`;

export const createSurvey = gql`
  mutation surveySet($title: LocaleStringInput!, $type: SurveyType!) {
    surveySet(title: $title, type: $type) {
      success
      message
    }
  }
`;

export const updateSurvey = gql`
  mutation surveySet(
    $surveyId: ID!
    $type: SurveyType!
    $title: LocaleStringInput!
    $description: LocaleStringInput
    $icon: String
    $reportText: String
    $reportLogo: String
    $reportAddress: String
    $clientId: ID!
    $categoryId: ID
  ) {
    surveySet(
      surveyId: $surveyId
      type: $type
      title: $title
      description: $description
      icon: $icon
      reportText: $reportText
      reportLogo: $reportLogo
      reportAddress: $reportAddress
      clientId: $clientId
      categoryId: $categoryId
    ) {
      success
      message
    }
  }
`;

export const addPageToSurvey = gql`
  mutation surveyPageSet($surveyId: ID!, $title: LocaleStringInput!) {
    surveyPageSet(surveyId: $surveyId, title: $title) {
      success
      message
    }
  }
`;

export const updatePageToSurvey = gql`
  mutation surveyPageSet(
    $surveyId: ID!
    $pageId: ID!
    $title: LocaleStringInput!
    $description: LocaleStringInput
  ) {
    surveyPageSet(
      surveyId: $surveyId
      pageId: $pageId
      title: $title
      description: $description
    ) {
      success
      message
    }
  }
`;

export const deletePageFromSurvey = gql`
  mutation surveyPageDelete($surveyId: ID!, $id: ID!) {
    surveyPageDelete(surveyId: $surveyId, id: $id) {
      success
      message
    }
  }
`;

export const addSectionToPage = gql`
  mutation surveySectionSet(
    $surveyId: ID!
    $pageId: ID!
    $title: LocaleStringInput!
  ) {
    surveySectionSet(surveyId: $surveyId, pageId: $pageId, title: $title) {
      success
      message
    }
  }
`;

export const updateSectionToPage = gql`
  mutation surveySectionSet(
    $surveyId: ID!
    $pageId: ID!
    $sectionId: ID!
    $title: LocaleStringInput!
    $description: LocaleStringInput
  ) {
    surveySectionSet(
      surveyId: $surveyId
      pageId: $pageId
      sectionId: $sectionId
      title: $title
      description: $description
    ) {
      success
      message
    }
  }
`;

export const deleteSectionFromPage = gql`
  mutation surveySectionDelete($surveyId: ID!, $id: ID!) {
    surveySectionDelete(surveyId: $surveyId, id: $id) {
      success
      message
    }
  }
`;

export const updateDisplayConditions = gql`
  mutation surveyDisplayConditionSet(
    $surveyId: ID!
    $displayConditionId: ID
    $conditionQuestionId: ID!
    $operator: SurveyConditionOperator!
    $value: String!
    $pageId: ID
    $sectionId: ID
    $questionId: ID
  ) {
    surveyDisplayConditionSet(
      surveyId: $surveyId
      displayConditionId: $displayConditionId
      conditionQuestionId: $conditionQuestionId
      operator: $operator
      value: $value
      pageId: $pageId
      sectionId: $sectionId
      questionId: $questionId
    ) {
      success
      message
    }
  }
`;

export const surveyUpdateRole = gql`
  mutation surveyUpdateRole(
    $surveyId: ID!
    $pageId: ID
    $sectionId: ID
    $questionId: ID
    $roleName: [String!]
  ) {
    surveyUpdateRole(
      surveyId: $surveyId
      pageId: $pageId
      sectionId: $sectionId
      questionId: $questionId
      roleName: $roleName
    ) {
      success
      message
    }
  }
`;

export const addQuestionToSection = gql`
  mutation surveyQuestionSet(
    $surveyId: ID!
    $pageId: ID!
    $sectionId: ID!
    $title: LocaleStringInput!
    $question: LocaleStringInput!
    $inputType: SurveyInputType!
    $isRequired: Boolean!
    $isVisible: Boolean
  ) {
    surveyQuestionSet(
      surveyId: $surveyId
      pageId: $pageId
      sectionId: $sectionId
      title: $title
      question: $question
      inputType: $inputType
      isRequired: $isRequired
      isVisible: $isVisible
    ) {
      success
      message
    }
  }
`;

export const updateQuestionToSection = gql`
  mutation surveyQuestionSet(
    $surveyId: ID!
    $pageId: ID!
    $sectionId: ID!
    $questionId: ID!
    $inputType: SurveyInputType!
    $title: LocaleStringInput!
    $question: LocaleStringInput!
    $helpShort: LocaleStringInput
    $helpDetail: LocaleStringInput
    $placeholder: LocaleStringInput
    $isRequired: Boolean!
    $isVisible: Boolean
    $min: Int
    $max: Int
    $confirmText: LocaleStringInput
    $confirmUrl: String
    $options: String
    $isHighlighted: Boolean
    $highlightPriority: Int
    $data: String
  ) {
    surveyQuestionSet(
      surveyId: $surveyId
      pageId: $pageId
      sectionId: $sectionId
      questionId: $questionId
      inputType: $inputType
      question: $question
      title: $title
      helpShort: $helpShort
      helpDetail: $helpDetail
      placeholder: $placeholder
      min: $min
      max: $max
      confirmText: $confirmText
      confirmUrl: $confirmUrl
      options: $options
      isRequired: $isRequired
      isVisible: $isVisible
      isHighlighted: $isHighlighted
      highlightPriority: $highlightPriority
      data: $data
    ) {
      success
      message
    }
  }
`;

export const deleteQuestionFromSection = gql`
  mutation surveyQuestionDelete($surveyId: ID!, $id: ID!) {
    surveyQuestionDelete(surveyId: $surveyId, id: $id) {
      success
      message
    }
  }
`;

export const setDisplayConditions = gql`
  mutation surveyDisplayConditionSet(
    $surveyId: ID!
    $displayConditionId: ID
    $conditionQuestionId: ID!
    $operator: SurveyConditionOperator!
    $value: String!
    $pageId: ID
    $sectionId: ID
    $questionId: ID
  ) {
    surveyDisplayConditionSet(
      surveyId: $surveyId
      displayConditionId: $displayConditionId
      conditionQuestionId: $conditionQuestionId
      operator: $operator
      value: $value
      pageId: $pageId
      sectionId: $sectionId
      questionId: $questionId
    ) {
      success
      message
    }
  }
`;

export const addOwnAnswerFromCMS = gql`
  mutation surveySubmitAnswerCMS(
    $instanceId: ID!
    $questionId: ID!
    $answer: String!
  ) {
    surveySubmitAnswerCMS(
      instanceId: $instanceId
      questionId: $questionId
      answer: $answer
    ) {
      success
      message
    }
  }
`;

export const deleteSurvey = gql`
  mutation surveyDelete($id: ID!) {
    surveyDelete(id: $id) {
      success
      message
    }
  }
`;

export const activateSurvey = gql`
  mutation surveyActivate($id: ID!) {
    surveyActivate(id: $id)
  }
`;

export const deactivateSurvey = gql`
  mutation surveyDeactivate($id: ID!) {
    surveyDeactivate(id: $id)
  }
`;

export const cloneSurvey = gql`
  mutation surveyClone($id: ID!, $clientId: ID!, $categoryId: ID!) {
    surveyClone(id: $id, clientId: $clientId, categoryId: $categoryId) {
      success
      message
    }
  }
`;

export const surveyPagePrioritize = gql`
  mutation surveyPagePrioritize($surveyId: ID!, $pages: String!) {
    surveyPagePrioritize(surveyId: $surveyId, pages: $pages)
  }
`;

export const surveySectionPrioritize = gql`
  mutation surveySectionPrioritize($surveyId: ID!, $sections: String!) {
    surveySectionPrioritize(surveyId: $surveyId, sections: $sections)
  }
`;

export const surveyQuestionPrioritize = gql`
  mutation surveyQuestionPrioritize($surveyId: ID!, $questions: String!) {
    surveyQuestionPrioritize(surveyId: $surveyId, questions: $questions)
  }
`;
export const surveyQuestionUpdateWeight = gql`
  mutation surveyQuestionWeights($surveyId: ID!, $weightMap: [WeightMap!]!) {
    surveyWeight(type: "question", surveyId: $surveyId, weights: $weightMap) {
      success
      message
    }
  }
`;
export const surveySectionUpdateWeight = gql`
  mutation surveySectionWeights($surveyId: ID!, $weightMap: [WeightMap!]!) {
    surveyWeight(type: "section", surveyId: $surveyId, weights: $weightMap) {
      success
      message
    }
  }
`;
export const surveyUpdateList = gql`
  mutation surveyUpdateList($surveyId: ID!, $listMap: [ListMap!]!, $type: String) {
    surveyUpdateList(type: $type, surveyId: $surveyId, list: $listMap) {
      success
      message
    }
  }
`;
export const surveyDisplayConditionDelete = gql`
  mutation surveyDisplayConditionDelete($surveyId: ID!, $id: ID!) {
    surveyDisplayConditionDelete(surveyId: $surveyId, id: $id) {
      success
      message
    }
  }
`;

export const surveyQuestionOptionDelete = gql`
  mutation surveyQuestionOptionDelete($optionId: ID!) {
    surveyQuestionOptionDelete(optionId: $optionId)
  }
`;
