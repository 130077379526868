import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home";
import IndexAdmin from "../views/Admin/Admin";
import Dashboard from "../views/Admin/Dashboard";
import SurveysAdministration from "../views/Admin/SurveysAdministration";
import EditSurvey from "../views/Admin/Surveys/EditSurvey";
import UserManagement from "../views/Admin/UserManagement";
import SurveyManagement from "../views/Admin/SurveyManagement";
import SubmittedSurvey from "../views/Admin/Surveys/SubmittedSurvey";
import ClientManagement from "../views/Admin/ClientManagement";
import Pages from "../views/Admin/Pages";
import EditUser from "../views/Admin/Users/EditUser";
import CreateUser from "../views/Admin/Users/CreateUser";
import ClientDetail from "../views/Admin/Clients/ClientDetail";
import EditClient from "../views/Admin/Clients/EditClient";
import CreateClient from "../views/Admin/Clients/CreateClient";
import CreatePage from "../views/Admin/Static/CreatePage";
import EditPage from "../views/Admin/Static/EditPage";
import SurveyReport from "../views/Admin/Surveys/SurveyReport";
import EmailValidate from "../views/EmailValidate";
import PasswordReset from "../views/PasswordReset";
import RoleManagement from "@/views/Admin/Roles/RoleManagement";
import CreateRole from "@/views/Admin/Roles/CreateRole";
import EditRole from "@/views/Admin/Roles/EditRole";
import SurveyMasterPrint from '../components/Surveys/Builder/Print/MasterPrint';
Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "index",
      component: Home,
    },
    {
      path: "/passwordReset/:tokenId/:email",
      name: "reset-password",
      component: PasswordReset,
    },
    {
      path: "/emailValidate/:tokenId/:email",
      name: "email-validate",
      component: EmailValidate,
    },
    {
      path: "/survey-management/report/:surveyId",
      name: "survey-report",
      component: SurveyReport,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/survey-administration/pdf/:surveyId",
      name: "survey-master-print",
      component: SurveyMasterPrint,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin",
      component: IndexAdmin,
      children: [
        {
          path: "/",
          name: "dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "surveys-administration",
          name: "surveys-administration",
          component: SurveysAdministration,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "survey-management",
          name: "survey-management",
          component: SurveyManagement,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "survey-management/:surveyId",
          name: "submitted-survey",
          component: SubmittedSurvey,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "surveys-administration/edit-survey/:surveyId",
          name: "edit-survey",
          component: EditSurvey,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "user-management",
          name: "users",
          component: UserManagement,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "user-management/edit/:id/:mode",
          name: "edit-user",
          props: true,
          component: EditUser,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "user-management/create/",
          name: "create-user",
          component: CreateUser,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "client-management",
          name: "clients",
          component: ClientManagement,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "client-management/view/:id",
          name: "client-detail",
          component: ClientDetail,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "client-management/edit/:id",
          name: "edit-client",
          component: EditClient,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "client-management/create",
          name: "create-client",
          component: CreateClient,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "pages",
          name: "pages",
          component: Pages,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "pages/create",
          name: "create-page",
          component: CreatePage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "pages/edit/:id",
          name: "edit-page",
          component: EditPage,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "roles",
          name: "roles",
          component: RoleManagement,
          meta: {
            requiresAuth: true,
            requiresRootClient: true,
          },
        },
        {
          path: "roles/create",
          name: "create-role",
          component: CreateRole,
          meta: {
            requiresAuth: true,
            requiresRootClient: true,
          },
        },
        {
          path: "roles/edit/:id",
          name: "edit-role",
          component: EditRole,
          meta: {
            requiresAuth: true,
            requiresRootClient: true,
          },
        },
      ],
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});
