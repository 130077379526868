<template>
    <v-dialog :value="showDialog"
              @input="$emit('dialog',$event)"
              max-width="400"
    >
        <v-card>
            <v-card-title class="headline">Import list of options</v-card-title>

            <v-card-text>
                <v-form @submit.prevent="onImport">
                    <p>We're importing the following list of options. Please add one per line.</p>
                    <v-textarea
                            v-model="optionText"
                            label="List of options. one per line."
                            autofocus
                    ></v-textarea>
                    <v-btn color="primary" min-width="150" outlined rounded type="submit">Import options</v-btn>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>

export default {
  name: 'ShowImportModal',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    optionText: '',
  }),
  methods: {
    onCloseModal() {
      this.$emit('dialog', false);
      this.$store.dispatch('modals/closeModal');
    },
    onImport() {
      this.$emit('import', this.optionText.replace('\r\n', '\n').split(`\n`).map(value => value.toString().trim()).filter((value) => {
        return value !== '';
      }));
      this.onCloseModal();
    }
  }
};
</script>
