<template>
    <v-alert :type="type" tile prominent icon="notifications_none">
        {{errorText}}
    </v-alert>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    errorText: {
      type: String
    },
    type: {
      default: 'error'
    }
  }
};
</script>
