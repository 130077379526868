<template>
    <table style="width:100%">
        <thead>
            <tr>
                <th width="30%" style="text-align:left;">
                    Key
                </th>
                <th style="text-align:right;">
                    Value
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, key, index) in vesselData[0]" :key="index">
                <td width="30%" style="padding: 7px 0; text-align:left; border-bottom:1px solid #ccc;">
                    {{key}}
                </td>
                <td style="padding: 7px 0; text-align:right; border-bottom:1px solid #ccc;">
                    {{item}}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
  name: 'ListVesselData',
  props: {
    vesselData: {
      type: Array
    }
  }
};
</script>
