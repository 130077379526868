<template>
    <div>
        <v-progress-linear
                v-if="isLoading"
                :indeterminate="isLoading"
                :loading="isLoading"
                absolute
                top
                color="primary"
        ></v-progress-linear>
        <v-tabs>
            <v-tab>Question / Answer</v-tab>
            <v-tab-item>
                <div class="pa-3 py-10">
                    <p>You are now at question: <strong>{{ data.title.en }}.</strong>
                        <template v-if="isRootClient">
                            <br/>
                            Add a comment, reopen question, or/and add a recommendation to the question.
                        </template>
                    </p>
                    <div class="pa-3 answer-box">
                        <h3>Answer</h3>
                        <div class="answer-flex">
                            <div class="answer-information">
                                <v-text-field disabled :value="data.inputType" label="Input type"></v-text-field>
                                <survey-editor-condition :condition="data.displayConditions" :role-name="data.roleName"
                                                         :required="data.isRequired"></survey-editor-condition>
                            </div>
                            <div class="answer">
                                <answer-media @errorImageUpdated="onImageUpdatedError" @imageUpdated="onImageUpdated"
                                              :images="data.answer" v-if="data.inputType === 'MEDIA'"/>
                                <answer-date :answer="data.answer" v-if="data.inputType === 'DATE'"/>
                                <answer-date-time :answer="data.answer" v-if="data.inputType === 'DATETIME'"/>
                                <answer-options :answer="data.answer"
                                                v-if="data.inputType === 'RADIO' || data.inputType === 'CHECKBOX' || data.inputType === 'DROPDOWN'"/>
                                <answer-cost-estimate :answer="data.answer" v-if="data.inputType === 'COSTESTIMATE'"/>
                                <answer-damage :answer="data.answer" v-if="data.inputType === 'DAMAGE'"/>
                                <answer-text :answer="data.answer" v-if="data.inputType === 'STRING' ||
                                        data.inputType === 'BOOLEAN' ||
                                        data.inputType === 'CALLSIGN' ||
                                        data.inputType === 'IMO' ||
                                        data.inputType === 'NUMERIC' ||
                                        data.inputType === 'CONFIRM'"/>
                            </div>
                        </div>
                        <div class="pa-3"
                             v-if="data.inputType !== 'MEDIA' && (!data.isVisible || !hasAnswer(data.answer))">
                            <p>Add your own answer to the question</p>
                            <v-textarea
                                    v-model="ownAnswer"
                                    auto-grow
                                    filled
                                    color="primary"
                                    label="Add an answer"
                                    rows="4"
                                    class="mb-2"
                            ></v-textarea>
                            <v-btn color="primary" :disabled="isLoading" :outlined="!this.changed.ownAnswer"
                                   small @click="onAddOwnAnswer">
                                Add answer
                            </v-btn>
                        </div>
                        <div class="pa-3" v-if="false && data.inputType === 'MEDIA'">
                            <p>Add your own answer to the question</p>
                            <v-btn color="primary" :disabled="isLoading" outlined
                                   small @click="onAddOwnAnswerImage">
                                Add answer
                            </v-btn>
                        </div>
                    </div>
                    <div class="mb-4" v-if="isRootClient">
                        <v-textarea
                                v-model="comment"
                                auto-grow
                                filled
                                color="primary"
                                label="Comment"
                                rows="4"
                                class="mb-2"
                        ></v-textarea>
                        <v-btn color="primary" :disabled="isLoading" :outlined="!this.changed.comment" rounded small
                               @click="onUpdateComment">
                            Save
                        </v-btn>
                    </div>

                    <div class="mb-4" v-if="conditionType === 'CONDITION' && isRootClient">
                        <v-textarea
                                v-model="recommendation"
                                auto-grow
                                filled
                                color="primary"
                                label="Recommendation"
                                rows="4"
                                class="mb-2"
                        ></v-textarea>
                        <v-menu
                                ref="menu"
                                v-model="dueDateMenu"
                                :close-on-content-click="false"
                                :return-value.sync="dueDate"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                v-if="recommendation.length > 0"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="dueDate"
                                        label="Due Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        prepend-icon="calendar_today"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="dueDate"
                                    no-title
                                    scrollable
                                    show-adjacent-months
                                    @change="$refs.menu.save($event)"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                        text
                                        color="primary"
                                        @click="dueDateMenu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(dueDate)"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                        <v-btn color="primary" :disabled="isLoading" rounded small
                               :outlined="!(this.changed.recommendation || this.changed.dueDate)"
                               @click="onUpdateRecommendation">Save
                        </v-btn>
                    </div>

                    <v-container v-if="isRootClient">
                        <v-row>
                            <v-flex md6>
                                <h3 class="subtitle-2">Reopen question?</h3>
                                <span class="d-block">This action will allow you to add a comment to the question/answer</span>
                            </v-flex>
                            <v-flex md6>
                                <div class="d-flex justify-end text-xs-right">
                                    <v-switch v-model="isReopened" label="Reopen question"></v-switch>
                                </div>
                            </v-flex>
                        </v-row>
                        <v-row v-if="isReopened">
                            <v-flex grow>
                                <v-alert type="warning" v-if="!data.answerId">This question does not have an answer, so
                                    it is not
                                    possible to reopen it.
                                </v-alert>
                                <v-textarea
                                        v-model="reopenedComment"
                                        auto-grow
                                        filled
                                        color="primary"
                                        label="Comment / Question"
                                        rows="4"
                                        class="mb-2"
                                ></v-textarea>
                                <v-btn color="primary" :disabled="isLoading" :outlined="!this.changed.reopenedComment"
                                       rounded small
                                       @click="onUpdateReopenedComment">Save
                                </v-btn>
                            </v-flex>
                        </v-row>
                    </v-container>
                </div>
            </v-tab-item>

        </v-tabs>

        <v-snackbar color="primary" :bottom="y === 'bottom'" v-model="isSaved">
            Details have been updated
            <v-btn color="white" text @click="isSaved = false">
                Close
            </v-btn>
        </v-snackbar>

        <v-snackbar color="error" :bottom="y === 'bottom'" v-model="isError">
            <span v-if="errorMsg">{{ errorMsg }}</span>
            <span v-else>Error updating. Please try again or contact support</span>

            <v-btn color="white" text @click="isError = false">
                Close
            </v-btn>
        </v-snackbar>

    </div>
</template>

<script>
import EventBus from "../../../eventBus";
import { mapGetters, mapState } from "vuex";
import constants from "../../../helpers/constants";
import AnswerMedia from "./Question/AnswerMedia";
import AnswerText from "./Question/AnswerText";
import AnswerDate from "./Question/AnswerDate";
import AnswerDateTime from "./Question/AnswerDateTime";
import AnswerCostEstimate from "./Question/AnswerCostEstimate";
import AnswerOptions from "./Question/AnswerOptions";
import AnswerDamage from "./Question/AnswerDamage";
import { addOwnAnswerFromCMS } from "../../../graphql/mutations/surveys";
import SurveyEditorCondition from "@/components/Surveys/Editors/SurveyEditorCondition";

export default {
  name: "survey-editor-question",
  components: {
    SurveyEditorCondition,
    AnswerOptions,
    AnswerDate,
    AnswerText,
    AnswerMedia,
    AnswerDateTime,
    AnswerCostEstimate,
    AnswerDamage
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    y: "top",
    imagePath: constants.cloudinaryUrl,
    recommendationId: null,
    recommendation: "",
    comment: "",
    commentId: null,
    ownAnswer: "",
    isReopened: false,
    reopenedCommentId: null,
    reopenedComment: "",
    conditionType: null,
    isLoading: false,
    isError: false,
    errorMsg: null,
    isSaved: null,
    dueDate: "",
    dueDateMenu: false,
    changed: {
      dueDate: false,
      comment: false,
      reopenedComment: false,
      recommendation: false,
      ownAnswer: false,
    },
    original: {
      comment: "",
      reopenedComment: "",
      recommendation: "",
      ownAnswer: "",
      dueDate: "",
    }
  }),
  computed: {
    ...mapState("submittedSurveys", ["currentSurvey"]),
    ...mapGetters("clients", ["isRootClient"])
  },
  watch: {
    comment(newVal) {
      this.changed.comment = this.original.comment !== newVal;
      this.emitIfDirty();
    },
    ownAnswer(newVal) {
      this.changed.ownAnswer = this.original.ownAnswer !== newVal;
      this.emitIfDirty();
    },
    reopenedComment(newVal) {
      this.changed.reopenedComment = this.original.reopenedComment !== newVal;
      this.emitIfDirty();
    },
    recommendation(newVal) {
      this.changed.recommendation = this.original.recommendation !== newVal;
      this.emitIfDirty();
    },
    dueDate(newVal) {
      this.changed.dueDate = this.original.dueDate !== newVal;
      this.emitIfDirty();
    }
  },
  methods: {
    emitIfDirty() {
      this.$emit("dirty", !!Object.values(this.changed).find(f => f));
    },
    onImageUpdatedError() {
      this.isError = true;
    },
    onImageUpdated() {
      this.setCompletedState();
    },
    setCompletedState() {
      EventBus.$emit("surveyUpdated");
      this.isLoading = false;
      this.isError = false;
      this.isSaved = true;
      this.changed = {
        dueDate: false,
        comment: false,
        reopenedComment: false,
        recommendation: false
      };
      this.$emit("dirty", false);
    },
    onAddOwnAnswer() {
      this.$apollo.mutate({
        mutation: addOwnAnswerFromCMS,
        variables: {
          instanceId: this.currentSurvey.instanceId,
          questionId: this.data.id,
          answer: this.ownAnswer,
        }
      }).then(data => {
        this.setCompletedState();
      }).catch(() => {
        this.isError = true;
      });
    },
    onAddOwnAnswerImage() {
      const myWidget = cloudinary.createUploadWidget({
        cloudName: 'survey-associasion',
        apiKey: '634382941652235',
        apiSecret: '-ShNKXyXJHSbSRdNU_W1k9AygyU',
        secure: true,
        sources: ['local', 'url'],
        showPoweredBy: false,
        uploadPreset: 'v4mk0udz',
        folder: `surveys/${this.currentSurvey.instanceId}/${this.data.id}`,
        maxImageFileSize: 50000, //500kb
        maxImageWidth: 500,
        styles: {
          palette: {
            window: '#FFF',
            windowBorder: '#90A0B3',
            tabIcon: '#0E2F5A',
            menuIcons: '#5A616A',
            textDark: '#000000',
            textLight: '#FFFFFF',
            link: '#0078FF',
            action: '#FF620C',
            inactiveTabIcon: '#0E2F5A',
            error: '#F44235',
            inProgress: '#0078FF',
            complete: '#20B832',
            sourceBg: '#E4EBF1'
          },
          fonts: {
            "'Rajdhani'": 'https://fonts.googleapis.com/css?family=Rajdhani'
          }
        }
      }, (error, result) => {
        if (result && result.event === 'success') {
          this.$apollo.mutate({
            mutation: addOwnAnswerFromCMS,
            variables: {
              instanceId: this.currentSurvey.instanceId,
              questionId: this.data.id,
              answer: result.info.public_id,
            }
          }).then(data => {
            this.setCompletedState();
            data.answer.push(result.info.public_id);
          }).catch(() => {
            this.isError = true;
          });
        } else {
          window.console.log(error, result);
        }
      });

      myWidget.open();
    },
    onUpdateComment() {
      this.$store.dispatch("submittedSurveys/commentSet", {
        content: this.comment,
        commentId: this.commentId,
        type: "COMMENT",
        questionId: this.data.id,
        instanceId: this.currentSurvey.instanceId,
        sectionId: this.data?.sectionId
      }).then(() => {
        this.setCompletedState();
      }).catch(() => {
        this.isError = true;
      });
    },
    onUpdateRecommendation() {
      this.$store.dispatch("submittedSurveys/commentSet", {
        content: this.recommendation,
        type: "RECOMMENDATION",
        dueDate: this.dueDate || "",
        commentId: this.recommendationId,
        questionId: this.data.id,
        instanceId: this.currentSurvey.instanceId,
      }).then(() => {
        this.setCompletedState();
      }).catch(() => {
        this.isError = true;
      });
    },
    onUpdateReopenedComment() {
      if (!this.data.answerId) {
        window.alert("This question has no answer - Possible because of conditions. so can not be reopened");
      } else {
        this.$store.dispatch("submittedSurveys/openAnswerToQuestion", {
          content: this.reopenedComment,
          instanceId: this.currentSurvey.instanceId,
          answerId: this.data.answerId,
          question: this.data.title
        }).then(() => {
          this.setCompletedState();
        }).catch((error) => {
          this.errorMsg = error.graphQLErrors.map((e) => e.message).join("\n");
          this.isError = true;
        });
      }
    },
    setComment() {
      if (this.data.comments) {
        const findComment = this.data.comments.filter(comment => comment.type === "COMMENT");
        if (findComment.length > 0) {
          this.commentId = findComment[0].id;
          this.comment = findComment[0].content;
          this.original.comment = findComment[0].content;
        }
      }
    },
    setRecommendation() {
      if (this.data.comments) {
        const findRecommendation = this.data.comments.find(comment => comment.type === "RECOMMENDATION");
        if (findRecommendation) {
          this.recommendationId = findRecommendation.id;
          this.recommendation = findRecommendation.content;
          this.original.recommendation = findRecommendation.content;
          this.original.dueDate = findRecommendation.dueDate || "";
          this.dueDate = this.original.dueDate;
        }
      }
    },
    setReopenedComment() {
      if (this.data.comments) {
        const findReopenedComment = this.data.comments.filter(comment => comment.type === "REOPEN" && comment.status === "OPEN");
        if (findReopenedComment.length > 0) {
          this.reopenedCommentId = findReopenedComment[0].id;
          this.isReopened = true;
          this.reopenedComment = findReopenedComment[0].content;
          this.original.reopenedComment = findReopenedComment[0].content;
        }
      }
    },
    setConditionType() {
      this.conditionType = this.currentSurvey.survey.type;
    },
    getFieldValue(field, options) {
      console.log(field);
      if (typeof field === "string") {
        return field;
      }

      let defaultOptions = { defaultLocale: "en", locale: "en" };
      if (typeof options === "string") {
        options = { locale: options };
      }
      options = { ...defaultOptions, ...options };
      if (field && field[options.locale]) {
        return field[options.locale];
      }
      if (options.defaultLocale && field && field[options.defaultLocale]) {
        return field[options.defaultLocale];
      }

      if (options.default) {
        return options.default;
      }

      return null;
    },
    hasAnswer(field) {
      if (!field) {
        return false;
      }
      if (field[0]) {
        return this.getFieldValue(field[0].answer);
      }
      return this.getFieldValue(field) || field;
    }
  },
  created() {
    this.setComment();
    this.setRecommendation();
    this.setReopenedComment();
    this.setConditionType();
  }
};
</script>

<style scoped lang="scss">
.answer-box {
  background: #f5f5f5;
  margin-bottom: 1.5rem;
}

.answer-flex {
  display: flex;

  .answer-information {
    max-width: 50%;
    margin-right: 20px;
  }

  .answer {
    max-height: 100%;
    flex-grow: 1;
    font-size: 2rem;
  }
}
</style>
