<template>
    <v-container fill-height fluid>
        <v-content>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <Logo></Logo>
                    <LoginForm></LoginForm>
                </v-col>
            </v-row>
        </v-content>
    </v-container>
</template>

<script>
import LoginForm from '../components/Forms/Login/LoginForm';
import Logo from '../components/UI/Logo';

export default {
  components: {
    Logo,
    LoginForm
  }
};
</script>
