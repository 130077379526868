export const sortingBy = {
  date: (items) =>
    items.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    }),

  title: (items) =>
    items.sort((a, b) => {
      return a.title.en.localeCompare(b.title.en);
    }),

  "title-deep": (items) =>
    items.sort((a, b) => {
      return a.title.en.localeCompare(b.title.en);
    }),
  ship: (items) => {
    return items.sort((a, b) => {
      let aShip = a.ship || "N/A Ship";
      let aImo = a.imo || 0;
      let bShip = b.ship || "N/A Ship";
      let bImo = b.imo || 0;
      let shipCompare = aShip.localeCompare(bShip);
      if (shipCompare !== 0) {
        return shipCompare;
      }
      return aImo - bImo;
    });
  },
  status: (items) =>
    items.sort((a, b) => {
      return a.status.localeCompare(b.status);
    }),

  name: (items) =>
    items.sort((a, b) => {
      return a.name.localeCompare(b.name);
    }),

  client: (items) =>
    items.sort((a, b) => {
      return a.client.name.localeCompare(b.client.name);
    }),

  updated: (items) =>
    items.sort((a, b) => {
      return new Date(a.updatedAt) - new Date(b.updatedAt);
    }),

  id: (items) =>
    items.sort((a, b) => {
      return a.id.localeCompare(b.id);
    }),

  adminCount: (items) =>
    items.sort((a, b) => {
      if (a.adminCount) {
        return a.adminCount - b.adminCount;
      }
    }),

  userCount: (items) =>
    items.sort((a, b) => {
      if (a.userCount) {
        return a.userCount - b.userCount;
      }
    }),
};
