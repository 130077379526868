<template>
    <div>
        <div>{{ question.question.en }}</div>
        <div class="" v-if="question.helpShort.en">{{ question.helpShort.en }}</div>
        <div class="" v-if="question.helpDetail.en">{{ question.helpDetail.en }}</div>
        <div v-if="question.min">Please include at least: {{ question.min }} media for this question</div>
        <div v-if="question.max">Please include at most: {{ question.max }} media for this question</div>
    </div>
</template>

<script>

import constants from "../../../../..//helpers/constants";

export default {
  name: "GenericQuestionMedia",
  props: {
    question: {
      required: true
    },
    showCount: {
      default: false
    },
    limit: {
      required: false
    },
    center: {
      default: false
    }
  },
  data: () => ({
    imagePath: constants.cloudinaryUrlOriginal,
    videoUrl: constants.cloudinaryVideo
  })
};
</script>
