<template>
    <v-container>
    <v-layout row wrap>
        <v-flex grow class="pa-3">
            <v-card flat class="mx-auto">
                <v-card-title>Create new user</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <create-user-form></create-user-form>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
    </v-container>
</template>

<script>
import CreateUserForm from '../../../components/Forms/Users/CreateUserForm';
export default {
  name: 'CreateUser',
  components: {CreateUserForm}
};
</script>
