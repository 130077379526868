<template>
    <v-dialog
            v-model="dialog"
            max-width="290"
    >
        <v-card>
            <v-card-title class="headline">Delete client?</v-card-title>

            <v-card-text>
                <p>To delete this client, please confirm by typing DELETE in the field below</p>
                <v-form ref="form" @submit.prevent="onDelete">
                    <v-text-field
                            v-model="deleteText"
                            label="Confirm delete"
                            name="name"
                            type="text"
                            :rules="validationRule"
                    ></v-text-field>

                    <div class="text-sm-center">
                        <v-btn color="primary" :loading="isLoading" min-width="150" outlined rounded type="submit">
                            Delete
                        </v-btn>
                    </div>

                    <v-alert type="error" icon="error_outline" v-if="isError" class="mt-3">
                        <small>There was an error deleting the client. Try again later or contact SA Remote</small>
                    </v-alert>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
import constants from '../../../helpers/constants';
export default {
  name: 'ConfirmDeleteClient',
  props: {
    clientId: {
      required: true
    }
  },
  data: () => ({
    validationRule: [v => (v) === constants.deleteMode.confirmText || 'Error in text'],
    dialog: true,
    deleteText: '',
    isLoading: false,
    isError: false
  }),
  watch: {
    dialog(val) {
      this.onCloseModal();
    }
  },
  methods: {
    onValidateConfirm() {
      if (this.deleteText !== constants.deleteMode.confirmText) {
        this.$refs.form.validate();
        return false;
      } else {
        return true;
      }
    },
    onDelete() {
      this.isLoading = true;
      if (this.onValidateConfirm()) {
        this.$store.dispatch('clients/deleteClientFromCMS', this.clientId).then(() => {
          this.isLoading = false;
          this.$emit('clientDeleted');
          this.onCloseModal();
        }).catch(() => {
          this.isError = true;
        });
      } else {
        this.isLoading = false;
      }
    },
    onCloseModal() {
      this.$store.dispatch('modals/closeModal');
    }
  }
};
</script>
