<template>
    <div v-if="question">
        <v-tabs v-model="selectedTab">
            <v-tab>Question</v-tab>
            <v-tab>Conditions</v-tab>
            <v-tab>Settings</v-tab>
            <locale-picker @change="locale = $event" :selected="locale" type="tab"></locale-picker>
            <v-tab-item transition="fade" reverse-transition="fade">
                <div class="pa-3 py-10">
                    <v-text-field
                            label="Question title"
                            v-model="question.title[locale]"
                            :placeholder="locale !== 'en' ? question.title['en'] : ''"
                    ></v-text-field>
                    <v-text-field
                            label="Name/Question"
                            :placeholder="locale !== 'en' ? question.question['en'] : ''"
                            v-model="question.question[locale]"
                    ></v-text-field>
                    <v-select
                            filled
                            v-model="selectedField"
                            :items="fields"
                            item-text="prettyName"
                            item-value="name"
                            label="Input">
                        <template slot='selection' slot-scope='{ item }'>
                            {{ item.prettyName }}
                        </template>
                        <template slot='item' slot-scope='{ item }'>
                            {{ item.prettyName }}
                        </template>
                    </v-select>
                    <v-text-field
                            label="Input placeholder"
                            :placeholder="locale !== 'en' ? question.placeholder['en'] : ''"
                            v-model="question.placeholder[locale]"
                    ></v-text-field>
                    <checkbox @updateOptions="onUpdateOptions"
                              :data="question.options"
                              :locale="locale"
                              v-if="selectedField === questionFieldTypesConstants.checkbox"/>
                    <multiple-choice @updateOptions="onUpdateOptions"
                                     :data="question.options"
                                     :locale="locale"
                                     v-if="selectedField === questionFieldTypesConstants.multiple"/>
                    <dropdown-list @updateOptions="onUpdateOptions"
                                   :data="question.options"
                                   :locale="locale"
                                   v-if="selectedField === questionFieldTypesConstants.dropdown"/>
                    <media @updateMaxData="onUpdateMax" :min="question.min" :max="question.max"
                           @updateMinData="onUpdateMin"
                           v-if="selectedField === questionFieldTypesConstants.media"/>
                    <numeric @updateMaxData="onUpdateMax" @updateMinData="onUpdateMin"
                             :min="question.min" :max="question.max"
                             v-if="selectedField === questionFieldTypesConstants.numeric"/>
                    <cost-estimate @updateOptions="onUpdateOptions"
                                   :data="question.options"
                                   :locale="locale"
                                   v-if="selectedField === questionFieldTypesConstants.cost"/>
                    <damage @updateOptions="onUpdateOptions"
                            :data="question.options"
                            :locale="locale"
                            v-if="selectedField === questionFieldTypesConstants.damage"/>
                    <callsign v-if="selectedField === questionFieldTypesConstants.callsign"/>
                    <ship-name v-if="selectedField === questionFieldTypesConstants.shipname"/>
                    <radio @updateOptions="onUpdateOptions"
                           :data="question.options"
                           :locale="locale"
                           v-if="selectedField === questionFieldTypesConstants.radio"/>
                    <confirm @confirmUpdated="onConfirmUpdated"
                             :locale="locale"
                             v-if="selectedField === questionFieldTypesConstants.confirm"/>

                </div>
            </v-tab-item>
            <v-tab-item transition="fade" reverse-transition="fade">
                <div class="pa-3 py-10">
                    <p>Please select the condition you want to add to this question</p>
                    <v-divider class="my-4"/>
                    <h3 class="subtitle-2">Visible if</h3>
                    <v-container>
                        <v-layout row>
                            <v-flex xs5>
                                <v-autocomplete
                                        type="search"
                                        v-model="selectedQuestion"
                                        :items="surveyQuestions"
                                        filled
                                        clearable
                                        @click:clear="selectedQuestion={}"
                                        item-value="id"
                                        :filter="questionFilter"
                                        return-object
                                        label="Choose question"
                                        style="max-width:100%">
                                    <template slot="item" slot-scope="data">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ data.item.index }} - {{ data.item.title.en }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.index }} - {{ data.item.title.en }}
                                    </template>
                                </v-autocomplete>
                            </v-flex>

                            <v-flex xs3>
                                <v-select
                                        :items="conditions"
                                        v-model="questionConditions.operator"
                                        item-text="text"
                                        item-value="value"
                                        filled
                                        label="Choose condition"
                                ></v-select>
                            </v-flex>

                            <v-flex xs4>
                                <template v-if="selectedQuestion.inputType === questionFieldTypesConstants.dropdown ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.multiple ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.damage ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.cost ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.checkbox ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.radio">
                                    <v-autocomplete
                                            type="search"
                                            clearable
                                            :items="selectedQuestion.options"
                                            item-text="option.en"
                                            item-value="id"
                                            filled
                                            label="Value"
                                            v-model="questionConditions.value"
                                    ></v-autocomplete>
                                </template>
                                <template
                                        v-else-if="selectedQuestion.inputType === questionFieldTypesConstants.boolean">
                                    <v-select
                                            :items="optionsTrueFalse"
                                            filled
                                            label="Value"
                                            v-model="questionConditions.value"
                                    ></v-select>
                                </template>
                                <template v-else>
                                    <v-text-field
                                            filled
                                            label="Value"
                                            required
                                            v-model="questionConditions.value"
                                    ></v-text-field>
                                </template>
                            </v-flex>
                        </v-layout>
                        <v-layout row v-if="roles.length > 0">
                            <v-flex xs12>
                                <v-autocomplete
                                        v-model="selectedRole"
                                        :items="roles"
                                        filled
                                        clearable
                                        multiple
                                        @click:clear="selectedRole=[]"
                                        item-value="roleName"
                                        label="Choose Roles"
                                        style="max-width:100%">
                                    <template slot="item" slot-scope="data">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ data.item.title.en }} ({{ data.item.roleName }})
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.title.en }} ({{ data.item.roleName }})
                                    </template>
                                </v-autocomplete>
                            </v-flex>
                        </v-layout>
                        <div class="d-block pt-3">
                            <div class="d-flex">
                                <v-btn small rounded outlined color="primary" class="mr-3"
                                       @click="onSaveConditions">
                                    {{ conditionButtonText }}
                                </v-btn>
                                <v-btn small rounded outlined color="primary" @click="onDeleteConditions"
                                       v-if="showDeleteConditionButton">
                                    Delete condition
                                </v-btn>
                            </div>
                        </div>
                    </v-container>
                </div>
            </v-tab-item>
            <v-tab-item transition="fade" reverse-transition="fade">
                <div class="pa-3">
                    <v-switch
                            v-model="question.isRequired"
                            label="Fields is required?"
                    ></v-switch>
                    <v-switch
                            v-model="question.isVisible"
                            label="Is field filled out by end user?"
                    ></v-switch>
                    <v-switch
                            v-model="question.isHighlighted"
                            label="Is field highlighted?"
                            @change="priorityChanged"
                    ></v-switch>
                    <v-text-field style="max-width: 380px;" type="number" v-if="question.isHighlighted"
                                  label="Add priority - Number 1 being the highest"
                                  v-model="question.highlightPriority"></v-text-field>
                    <v-text-field class="mt-5" label="Add help text"
                                  :placeholder="locale !== 'en' ? question.helpShort['en'] : ''"
                                  v-model="question.helpShort[locale]"></v-text-field>
                    <v-textarea name="input-7-1"
                                label="Hotspot text"
                                value=""
                                auto-grow
                                filled
                                :placeholder="locale !== 'en' ? question.helpDetail['en'] : ''"
                                v-model="question.helpDetail[locale]"
                    ></v-textarea>

                    <v-switch
                            v-model="question.data.allowPast"
                            v-if="selectedField === questionFieldTypesConstants.date || selectedField === questionFieldTypesConstants.datetime"
                            label="Allow selecting past date / datetime"
                    ></v-switch>
                    <v-switch
                            v-model="question.data.allowFuture"
                            v-if="selectedField === questionFieldTypesConstants.date || selectedField === questionFieldTypesConstants.datetime"
                            label="Allow selecting future date / datetime"
                    ></v-switch>
                </div>
            </v-tab-item>
        </v-tabs>

        <div class="px-4 pb-4">
            <v-btn color="primary" outlined rounded @click="onSave" v-if="selectedTab !== 1">Update</v-btn>

            <v-alert type="error" icon="error_outline" v-if="isError" class="mt-3">
                There was an error saving. Please try again later, or contact SA Remote
            </v-alert>

            <v-alert type="success" icon="error_outline" v-if="isSaved" class="mt-3">
                Details have been saved
            </v-alert>
        </div>
    </div>
</template>

<script>
import EventBus from "../../../../eventBus";
import {mapGetters, mapMutations, mapState} from "vuex";
import Checkbox from "../Fieldtypes/Checkbox";
import MultipleChoice from "../Fieldtypes/MultipleChoice";
import DropdownList from "../Fieldtypes/DropdownList";
import Media from "../Fieldtypes/File";
import Numeric from "../Fieldtypes/Numeric";
import CostEstimate from "../Fieldtypes/CostEstimate";
import Callsign from "../Fieldtypes/Callsign";
import Radio from "../Fieldtypes/Radio";
import Confirm from "../Fieldtypes/Confirm";
import Damage from "../Fieldtypes/Damage";
import constants from "../../../../helpers/constants";
import surveys from "../../../../helpers/surveys";
import {surveyDisplayConditionDelete} from "../../../../graphql/mutations/surveys";
import LocalePicker from "../../../../components/I18N/LocalePicker";
import ShipName from "../Fieldtypes/ShipName.vue";

export default {
  name: "survey-question",
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    showDeleteConditionButton: false,
    conditionButtonText: "Save condition",
    selectedTab: 0,
    isLoading: false,
    isError: false,
    isSaved: false,
    questionFieldTypesConstants: constants.questionFieldTypesConstants,
    selectedQuestion: {},
    selectedField: null,
    fields: surveys.questionFieldTypes,
    conditions: surveys.conditions,
    selectedRole: [],
    question: null,
    optionsTrueFalse: ["true", "false"],
    questionConditions: {
      conditionQuestionId: null,
      operator: null,
      value: null
    },
    conditionChanged: false,
    resetStatusAfterSeconds: 2000,
  }),
  computed: {
    ...mapState("surveys", ["currentSurvey"]),
    ...mapGetters("surveys", ["surveyQuestions"]),
    ...mapState("roles", {
      "roles": (state) => {
        return state.rolesForClient || [];
      }
    }),
    ...mapState(["locale"])
  },
  watch: {
    selectedQuestion: function(val, oldVal) {
      if (val === undefined) {
        this.selectedQuestion = {};
        this.questionConditions.conditionQuestionId = null;
      } else {
        if (val !== oldVal) {
          this.questionConditions.conditionQuestionId = val.id;
        }
      }
    },
    questionConditions: {
      deep: true,
      handler() {
        this.conditionChanged = true;
      }
    }
  },
  components: {
    Radio,
    Callsign,
    Numeric,
    MultipleChoice,
    Checkbox,
    DropdownList,
    Media,
    CostEstimate,
    Confirm,
    Damage,
    LocalePicker,
    ShipName,
  },
  methods: {
    ...mapMutations("surveys", ["setCurrentPageId", "setCurrentSectionId"]),
    priorityChanged(e) {
      if (e === false) {
        this.question.highlightPriority = "";
      }
    },
    questionFilter(item, search, itemText) {
      let [firstPart, remainder] = search.split(" ", 2);
      let searchRegexp = new RegExp(`^${firstPart}`);
      if (searchRegexp.test(item.index)) {
        if (remainder) {
          return item.title.en.toLocaleLowerCase().indexOf(remainder.toLocaleLowerCase()) > -1;
        } else {
          return true;
        }
      }
      return item.title.en.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
    },
    resetSavedStatus() {
      //Reset status
      setTimeout(() => {
        this.isSaved = false;
        this.conditionChanged = false;
      }, this.resetStatusAfterSeconds);
    },
    setQuestion() {
      let data = {};
      if (this.data.data) {
        data = JSON.parse(this.data.data);
      }
      this.question = {
        displayConditions: [],
        ...this.data,
        title: this.data.title ? this.data.title : {en: ""},
        question: this.data.question ? this.data.question : {en: ""},
        placeholder: this.data.placeholder ? this.data.placeholder : {en: ""},
        helpShort: this.data.helpShort ? this.data.helpShort : {en: ""},
        helpDetail: this.data.helpDetail ? this.data.helpDetail : {en: ""},
        confirmText: this.data.confirmText ? this.data.confirmText : {en: ""},
        isVisible: this.data.isVisible ? this.data.isVisible : false,
        isRequired: this.data.isRequired ? this.data.isRequired : false,
        isHighlighted: this.data.isHighlighted ? this.data.isHighlighted : false,
        highlightPriority: this.data.highlightPriority ? this.data.highlightPriority : "",
      };
      if (this.question.roleName) {
        this.selectedRole = this.question.roleName;
      }
      if (this.question.displayConditions && this.question.displayConditions.length > 0) {
        this.selectedQuestion = this.surveyQuestions.find((question) => {
          return this.question.displayConditions[0] && question.id === this.question.displayConditions[0].conditionQuestionId;
        });
        if (this.selectedQuestion) {
          this.conditionButtonText = "Update condition";
          this.showDeleteConditionButton = true;

          this.questionConditions = {
            id: this.data.displayConditions[0].id,
            conditionQuestionId: this.data.displayConditions[0].conditionQuestionId,
            operator: this.data.displayConditions[0].operator,
            value: this.data.displayConditions[0].value
          };
        } else {
          this.selectedQuestion = {};
        }
      }
      this.question.inputType = this.data.inputType ? this.data.inputType : "STRING";
      this.selectedField = this.data.inputType;
      if (this.selectedField === this.questionFieldTypesConstants.datetime || this.selectedField === this.questionFieldTypesConstants.date) {
        data = {allowPast: true, allowFuture: true, ...data};
      }
      this.question.data = data;
    },
    onConfirmUpdated(data) {
      this.question.confirmText = data.text;

      if (data.url) {
        this.question.confirmUrl = data.url;
      }
    },
    onUpdateMax(max) {
      this.question.max = max || null;
    },
    onUpdateMin(min) {
      this.question.min = min || null;
    },
    onUpdateOptions(data) {
      let options = data.map((item) => {
        if (item.option && item.option.en && item.option.en.length > 0) {
          let itemOptions = {};
          for (const [key, value] of Object.entries(item.option)) {
            if (key === "__typename") {
              continue;
            }
            if (value) {
              itemOptions[key] = value.toString();
            } else {
              itemOptions[key] = "";
            }
          }
          return {
            id: item.id,
            priority: item.priority,
            option: itemOptions,
            ratingValue: item.ratingValue,
          };
        }
      }).filter(Boolean); //Removes undefined values from array
      this.question.options = [...options];
    },
    onSaveConditions() {
      let promises = [];
      if (this.conditionChanged) {
        promises.push(this.$store.dispatch("surveys/onDisplayConditionsUpdate", {
          id: this.questionConditions.id || null,
          sectionId: null,
          conditionQuestionId: this.questionConditions.conditionQuestionId,
          operator: this.questionConditions.operator,
          value: this.questionConditions.value,
          questionId: this.question.id
        }).then((conditionId) => {
          this.questionConditions.id = conditionId;
          this.conditionButtonText = "Update condition";
          this.showDeleteConditionButton = true;
        }));
      }
      if (this.data.roleName !== this.selectedRole) {
        promises.push(this.$store.dispatch("surveys/updateRole", {
          roleName: this.selectedRole,
          questionId: this.question.id
        }).then((role) => {
          this.selectedRole = role;
          this.conditionButtonText = "Update condition";
        }));
      }
      if (promises.length === 0) {
        return false;
      }
      Promise.all(promises).then(() => {
        this.isLoading = false;
        this.isSaved = true;
        this.isError = false;
        EventBus.$emit("surveyUpdated");

        this.resetSavedStatus();
      }).catch((error) => {
        console.error(error);
        this.isError = true;
      });
    },
    onDeleteConditions() {
      this.$apollo.mutate({
        mutation: surveyDisplayConditionDelete,
        variables: {
          surveyId: this.currentSurvey.id,
          id: this.questionConditions.id
        }
      }).then(() => {
        this.showDeleteConditionButton = false;
        this.conditionButtonText = "Save condition";
        this.isSaved = true;
        this.selectedQuestion = {};
        this.questionConditions = {
          conditionQuestionId: null,
          operator: null,
          value: null
        };
        EventBus.$emit("surveyUpdated");

        this.resetSavedStatus();
      }).catch(() => {
        this.isError = true;
      });
    },
    clearValuesOnSpecificInputTypes() {
      if (this.selectedField === constants.questionFieldTypesConstants.multiple ||
        this.selectedField === constants.questionFieldTypesConstants.checkbox ||
        this.selectedField === constants.questionFieldTypesConstants.dropdown ||
        this.selectedField === constants.questionFieldTypesConstants.damage ||
        this.selectedField === constants.questionFieldTypesConstants.radio ||
        this.selectedField === constants.questionFieldTypesConstants.cost) {
        //Keep options
      } else {
        this.question.options = null;
      }

      if (this.selectedField === constants.questionFieldTypesConstants.numeric ||
        this.selectedField === constants.questionFieldTypesConstants.media) {
        //Keep values
      } else {
        this.question.min = null;
        this.question.max = null;
      }
    },
    onSave() {
      this.clearValuesOnSpecificInputTypes();

      this.isLoading = true;
      this.conditionButtonText = "Update condition";
      this.showDeleteConditionButton = true;

      this.$store.dispatch("surveys/onUpdateQuestionToSection", {
        id: this.question.id,
        pageId: this.question.pageId,
        sectionId: this.question.sectionId,
        inputType: this.selectedField,
        title: this.question.title,
        question: this.question.question,
        helpShort: this.question.helpShort,
        helpDetail: this.question.helpDetail,
        placeholder: this.question.placeholder,
        isRequired: this.question.isRequired,
        isHighlighted: this.question.isHighlighted,
        highlightPriority: this.question.highlightPriority ? parseInt(this.question.highlightPriority) : null,
        isVisible: this.question.isVisible,
        min: this.question.min ? parseInt(this.question.min) : null,
        max: this.question.max ? parseInt(this.question.max) : null,
        confirmText: this.question.confirmText,
        confirmUrl: this.question.confirmUrl,
        options: this.question.options ? JSON.stringify(this.question.options) : null,
        data: this.question.data,
      }).then(() => {
        this.isLoading = false;
        this.isSaved = true;
        EventBus.$emit("surveyUpdated");
        this.resetSavedStatus();
      }).catch(() => {
        this.isLoading = false;
        this.isError = true;
      });
    }
  },
  created() {
    this.setCurrentPageId(this.data.pageId);
    this.setCurrentSectionId(this.data.sectionId);
    this.setQuestion();
    this.$store.dispatch("roles/getRolesForClientSurvey");
  }
};
</script>
