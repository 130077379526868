<template>
  <div>
    <div
        :class="{'condition-filled': hasCondition && isConditionFilled,'condition-unfilled': hasCondition && !isConditionFilled}">
      <h3>Condition</h3>
      <template v-if=!hasCondition>No condition on this question</template>
      <template v-else>
        <span v-if="isConditionFilled">This has a condition</span>
        <span v-else>This has an unfilled condition</span>
      </template>
    </div>
    <div><h3>Role name</h3>
      <span>{{ roleNames.join(", ") }}</span></div>
    <div v-if="required !== null"><h3>Required</h3>
      <span>{{ required ? "yes" : "no" }}</span></div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SurveyEditorCondition",
  props: {
    condition: {},
    roleName: { default: () => ["crew"] },
    required: { type: Boolean, default: null }

  },
  computed: {
    ...mapGetters("submittedSurveys", ["getAnsweredQuestions"]),
    hasCondition() {
      if (this.condition?.[0]?.conditionQuestionId) {
        return true;
      }
      return false;
    },
    isConditionFilled() {
      let conditionId = this.condition?.[0]?.conditionQuestionId;
      if (!conditionId) {
        return false;
      }

      let answer = this.getAnsweredQuestions.find(answer => {
        return answer.questionId === conditionId && answer.answerId === this.condition[0].answerId;
      });
      return !!answer;
    },
    roleNames() {
      let roleName = this.roleName;
      if (!roleName || roleName.length === 0) {
        roleName = ["crew"];
      }
      return [...roleName];
    }
  }
};
</script>

<style scoped>
.condition-filled {
  color: #68d391;
}

.condition-unfilled {
  color: #fc8181;
}
</style>
