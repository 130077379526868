<template>
    <div class="flex-column">
        <div class="flex mb-2"><div class="h-4 w-4 border border-black mr-2"></div><div><strong>Yes</strong></div></div>
        <div class="flex"><div class="h-4 w-4 border border-black mr-2"></div><div><strong>Yes</strong></div></div>
    </div>
</template>

<script>
export default {
  name: 'GenericQuestionBoolean',
  props: {
    question: {
      required: true
    }
  }
};
</script>
