<template>
    <v-container class="client-detail">
        <v-layout row wrap>

            <loading v-if="isLoading" />

            <v-flex grow class="pa-3" v-if="!isLoading">
                <v-card flat>
                    <v-card-title>
                        <v-layout>
                            <v-flex>
                                <div class="d-flex">
                                    <div>
                                        <v-avatar color="primary" :tile="false" :size="40"
                                                  class="client-detail__avatar">
                                            <span class="white--text client-detail__avatar-text" v-if="currentClient.name">{{currentClient.name.substring(0,1)}}</span>
                                        </v-avatar>
                                    </div>
                                    <div>
                                        <h2 class="client-detail--heading" v-if="currentClient.name">{{currentClient.name}}</h2>
                                        <h3 class="subtitle-2 client-detail--sub-heading">{{currentClient.createdAt | formatDate}}</h3>
                                    </div>
                                </div>
                            </v-flex>
                            <v-flex class="justify-end text-right">
                                <v-btn rounded depressed color="primary" class="mt-2" @click="onInvite">Invite user to team</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-title>
                </v-card>
                <client-detail-content/>
            </v-flex>
        </v-layout>
        <invite-user-to-client v-if="showInviteUserToClientCMSModal" />
    </v-container>
</template>

<script>
import {mapState} from 'vuex';
import ClientDetailContent from '../../../components/Clients/ClientDetailContent';
import InviteUserToClient from '../../../components/Clients/Modals/InviteUserToClient';

export default {
  name: 'ClientDetail',
  data: () => ({
    isLoading: false
  }),
  components: {InviteUserToClient, ClientDetailContent},
  computed: {
    ...mapState('clients', ['currentClient']),
    ...mapState('modals', ['showInviteUserToClientCMSModal'])
  },
  methods: {
    onInvite() {
      this.$store.dispatch('modals/toggleModal', 'showInviteUserToClientCMSModal');
    },
    fetchAppUsers() {
      this.$store.dispatch('users/getAppUsersByClient', this.$route.params.id);
    },
    fetchClient() {
      this.isLoading = true;
      this.$store.dispatch('clients/getClientById', this.$route.params.id).then(() => {
        this.isLoading = false;
      });
    }
  },
  created() {
    this.fetchClient();
    this.fetchAppUsers();
  }
};
</script>
