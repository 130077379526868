import gql from "graphql-tag";

export const getAllCMSUsers = gql`
  query admins {
    admins {
      id
      email
      name
      createdAt
      isActivated
    }
  }
`;

export const getAllAppUsers = gql`
  query users($clientId: ID) {
    users(clientId: $clientId) {
      id
      firstName
      lastName
      email
      createdAt
      status
      lastLogin
      company {
        id
        name
      }
      companies

      jobTitle
      ship
      imo
      locale
      roleName
    }
  }
`;

export const getAllAppUsersByClientID = gql`
  query users($clientId: ID!) {
    users(clientId: $clientId) {
      id
      firstName
      lastName
      email
      createdAt
      status
      lastLogin
      company {
        id
        name
      }
      companies
      jobTitle
      ship
      locale
      roleName
    }
  }
`;
/*
const getUserById = gql`
    query users($id: Int) {
        user {
            id,
            email,
            firstName,
            lastName,
            image,
            ship,
            imo,
            jobTitle,
            company,
            role
        }
    }
`;
*/
