<template>
    <div class="creator-canvas">
        <div class="creator-canvas__browser-top" tabindex="0">
            <span>{{currentClient.subdomain}}.saremote.com</span>
        </div>
        <div class="creator-canvas__canvas light-theme">
            <div class="creator-canvas__canvas-preview">
                <div class="creator-canvas__canvas-gradient"
                     :style="{'background-image': 'linear-gradient(to bottom, '+ primaryColor +' 0%, '+ primaryColor +' 100%)'}"></div>

                <div class="creator-canvas__scale-down">
                    <div class="login">
                        <div class="login__form form">
                            <div class="text-center" v-if="logo">
                                <img :src="cloudinaryUrl + logo" style="max-width:30px; margin: 0 auto;"/>
                            </div>
                            <div class="login__fallback-text" v-else>{{currentClient.name}}</div>
                            <v-text-field/>
                            <v-text-field/>

                            <div class="text-center">
                                <v-btn rounded small depressed :color="primaryColor" dark><span>Sign in</span></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <span class="creator-canvas__canvas-preview-label">Preview mode</span>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import constants from '../../../helpers/constants';

export default {
  name: 'ClientBrandingBrowser',
  props: {
    primaryColor: {
      required: true
    },
    logo: {}
  },
  data: () => ({
    cloudinaryUrl: constants.cloudinaryUrl
  }),
  computed: {
    ...mapState('clients', ['currentClient'])
  }
};
</script>
