<template>
    <div class="text-center" v-if="clientTheme">
        <div class="mx-auto text-center" v-if="clientTheme.logo">
            <img :src="imagePath + clientTheme.logo" alt="" class="logo-img"/>
        </div>
        <span v-else>{{clientTheme.name}}</span>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import constants from '../../helpers/constants';

export default {
  name: 'logo',
  data: () => ({
    imagePath: constants.cloudinaryUrlOriginal
  }),
  computed: {
    ...mapState('clients', ['clientTheme'])
  }
};
</script>

<style scoped lang="scss">
    .logo-img {
        max-width: 150px;
    }
</style>
