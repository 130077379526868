<template>
    <v-card>
        <v-card-title class="client-info__heading">
            <v-container>
                <v-layout row wrap>
                    <v-flex xs6>
                        Companies
                    </v-flex>
                    <v-flex xs6 class="d-flex justify-end">
                        <v-btn rounded depressed color="primary" @click="onCreateNewCompany">Add new company</v-btn>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-simple-table width="100%" class="mb-10 table-list" v-if="companies && companies.length > 0">
                <thead>
                <tr>
                    <th width="50"></th>
                    <th class="text-left table-list__heading-sortable"><span>Name <v-icon>expand_more</v-icon></span>
                    </th>
                    <th class="text-left table-list__heading-sortable"><span>Email <v-icon>expand_more</v-icon></span>
                    </th>
                    <th class="text-left table-list__heading-sortable"><span>Contact person <v-icon>expand_more</v-icon></span>
                    </th>
                    <th class="text-right"><span>Actions</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(company, index) in companies" :key="index">
                    <td width="50">
                        <v-avatar color="grey lighten-4" :tile="false" :size="40">
                            <span>{{company.name.substring(0, 1)}}</span>
                        </v-avatar>
                    </td>
                    <td>
                        {{company.name}}
                    </td>
                    <td>{{company.accountHolderEmail || 'N/A'}}</td>
                    <td>{{company.accountHolderName || 'N/A'}}</td>
                    <td class="align-right d-flex justify-end align-center table-list__actions">
                        <v-menu bottom left>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        light
                                        icon
                                        small
                                        v-on="on"
                                >
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item link @click="onDelete(company.id)">
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                                <v-list-item link @click="onEditCompany(company)">
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>
            <empty-state error-text="Currently no companies are added" v-if="companies.length === 0" />
        </v-card-text>
        <edit-create-company :clientId="clientId" :company="selectedCompany" v-if="showEditCreateModal" @companyUpdated="reloadCompanies" />
        <confirm-delete-company v-if="showDeleteModal" :companyId="selectedCompany" :client-id="clientId" @companyDeleted="reloadCompanies"/>
    </v-card>
</template>

<script>
import {getAllCompaniesFromClient} from '../../../graphql/queries/clients';
import ConfirmDeleteCompany from '../Modals/ConfirmDeleteCompany';
import EditCreateCompany from '../Modals/EditCreateCompany';

export default {
  name: 'ClientCompanies',
  components: {EditCreateCompany, ConfirmDeleteCompany},
  data: () => ({
    isLoading: false,
    isError: false,
    errorText: '',
    companies: [],
    selectedCompany: null,
    showDeleteModal: false,
    showEditCreateModal: false,
    clientId: null
  }),
  methods: {
    onCreateNewCompany() {
      this.showEditCreateModal = true;
    },
    onEditCompany(company) {
      this.selectedCompany = company;
      this.showEditCreateModal = true;
    },
    onDelete(id) {
      this.selectedCompany = id;
      this.showDeleteModal = true;
    },
    reloadCompanies() {
      this.showDeleteModal = false;
      this.showEditCreateModal = false;
      this.fetchAllCompaniesToClient('reload');
    },
    fetchAllCompaniesToClient(mode = null) {
      if (mode !== 'reload') {
        this.isLoading = true;
      }
      this.$apollo.mutate({
        mutation: getAllCompaniesFromClient,
        variables: {
          clientId: this.$route.params.id
        }
      }).then(data => {
        this.companies = data.data.companies;
        this.isLoading = false;
        this.isError = false;
      }).catch(error => {
        this.errorText = error;
        this.isLoading = false;
        this.isError = true;
      });
    }
  },
  created() {
    this.fetchAllCompaniesToClient();
    this.clientId = this.$route.params.id;
  }
};
</script>
