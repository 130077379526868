<template>
    <div class="w-100">
        <v-layout row wrap>
            <v-flex sm12 md4 lg3 class="pl-3 mb-sm-5">
                <survey-menu @editMode="onEditMode" mode="edit"></survey-menu>
            </v-flex>
            <v-flex sm12 md8 lg9 class="px-3">
                <v-card flat width="100%">
                    <v-card-text class="pa-0">
                        <loading v-if="isLoading" />
                        <template v-if="!isLoading">
                            <survey-info :data="data" v-if="editType === 'Survey'"></survey-info>
                            <survey-page :data="data" v-if="editType === 'SurveyPage'"></survey-page>
                            <survey-section :data="data" v-if="editType === 'SurveySection'"></survey-section>
                            <survey-question :data="data" v-if="editType === 'SurveyQuestion'"></survey-question>
                        </template>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <add-modal v-if="showAddToSurveyModal"></add-modal>
    </div>
</template>

<script>
import EventBus from '../../../eventBus';
import {mapState, mapMutations, mapGetters} from 'vuex';
import SurveyMenu from '../../../components/Surveys/Builder/SurveyMenu';
import AddModal from '../../../components/Surveys/Modals/AddSurveyModal';
import SurveyInfo from '../../../components/Surveys/Builder/EditMode/SurveyInfo';
import SurveyPage from '../../../components/Surveys/Builder/EditMode/SurveyPage';
import SurveySection from '../../../components/Surveys/Builder/EditMode/SurveySection';
import SurveyQuestion from '../../../components/Surveys/Builder/EditMode/SurveyQuestion';

export default {
  name: 'EditSurvey',
  data: () => ({
    isLoading: false,
    editType: null,
    data: null
  }),
  computed: {
    ...mapState('modals', ['showAddToSurveyModal']),
    ...mapState('surveys', [
      'currentSurvey',
      'surveyEditClientId'
    ]),
    ...mapGetters('clients', ['clientId'])
  },
  components: {
    SurveyQuestion,
    SurveySection,
    SurveyPage,
    SurveyMenu,
    AddModal,
    SurveyInfo
  },
  methods: {
    ...mapMutations('cms', ['updatePageTitle']),
    onEditMode(data) {
      this.editType = null;
      this.data = null;
      this.$nextTick(() => {
        this.editType = data.__typename;
        this.data = data;
      });
    },
    fetchSurvey(hasUpdated) {
      this.$store.dispatch('surveys/getSurveyById', this.$route.params.surveyId).then(() => {
        this.isLoading = false;
        this.updatePageTitle(this.currentSurvey.title.en);
        if (!hasUpdated) {
          this.editType = 'Survey';
        }
      });
    },
    fetchSurveyCategories() {
      const currentCategoryId = this.surveyEditClientId || this.clientId;
      this.$store.dispatch('surveys/getSurveyCategories', currentCategoryId);
    }
  },
  created() {
    this.fetchSurveyCategories();
    this.isLoading = true;
    this.fetchSurvey(false);
  },
  beforeDestroy() {
    EventBus.$off('surveyUpdated');
  },
  mounted() {
    EventBus.$on('surveyUpdated', () => {
      this.fetchSurvey(true);
    });
  }
};
</script>
