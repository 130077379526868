<template>
    <div>
        <v-tabs :center-active="false" :centered="false">
            <v-tab>Survey</v-tab>
            <v-tab>Report data</v-tab>
            <locale-picker :selected="locale" type="tab"></locale-picker>

            <v-tab-item transition="fade" reverse-transition="fade">
                <v-form ref="form" v-if="survey">
                    <v-card flat>
                        <v-card-text>
                            <v-text-field
                                    v-model="survey.title[locale]"
                                    label="Survey title"
                                    :placeholder="locale !== 'en' ? survey.title['en'] : ''"
                                    :rules="[rules.requiredLocale(locale)]"
                            ></v-text-field>
                            <v-textarea
                                    name="input-7-1"
                                    label="Description of the survey"
                                    value="Write an informative description of the survey"
                                    auto-grow
                                    filled
                                    :placeholder="locale !== 'en' ? survey.description['en'] : ''"
                                    v-model="survey.description[locale]"
                            ></v-textarea>
                            <v-select
                                    :items="items"
                                    label="Survey type"
                                    :rules="[rules.required]"
                                    v-model="survey.type"
                            ></v-select>
                            <v-select
                                    :items="surveyCategories"
                                    label="Survey category"
                                    item-text="name.en"
                                    item-value="id"
                                    :rules="[rules.required]"
                                    v-model="survey.categoryId"
                                    v-if="surveyCategories.length > 0"
                            ></v-select>

                            <div style="background-color:#f5f5f5" class="mt-3 mb-3">
                                <div class="text-center" v-if="survey.icon">
                                    <img :src="iconPath + survey.icon" class="my-3 mx-auto" style="max-width:400px"/>
                                </div>
                                <div class="pa-3 text-sm-center">
                                    <v-icon :size="80">cloud_upload</v-icon>
                                    <br/>
                                    <v-btn rounded outlined text depressed color="primary"
                                           @click="onUploadImage('icon')">
                                        Add
                                        image/icon
                                    </v-btn>
                                </div>
                            </div>

                            <survey-list-pages/>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-tab-item>
            <v-tab-item transition="fade" reverse-transition="fade">
                <div class="pa-3 py-10" v-if="survey">

                    <v-textarea
                            name="input-7-1"
                            label="Report text"
                            value="Report text"
                            auto-grow
                            filled
                            hint="Use {NAME} {EMAIL} {BLAH} as variables"
                            v-model="survey.reportText"
                    ></v-textarea>
                    <v-alert type="info" dense tile class="mb-2">
                        {{infoReportInfoText}}
                    </v-alert>

                    <div style="background-color:#f5f5f5" class="mt-10 mb-3">
                        <div class="text-center" v-if="survey.reportLogo">
                            <img :src="iconPath + survey.reportLogo" class="my-3 mx-auto" style="max-width:200px;"/>
                        </div>
                        <div class="pa-3 text-sm-center">
                            <v-icon :size="80">cloud_upload</v-icon>
                            <br/>
                            <v-btn rounded outlined text depressed color="primary" @click="onUploadImage('reportLogo')">
                                Add report logo
                            </v-btn>
                        </div>
                    </div>

                    <v-textarea
                            name="input-7-1"
                            label="Address"
                            value="Address"
                            auto-grow
                            multi-line
                            filled
                            v-model="survey.reportAddress"
                    ></v-textarea>
                    <v-btn link target="_blank"
                           :to="{name: 'survey-master-print', params: {surveyId: survey.id } }">Print the master as PDF
                    </v-btn>
                </div>
            </v-tab-item>
        </v-tabs>

        <div class="px-7 pb-4">
            <v-btn color="primary" :loading="isLoading" outlined rounded @click="onSave" class="mb-3">
                Update
            </v-btn>

            <v-alert type="error" icon="error_outline" v-if="isError">
                There was an error saving. <br/> {{errorText}}
            </v-alert>

            <v-alert type="success" icon="error_outline" v-if="isSaved">
                Details have been saved
            </v-alert>
        </div>

        <div id="gist"></div>
    </div>
</template>

<script>
import EventBus from '../../../../eventBus';
import {mapState, mapGetters} from 'vuex';
import constants from '../../../../helpers/constants';
import SurveyListPages from '../Components/SurveyListPages';
import LocalePicker from '../../../../components/I18N/LocalePicker';

export default {
  name: 'survey-info',
  data: () => ({
    infoReportInfoText: 'Use {{ client }} {{ date }} {{ type }} for adding client, date or type of survey in the report text.',
    iconPath: constants.cloudinaryUrlOriginal,
    valid: false,
    isError: false,
    errorText: '',
    isSaved: false,
    isLoading: false,
    items: ['DAMAGE', 'CONDITION'],
    survey: null,
    rules: {
      required: value => !!value || 'Field is required.',
      requiredLocale: (locale) => {
        if (locale === 'en') {
          return value => !!value || 'Field is required.';
        }
        return false;
      }
    },
  }),
  computed: {
    ...mapGetters('clients', ['clientId']),
    ...mapState('surveys', [
      'currentSurvey',
      'surveyCategories',
      'surveyEditClientId'
    ]),
    ...mapState(['locale'])
  },
  components: {SurveyListPages, LocalePicker},
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        return true;
      } else {
        return false;
      }
    },
    onSave() {
      if (this.validateForm()) {
        this.isLoading = true;

        this.$store.dispatch('surveys/onSurveyUpdate', {
          surveyId: this.survey.id,
          type: this.survey.type,
          title: this.survey.title,
          description: this.survey.description,
          icon: this.survey.icon || '',
          reportText: this.survey.reportText,
          reportLogo: this.survey.reportLogo || '',
          reportAddress: this.survey.reportAddress || '',
          clientId: this.surveyEditClientId || this.clientId,
          categoryId: this.survey.categoryId
        }).then((response) => {
          this.isLoading = false;
          this.isSaved = true;
          this.isError = false;

          //Reset message
          setTimeout(() => {
            this.isError = false;
            this.isSaved = false;
          }, 3000);

          EventBus.$emit('surveyUpdated');
        }).catch((err) => {
          this.errorText = err;
          this.isError = true;
          this.isLoading = false;
        });
      } else {
        this.isError = true;
        this.errorText = 'Please fill in all required fields';
      }
    },
    onUploadImage(type) {
      let folderUrl = type === 'icon' ? 'surveys/icons' : 'surveys/reportLogos';
      //eslint-disable-next-line no-undef
      const myWidget = cloudinary.createUploadWidget({
        cloudName: 'survey-associasion',
        apiKey: '634382941652235',
        apiSecret: '-ShNKXyXJHSbSRdNU_W1k9AygyU',
        secure: true,
        sources: ['local', 'url'],
        showPoweredBy: false,
        uploadPreset: 'v4mk0udz',
        folder: folderUrl,
        styles: {
          palette: {
            window: '#FFF',
            windowBorder: '#90A0B3',
            tabIcon: '#0E2F5A',
            menuIcons: '#5A616A',
            textDark: '#000000',
            textLight: '#FFFFFF',
            link: '#0078FF',
            action: '#FF620C',
            inactiveTabIcon: '#0E2F5A',
            error: '#F44235',
            inProgress: '#0078FF',
            complete: '#20B832',
            sourceBg: '#E4EBF1'
          },
          fonts: {
            "'Rajdhani'": 'https://fonts.googleapis.com/css?family=Rajdhani'
          }
        }
      }, (error, result) => {
        if (result && result.event === 'success') {
          if (type === 'icon') {
            this.survey.icon = result.info.public_id;
          }
          if (type === 'reportLogo') {
            this.survey.reportLogo = result.info.public_id;
          }
        } else {
          window.console.log(error, result);
        }
      });
      myWidget.open();
    },
    printMaster() {

    }
  },
  created() {
    this.survey = Object.assign({}, this.currentSurvey);

    if (!this.survey.description) {
      this.survey.description = {en: ''};
    }

    if (this.survey.category) {
      this.survey.categoryId = this.survey.category.id;
    }

    if (!this.survey.reportText) {
      this.survey.reportText = '';
    }
    if (!this.survey.reportLogo) {
      this.survey.reportLogo = '';
    }

    if (!this.survey.reportAddress) {
      this.survey.reportAddress = '';
    }
  }
};
</script>
