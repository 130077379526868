<template>
    <v-container class="page-editor">
        <v-layout row wrap>
            <v-flex grow class="pa-3">
                <v-card flat>
                    <v-card-title>Create page</v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <div class="mb-3">
                            <v-text-field label="Page name" placeholder="Page name" v-model="page.title"/>
                        </div>
                        <div class="mb-3">
                            <wysiwyg v-model="page.content" ></wysiwyg>
                        </div>
                        <div class="pt-4">
                            <v-btn rounded depressed color="primary" @click="onCreatePage">Create page</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
                <v-alert type="error" prominent v-if="isError">
                    Something went wrong trying to create the page. Please try again later or contact support if the problem persists.
                </v-alert>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
  name: 'CreatePage',
  data: () => ({
    isLoading: false,
    isSaved: false,
    isError: false,
    page: {
      title: '',
      content: ''
    }
  }),
  methods: {
    onCreatePage() {
      this.isLoading = true;
      this.$store.dispatch('static/createPage', this.page).then(() => {
        this.isLoading = false;
        this.isError = false;
        this.isSaved = true;
        this.$router.push({name: 'pages'});
      }).catch(() => {
        this.isError = true;
      });
    }
  }
};
</script>
