<template>
    <v-list>
        <v-list-item>
            <v-list-item-content>
                <v-text-field v-if="getFieldValue(answer)" disabled :value="getFieldValue(answer)" label="Answer"></v-text-field>
                <span v-if="!getFieldValue(answer)">No answer to question</span>
            </v-list-item-content>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
  name: 'AnswerText',
  methods: {
    getFieldValue(field, options) {
      if (typeof field === "string") {
        return field;
      }
      if (typeof field === "number") {
        return field;
      }

      let defaultOptions = { defaultLocale: "en", locale: "en" };
      if (typeof options === "string") {
        options = { locale: options };
      }
      options = { ...defaultOptions, ...options };
      if (field && field[options.locale]) {
        return field[options.locale];
      }
      if (options.defaultLocale && field && field[options.defaultLocale]) {
        return field[options.defaultLocale];
      }

      if (options.default) {
        return options.default;
      }

      return null;
    }
  },
  props: {
    answer: {
      required: false
    }
  }
};
</script>
