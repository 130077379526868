<template>
    <v-dialog
            v-model="dialog"
            max-width="390">
        <v-card>
            <v-card-title class="headline">Assign {{survey.title.en}}</v-card-title>
            <v-card-text>
                <v-form @submit.prevent="onAssign">
                    <p>Which client do you want to assign this survey to?</p>
                    <v-select
                            v-if="clients"
                            :items="clients"
                            v-model="selectedClientId"
                            label="Select client"
                            item-text="name"
                            item-value="id"
                            :rules="[rules.required]"
                    ></v-select>
                    <v-select
                            :disabled="categories.length === 0"
                            :items="categories"
                            v-model="selectedCategory"
                            label="Select category"
                            item-text="name.en"
                            item-value="id"
                            :rules="[rules.required]"
                    ></v-select>
                    <div class="text-sm-center">
                        <v-btn color="primary" :loading="isLoading" min-width="150" outlined rounded type="submit">
                            Assign survey
                        </v-btn>
                    </div>
                </v-form>

                <v-alert type="error" v-if="isError" icon="error_outline" prominent dense class="mt-4">
                    <small>Error assigning survey: {{errorText}}</small>
                </v-alert>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'AssignSurveyModal',
  props: {
    survey: {
      required: true
    }
  },
  data: () => ({
    rules: {
      required: value => !!value || 'Field is required.'
    },
    isLoading: false,
    isError: false,
    errorText: '',
    dialog: true,
    selectedClientId: null,
    selectedCategory: null,
    categories: []
  }),
  computed: {
    ...mapState('clients', ['clients'])
  },
  watch: {
    dialog(val) {
      this.onCloseModal();
    },
    selectedClientId(val) {
      this.fetchCategoriesForClientId(val);
    }
  },
  methods: {
    onAssign() {
      this.isLoading = true;
      this.$store.dispatch('surveys/onAssignSurvey', {
        id: this.survey.id,
        clientId: this.selectedClientId,
        categoryId: this.selectedCategory || null
      }).then(() => {
        this.$emit('surveyAssigned');
        this.isLoading = false;
        this.onCloseModal();
      }).catch((err) => {
        this.errorText = err;
        this.isError = true;
        this.isLoading = false;
      });
    },
    onCloseModal() {
      this.$store.dispatch('modals/closeModal');
    },
    fetchCategoriesForClientId(clientId) {
      this.$store.dispatch('surveys/getSurveyCategories', clientId).then((response) => {
        this.categories = response;
      });
    }
  },
  created() {
    this.$store.dispatch('clients/getAllClients');
  }
};
</script>
