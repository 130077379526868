<script>
export default {
  name: 'VueTitle',
  props: ['title'],
  data() {
    return {
      defaultTitle: null
    }
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        if(this.title) {
          document.title = `${this.title} - SARemote`
        } else {
          document.title = this.defaultTitle;
        }
      }
    }
  },
  render() {
  },
  beforeMount() {
    if (this.defaultTitle === null) {
      this.defaultTitle = document.title
    }
  }
}
</script>
