<template>
    <pre style="font-family: 'Arial', sans-serif; white-space: pre-line">{{answer}}</pre>
</template>

<script>
export default {
  name: 'GenericAnswerString',
  props: {
    answer: {
      required: true
    }
  }
};
</script>
