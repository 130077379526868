<template>
    <v-form ref="form">
        <v-text-field placeholder="Enter first name" label="First name" v-model="user.firstname" />
        <v-text-field placeholder="Enter last name" label="Last name" v-model="user.lastname" />
        <v-text-field type="email" placeholder="Enter email" label="Email" v-model="user.email" />
        <v-text-field
                v-model="user.password"
                :append-icon="showPass ? 'visibility' : 'visibility_off'"
                :rules="[rules.required, rules.min]"
                :type="showPass ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                hint="At least 8 characters"
                counter
                @click:append="showPass = !showPass"
        ></v-text-field>
        <v-select
                class="mt-4"
                :items="roles"
                item-text="role"
                v-model="user.role"
                filled
                label="Choose user role"
        ></v-select>
        <div style="background-color:#f5f5f5" class="mt-4 mb-4">
            <div class="pa-3 text-sm-center">
                <v-icon :size="80">cloud_upload</v-icon>
                <br/>
                <v-btn rounded outlined text depressed color="primary">
                    Upload user image
                </v-btn>
            </div>
        </div>
        <div class="pt-3">
            <v-btn rounded color="primary" depressed :loading="isLoading">Create user</v-btn>
            <v-btn text>Cancel</v-btn>
        </div>
    </v-form>
</template>

<script>
export default {
  name: 'CreateUserForm',
  data: () => ({
    isLoading: false,
    showPass: false,
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters'
    },
    roles: [
      {
        id: 1,
        role: 'admin'
      },
      {
        id: 2,
        role: 'viewer'
      }
    ],
    user: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      image: '',
      role: ''
    }
  })
};
</script>

<style scoped>

</style>
