<template>
    <div>
        <div>{{ question.question.en }}</div>
        <div class="" v-if="question.helpShort.en">{{ question.helpShort.en }}</div>
        <div class="" v-if="question.helpDetail.en">{{ question.helpDetail.en }}</div>
        <template v-if="question.question.en.toLowerCase() === 'country'">
            <div class="text-line"></div>
            <div class="">Please write the country code</div>
        </template>
        <template v-else>
            <template v-if="question.inputType === 'DROPDOWN' || question.inputType === 'RADIO'">
                <div class="page-break-inside">
                    <div class="" :key=index v-for="(option,index) in question.options">
                        <div class="flex mb-2">
                            <div style="width:1rem;height:1rem;border:1px solid black; margin-right:0.5rem; border-radius:100px"></div>
                            <div><strong>{{ option.option.en }}</strong></div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="question.inputType === 'CHECKBOX'">
                <div class="page-break-inside">
                    <div class="" :key=index v-for="(option,index) in question.options">
                        <div class="flex mb-2">
                            <div style="width:1rem;height:1rem;border:1px solid black; margin-right:0.5rem;"></div>
                            <div><strong>{{ option.option.en }}</strong></div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
export default {
  name: 'GenericQuestionOptions',
  props: {
    question: {
      required: true
    }
  },
  methods: {}
};
</script>

<style scoped>
.option {
    height: 1.5rem;
}

.text-line {
    height: 1.5rem;
    border-bottom: 1px solid black;
}
</style>
