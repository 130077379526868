<template>
    <div class="survey-page-info">
        <v-text-field
                :value="page.title.en"
                label="Page title"
                disabled
        ></v-text-field>
    </div>
</template>

<script>
export default {
  name: 'Info',
  props: {
    page: {
      type: Object,
      required: true
    }
  }
};
</script>
