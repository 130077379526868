<template>
  <v-card
    flat
    class="mx-auto survey-menu"
    min-width="250"
  >
    <v-card-text>
      <v-treeview
        ref="treeView"
        :active="active"
        :items="tree"
        activatable
        hoverable
        item-key="id"
        dense
        expand-icon="keyboard_arrow_down"
        shaped
        @update:active="changedActive"
        color="primary">
        <template v-slot:prepend="{ item, open }">
          <v-icon :size="15" v-if="item.__typename === 'Survey'">
            apps
          </v-icon>
          <v-icon :size="15" v-if="item.__typename === 'SurveyPage'">
            folder
          </v-icon>
          <v-icon :size="15" v-if="item.__typename === 'SurveySection'">
            dns
          </v-icon>
          <v-icon :size="15" v-if="item.__typename === 'SurveyQuestion'" :class="{'missing-answer': !item.answer && item.isRequired && !item.hasConditions}">
            help_outline
          </v-icon>
        </template>
        <template slot="label" slot-scope="{ item }">
          <template v-if="item.title || item.question">
            <template
              v-if="item.__typename === 'SurveyPage' || item.__typename === 'SurveySection' || item.__typename === 'SurveyQuestion'">
              {{ item.index }} -
            </template>
            {{ item.title ? item.title.en : item.question.en }}
          </template>
        </template>
        <!-- <template slot="append" slot-scope="{ item }" v-if="mode !== 'admin'">
             <v-btn text small fab rounded depressed @click.stop="addToSurvey(item)" v-if="item.__typename !== 'SurveyQuestion'">
                 <v-icon :size="15">add</v-icon>
             </v-btn>
         </template> -->
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
import constants from "../../../helpers/constants";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "survey-menu",
  props: {
    mode: {
      required: true
    },
    activatedItems: {
      required: false
    }
  },
  data: () => ({
    active: []
  }),
  computed: {
    ...mapGetters("surveys", ["surveyTree"]),
    ...mapGetters("submittedSurveys", ["submittedSurveyTree"]),
    ...mapState("surveys", ["currentSurvey"]),
    ...mapState(["isDirty"]),
    tree() {
      this.updateTree();
      if (this.mode === "edit") {
        return this.surveyTree;
      }
      return this.submittedSurveyTree;
    },
    allNodes() {
      console.log("Calculating all nodes");
      let reducer = (acc, currentValue) => {
        if (currentValue.children?.length > 0) {
          return acc.concat(currentValue, ...currentValue.children.reduce(reducer, []));
        }
        return acc.concat(currentValue);
      };
      let nodes = this.tree.reduce(reducer, []);
      console.log({ nodes: nodes.length });
      return nodes;
    }
  },
  methods: {
    ...mapMutations("surveys", ["toggleModal", "toggleAddMode", "setAddItemObject", "setCurrentSurveyId", "setCurrentPageId", "setCurrentSectionId"]),
    updateTree() {
      setTimeout(() => {
        this.$refs.treeView.updateAll(true);
      }, 150);
    },
    changedActive(active) {
      if (typeof active[0] === "undefined") {
        return;
      }
      if (this.isDirty) {
        console.log("We are in a dirty state");
        if (!window.confirm("Do you want to switch?")) {
          console.log("Did not want to switch");
        } else {
          console.log("did want to switch");
          this.active = [active[0]];
          this.emitNewActiveItem(active[0]);
          this.$store.commit("setIsDirty", false);
        }
      } else {
        console.log("Not dirty. did want to switch");
        this.active = [active[0]];
        this.emitNewActiveItem(active[0]);
      }
    },
    findNodeById(id) {
      console.log("looking for id:", id);
      return this.allNodes.find(f => f.id === id);
    },
    setIds(id, type) {
      if (type === constants.editModes.survey) {
        this.setCurrentSurveyId(id);
      }
      if (type === constants.editModes.page) {
        this.setCurrentPageId(id);
      }
      if (type === constants.editModes.section) {
        this.setCurrentSectionId(id);
      }
    },
    emitNewActiveItem(id) {
      if (id) {
        let node = this.findNodeById(id);
        this.active = [id];
        const type = node.__typename;
        this.setIds(id, type);
        this.$emit("editMode", node);
      }
    },
    addToSurvey(item) {
      let addMode = item.type;

      //Need to overwrite type, to tell which type of section/component should be added
      if (item.__typename === constants.editModes.survey) {
        addMode = constants.editModes.page;
      }
      if (item.__typename === constants.editModes.page) {
        addMode = constants.editModes.section;
      }
      if (item.__typename === constants.editModes.section) {
        addMode = constants.editModes.question;
      }
      this.setAddItemObject(item);
      this.toggleAddMode(addMode);
      this.$store.dispatch("modals/toggleModal", "showAddToSurveyModal");
    }
  }
};
</script>

<style scoped>
.survey-menu {
  max-height: calc(100vh - 64px - 64px);
  overflow: auto;
}
.missing-answer {
  color: #ffa534;
}
</style>
