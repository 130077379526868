<template>
  <div class="log">
    <loading v-if="isLoading" />
    <div v-if="!isLoading">
      <v-timeline dense ::align-top="true" class="mt-3">
        <v-slide-x-reverse-transition
            group
            hide-on-leave
        >
          <v-timeline-item
              v-for="item in log"
              :key="item.createdAt"
              icon="adjust"
              small
              fill-dot
          >
            <v-alert :value="true" type="info">
              <small>{{ item.createdAt | formatDateWithTime }}</small>
              <br />
              <p>
                {{ item.userName }} has {{ item.type | formatLogType }}
              </p>
              <template v-if="item.type === 'STATUS'">
                <div v-if="parseContent(item.content,'oldStatus')">
                  <status-indicator style="background-color: #999999; padding:10px;" prefix="From: " :status="parseContent(item.content,'oldStatus')" show-label :handle-current="false" />
                  <status-indicator style="background-color: #999999; padding:10px;" prefix="To: " :status="parseContent(item.content,'newStatus')" show-label :handle-current="false" />
                </div>
              </template>
              <template v-else>
                <template v-if="item.content">
                  <strong>Questions opened:</strong>
                  <br />
                </template>
                <template v-if="item.content && item.type !== 'SUBMIT_ANSWER_CMS'">
                  <div v-for="(question, index) in JSON.parse(item.content)" :key="index">
                    <p>{{ question.question }}</p>
                  </div>
                </template>
                <template v-if="item.content && item.type === 'SUBMIT_ANSWER_CMS'">
                  <div>{{ item.content.question }}</div>
                </template>
              </template>
            </v-alert>
          </v-timeline-item>
        </v-slide-x-reverse-transition>
      </v-timeline>
    </div>
  </div>
</template>

<script>
export default {
  name: "Log",
  data: () => ({
    log: [],
    isLoading: false,
    isError: false
  }),

  methods: {
    fetchLog() {
      this.$store.dispatch("submittedSurveys/getLog", this.$route.params.surveyId).then((response) => {
        this.isLoading = false;
        this.isError = false;
        this.log = response;
      }).catch(() => {
        this.isLoading = false;
        this.isError = true;
      });
    },
    parseContent(content, field) {
      if (content) {
        let json = JSON.parse(content);
        console.log(json, field);
        return json[field];
      }
      return false;
    }
  },
  created() {
    this.fetchLog();
  }
};
</script>
