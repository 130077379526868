var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'status--text': _vm.showLabel, 'status--active': _vm.isActive}},[(_vm.prefix)?_c('strong',[_vm._v(_vm._s(_vm.prefix))]):_vm._e(),_c('div',{staticClass:"status",class:{
    'status--open': _vm.status === 'OPEN',
    'status--closed': _vm.status === 'CLOSED',
    'status--submitted': _vm.status === 'SUBMITTED',
    'status--partially-submitted': _vm.status === 'PARTIALLY_SUBMITTED',
    'status--cancelled': _vm.status === 'CANCELLED',
    'status--finalized': _vm.status === 'FINALIZED',
    'status--pending-finalization': _vm.status === 'PENDING_FINALIZATION',
    'status--label': _vm.showLabel}}),(_vm.showLabel)?_c('span',{staticClass:"title-case"},[_vm._v(" "+_vm._s(_vm.statusText)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }