<template>
  <v-container>
    <v-layout row>
      <v-flex xs12>

        <loading v-if="isLoading" />

        <v-card flat class="mx-auto" v-if="!isLoading">
          <helper-offset :inline="true"
                         :full-width="false"
                         :offset="24">
            <v-card color="primary"
                    class="v-card--material__header d-flex align-center mb-5"
                    dark
                    elevation="10"
                    min-height="80"
            >
              <v-card-title>
                <h4 class="title font-weight-light mb-2">App users</h4>
              </v-card-title>
            </v-card>
          </helper-offset>
          <v-divider></v-divider>
          <v-simple-table width="100%" class="mb-10 table-list" v-if="appUsers.length > 0">
            <thead>
            <tr>
              <th width="10"></th>
              <th width="50"></th>
              <th class="text-left table-list__heading-sortable"><span>User info <v-icon>expand_more</v-icon></span>
              </th>
              <th class="text-left table-list__heading-sortable"><span>Email <v-icon>expand_more</v-icon></span>
              </th>
              <th class="text-left table-list__heading-sortable"><span>Status <v-icon>expand_more</v-icon></span>
              </th>
              <th class="text-left table-list__heading-sortable"><span>Created at <v-icon>expand_more</v-icon></span>
              </th>
              <th class="text-right"><span>Actions</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(user, index) in appUsers" :key="index">
              <td width="10">
                <active-indicator :isActive="user.status === 'active'" />
              </td>
              <td width="50">
                <v-avatar color="grey lighten-4" :tile="false" :size="40">
                  <span>{{ user.firstName.substring(0, 1) }}</span>
                </v-avatar>
              </td>
              <td>
                <router-link :to="{name: 'edit-user', params: {id: user.id, mode: 'app-user' } }"> {{ user.firstName }}
                  {{ user.lastName }}
                </router-link>
              </td>
              <td><router-link :to="{name: 'edit-user', params: {id: user.id, mode: 'app-user' } }"> {{ user.email }}</router-link></td>
              <td>
                <status-label :status="user.status || 'pendingUser'" />
              </td>
              <td>{{ user.createdAt | formatDateWithTime }}</td>
              <td class="align-right d-flex justify-end align-center table-list__actions">
                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                        light
                        icon
                        small
                        v-on="on"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item link @click="onActivateUser(user)" v-if="user.status === 'pendingAdmin'">
                      <v-list-item-title>Activate user</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="onRejectUser(user)" v-if="user.status === 'pendingAdmin'">
                      <v-list-item-title>Reject user</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="onDeleteUser(user)">
                      <v-list-item-title>Delete user</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
          <empty-state class="mt-10" error-text="There are currently no active app users"
                       v-if="appUsers.length === 0" />
        </v-card>
      </v-flex>
    </v-layout>
    <confirm-delete-user :userId="selectedUserId" mode="app-user" v-if="showConfirmDeleteFEUserModal"
                         @appUserDeleted="fetchUsers" />
  </v-container>
</template>

<script>
import ConfirmDeleteUser from "./Modals/ConfirmDeleteUser";
import { mapState } from "vuex";
import StatusLabel from "../UI/StatusLabel";

export default {
  name: "UserListAppOverview",
  data: () => ({
    isLoading: false,
    isError: false,
    selectedUserId: ""
  }),
  computed: {
    ...mapState("modals", ["showConfirmDeleteFEUserModal"]),
    ...mapState("users", ["appUsers"])
  },
  components: {
    StatusLabel,
    ConfirmDeleteUser
  },
  methods: {
    onActivateUser(user) {
      this.$store.dispatch("users/appUserActivate", user.id).then((response) => {
        user.status = "active";
      }).catch((err) => {
        alert(err);
      });
    },
    onRejectUser(user) {
      this.$store.dispatch("users/appUserReject", user.id).then((response) => {
        user.status = "rejected";
      });
    },
    onDeleteUser(user) {
      this.selectedUserId = user.id;
      this.$store.dispatch("modals/toggleModal", "showConfirmDeleteFEUserModal");
    },
    fetchUsers() {
      this.$store.dispatch("users/getAppUsers").then((response) => {
        this.isLoading = false;
      }).catch(() => {
        this.isError = true;
        this.isLoading = false;
      });
    }
  },
  created() {
    this.isLoading = true;
    this.fetchUsers();
  }
};
</script>
