<template>
    <v-row justify="center">
        <v-btn text small @click.stop="showForgotPasswordModal = true">Forgot password?</v-btn>
        <v-dialog v-model="showForgotPasswordModal" max-width="290">
            <v-card>
                <v-card-title class="headline">Reset your password</v-card-title>

                <v-card-text v-if="!showConfirmation">
                    <p>You forgot your password? No worries, we've git you covered. Simply fill out the form below to reset your password.</p>
                    <v-form>
                        <v-text-field
                                label="Email"
                                name="email"
                                type="text"
                                v-model="email"
                                autofocus
                        ></v-text-field>
                        <div class="text-sm-center">
                            <v-btn color="primary" :loading="isLoading" min-width="150" outlined rounded @click="onResetPassword">Reset password</v-btn>
                        </div>
                    </v-form>

                    <v-alert type="error" icon="error_outline" v-if="isError" class="mt-3">
                        <small>There was an error: {{errorText}}</small>
                    </v-alert>
                </v-card-text>

                <v-card-text v-if="showConfirmation">
                    <p>An email with instructions on how to reset your password has been sent.</p>
                    <div class="text-sm-center">
                        <v-btn color="primary" min-width="150" outlined rounded @click="onClose">Close</v-btn>
                    </div>
                </v-card-text>

            </v-card>

        </v-dialog>
    </v-row>
</template>

<script>
export default {
  name: 'forgot-password',
  data() {
    return {
      email: '',
      showConfirmation: false,
      isError: false,
      errorText: '',
      isLoading: false,
      showForgotPasswordModal: false
    };
  },
  watch: {
    showForgotPasswordModal: function(val, oldVal) {
      if (!val) {
        this.resetForm();
      }
    }
  },
  methods: {
    resetForm() {
      this.showForgotPasswordModal = false;
      this.email = '';
      this.showConfirmation = false;
      this.isError = false;
      this.isLoading = false;
    },
    onResetPassword() {
      this.isLoading = true;
      this.$store.dispatch('users/onForgotPassword', this.email).then(() => {
        this.showConfirmation = true;
        this.isLoading = false;
      }).catch((err) => {
        this.errorText = err;
        this.isError = true;
        this.isLoading = false;
      });
    },
    onClose() {
      this.resetForm();
    }
  }
};
</script>
