<template>
    <div class="survey-report__rating">
        <table width="100%" style="background-color:#FFF;">
            <thead>
            <tr>
                <!--<th style="text-align:left;" class="text-left">ID</th>-->
                <th style="text-align:left;" class="text-left">Name</th>
                <th style="text-align:right;" class="text-right">Rating</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="page in survey.children">
                <tr v-if="page.calculatedRating && page.calculatedRating.rating > 0">
                    <td style="text-align:left; border-bottom:1px solid #ccc; padding:10px 0;">{{ page.index }}
                        {{ page.title.en }}
                    </td>
                    <td style="text-align:right; border-bottom:1px solid #ccc; padding:10px 0;" class="text-right">
                        <template v-if="false">
                            <v-icon class="d-print-inline-block" color="primary" :v-if="false"
                                    :style="{'color': primaryColor}"
                                    v-for="(star, index) in parseInt(page.calculatedRating.rating)"
                                    :key="index">star
                            </v-icon>
                        </template>
                    </td>
                </tr>
                <tr v-for="section in page.children" :key="section.id">
                    <template v-if="getStarCount(section) > 0">
                        <td :style="`text-align:left; border-bottom:1px solid #ccc; padding:10px 0 0 ${page.calculatedRating.rating > 0 ? '20px' : 0} ;`">
                            {{ section.index }} {{ section.title.en }}
                        </td>
                        <td style="text-align:right; border-bottom:1px solid #ccc; padding:10px 0;" class="text-right">
                            <div>
                                <v-icon class="d-print-inline-block" color="primary" :style="{'color': primaryColor}"
                                        v-for="(star, index) in getStarCount(section)"
                                        :key="index">star
                                </v-icon>
                            </div>
                            <span style="font-size:0.6rem;"
                                  v-if="section.weight">Weight: {{
                                    section.weight
                                }}/{{ getTotalPageWeight(page.calculatedRating) }}</span>
                        </td>
                    </template>
                    <!--<template v-else>
                        <td style="text-align:right; border-bottom:1px solid #ccc; padding:10px 0;" colspan="3">
                            No rating on section
                        </td>
                    </template>-->
                </tr>
            </template>
            <tr style="background-color:#f1f1f1;">
                <td colspan="3"
                    style="text-align:center; font-weight: 700; border-bottom:1px solid #ccc; padding:10px 10px;">
                    Average rating: {{ average }}
                </td>
            </tr>
            </tbody>
        </table>
        <!--   <table style="width:100%; margin-top:50px;">
               <thead>
               <tr>
                   <th style="text-align:left;" class="text-left">Rating</th>
                   <th style="text-align:left;" class="text-left">Definition</th>
               </tr>
               </thead>
               <tbody>
               <tr style="background-color:red; color:white">
                   <td style="text-align:left;">1</td>
                   <td style="text-align:left;">Critical / High risk<br />Very poor condition</td>
               </tr>
               <tr style="background-color:yellow; color:black;">
                   <td style="text-align:left;">2</td>
                   <td style="text-align:left;">Low quality / Medium Risk<br />Fair - poor condition </td>
               </tr>
               <tr style="background-color:green; color:white;">
                   <td style="text-align:left;">3</td>
                   <td style="text-align:left;">Acceptable quality<br />Good condition</td>
               </tr>
               <tr style="background-color:lightgreen; color:black;">
                   <td style="text-align:left;">4</td>
                   <td style="text-align:left;">High quality<br />Very good condition</td>
               </tr>
               <tr style="background-color:blue; color:white;">
                   <td style="text-align:left;">5</td>
                   <td style="text-align:left;">Excellent quality<br />New condition</td>
               </tr>
               </tbody>
           </table> -->
    </div>
</template>

<script>
export default {
  name: 'RatingReport',
  props: {
    survey: {
      type: Object
    }
  },
  data: () => ({
    sections: [],
    primaryColor: null
  }),
  computed: {
    average() {
      if (this.survey.calculatedRating) {
        return this.survey.calculatedRating.rating;
      }
      let ratings = this.survey.children.reduce((acc, item) => {
        if (item.calculatedRating) {
          acc.push(item.calculatedRating.rating);
        } else {
          let childRating = [];
          item.children.forEach((section) => {
            if (section.rating) {
              childRating.push(section.rating);
            } else if (section.calculatedRating) {
              childRating.push(section.calculatedRating.rating);
            }
          });
          let childSum = childRating.reduce((acc, item) => acc + item, 0);
          acc.push(childSum / childRating.length);
        }

        return acc;
      }, []);
      let sum = ratings.reduce((acc, item) => acc + item, 0);

      return (sum / ratings.length).toFixed(1);
    }
  },
  methods: {
    getTotalPageWeight(calculatedRating) {
      return calculatedRating.data?.totalWeight || 100;
    },
    getStarCount(section) {
      if (section.rating) {
        return section.rating;
      }
      if (section.calculatedRating) {
        return Math.round(section.calculatedRating.rating);
      }
      return 0;
    },
    shouldShowSection(children) {
      let shouldShow;
      children.forEach((question) => {
        if (question.answer) {
          shouldShow = true;
        } else {
          shouldShow = false;
        }
      });
      return shouldShow;
    }
  },
  created() {
    this.primaryColor = this.$vuetify.theme.themes.light.primary;
  }
};
</script>
