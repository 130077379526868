import gql from 'graphql-tag';

export const updateClientByID = gql`
    mutation clientSet($clientId: ID, $name: String!, $subdomain: String!, $color: String!, $logo: String!, $accountHolderName: String!, $accountHolderEmail: String!, $accountHolderPhone: String!) {
        clientSet(
            clientId: $clientId,
            name: $name,
            subdomain: $subdomain,
            color: $color,
            logo: $logo,
            accountHolderName: $accountHolderName,
            accountHolderEmail: $accountHolderEmail,
            accountHolderPhone: $accountHolderPhone
        ) {
            success,
            message
        }
    }
`;

export const inviteUserToClientCMS = gql`
    mutation adminAdd($clientId: ID!, $name: String!, $email: String!) {
        adminAdd(
            clientId: $clientId,
            name: $name,
            email: $email
        ) {
            success,
            message
        }
    }
`;

export const deleteClient = gql`
    mutation clientDelete($id: ID!) {
        clientDelete(
            id: $id
        )
    }
`;

export const deleteCompany = gql`
    mutation companyDelete($id: ID!, $clientId: ID) {
        companyDelete(
            id: $id,
            clientId: $clientId
        )
    }
`;

export const companySet = gql`
    mutation companySet($name: String!, $accountHolderName: String!, $accountHolderEmail: String!, $companyId: ID, $clientId: ID) {
        companySet(
            name: $name,
            accountHolderName: $accountHolderName,
            accountHolderEmail: $accountHolderEmail,
            companyId: $companyId,
            clientId: $clientId
        ) {
            success,
            message
        }
    }
`;
