<template functional>
    <div :class="{'active--text': props.showLabel}">
        <div class="active" :class="{'active--active': props.isActive, 'active--inactive': !props.isActive, 'active--label': props.showLabel}"></div>
        <span v-if="props.showLabel">{{props.isActive ? 'Active' : 'Inactive'}}</span>
    </div>
</template>

<script>
export default {
  name: 'ActiveIndicator',
  functional: true
};
</script>
