<template>
  <v-container>
    <v-layout row wrap>
      <v-flex grow class="pa-3">
        <v-card flat class="mx-auto">
          <v-card-title>Edit user: {{ user.firstName }} {{ user.lastName }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <edit-user-form :mode="mode" />
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import EditUserForm from "../../../components/Forms/Users/EditUserForm";
import { mapState } from "vuex";

export default {
  name: "EditUser",
  props: {
    mode: {
      default: "admin"
    }
  },
  data: () => ({ user: null }),
  components: { EditUserForm },
  computed: {
    ...mapState("users", ["appUsers", "users"])

  },
  mounted() {
    if (this.mode === "app-user") {
      this.user = { ...this.appUsers.find((user) => user.id === this.$route.params.id) };
    } else {
      this.user = { ...this.users.find((user) => user.id === this.$route.params.id) };
    }
  }
};
</script>
