<template>
  <v-card>
    <v-card-title class="client-info__heading">
      <div class="d-flex flex-grow" >
        <div>
          App users
        </div>
      </div>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <loading v-if="isLoading" />
      <v-simple-table width="100%" class="mb-10 table-list" v-if="!isLoading && appUsers.length > 0">
        <thead>
        <tr>
          <th colspan="2" class="text-left"><span>Name</span></th>
          <th class="text-left"><span>Email</span></th>
          <th class="text-left"><span>Last login</span></th>
          <th class="text-left"><span>Status</span></th>
          <th class="text-right"><span>Actions</span></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user, index) in appUsers" :key="index">
          <td width="50">
            <div class="d-flex flex-column align-center py-2">
              <v-avatar color="grey lighten-4" :tile="false" :size="40" class="mx-auto">
                <span>{{ user.firstName.substring(0, 1) }}{{ user.lastName.substring(0, 1) }}</span>
              </v-avatar>
              <span style="font-size: 4px;">{{ user.id }}</span>
            </div>
          </td>

          <td>
            <router-link :to="{name: 'edit-user', params: {id: user.id, mode: 'app-user' } }"> {{ user.firstName }}
              {{ user.lastName }}
            </router-link>
          </td>
          <td>
            <router-link :to="{name: 'edit-user', params: {id: user.id, mode: 'app-user' } }"> {{ user.email }}
            </router-link>
          </td>
          <td><span v-if="user.lastLogin">{{ user.lastLogin | formatDate }}</span><span v-else>N/A</span></td>
          <td>{{ user.status }}</td>
          <td class="table-list__actions">
            <div class="align-right d-flex justify-end align-center ">
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn
                      light
                      icon
                      small
                      v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item link :to="{name: 'edit-user', params: {userId: user.id } }">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="user.status !== 'active'" @click.prevent="confirmUser(user)">
                    <v-list-item-title>Confirm/Activate User</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <empty-state error-text="Currently no app users" v-if="appUsers.length === 0" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { appUserActivate } from "@/graphql/mutations/users";
import { apolloClient } from "@/apollo/init-apollo";

export default {
  name: "ClientAppUsers",
  data: () => ({
    isLoading: false,
    isError: false
  }),
  computed: {
    ...mapState("clients", ["currentClient"]),
    ...mapState("users", ["appUsers"])
  },
  methods: {
    fetchUsers() {
      this.isLoading = true;
      this.$store.dispatch("users/getAppUsersByClient", this.$route.params.id).then((response) => {
        this.isLoading = false;
      }).catch(() => {
        this.isError = true;
        this.isLoading = false;
      });
    },
    confirmUser(user) {
      let confirmed = confirm(`Are you sure you want to confirm ${user.email}?`);
      if (confirmed) {
        this.isLoading = true;
        apolloClient.mutate({
          mutation: appUserActivate,
          variables: { id: user.id, clientId: this.$route.params.id }
        }).catch(err => {
          alert(err);
        }).then(this.fetchUsers);
      }
    }
  },

  created() {
    this.fetchUsers();
  }
};
</script>
