<template>
    <div>
        <vue-title :title="currentPageTitle" />
        <v-navigation-drawer color="#2A2D3D"
                             :mobile-break-point="768"
                             dark
                             app
                             hide-overlay
                             :clipped="clipped"
                             v-model="drawer"
                             enable-resize-watcher>
            <v-list-item>
                <v-list-item-content v-if="clientTheme">
                    <!--<v-list-item-title class="mb-1" v-if="clientTheme.logo">
                        <img :src="imagePath + clientTheme.logo" style="max-height:50px; text-align:center; margin: 0 auto;" />
                    </v-list-item-title>-->
                    <v-list-item-subtitle v-if="clientTheme.name">
                        {{ clientTheme.name }}
                        <br/>
                        {{ clientTheme.subdomain }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
                <v-list-item exact :to="{'name': 'dashboard'}" color="#E4E3E3">
                    <v-list-item-icon>
                        <v-icon color="#676B7E">home</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>Home</v-list-item-title>
                </v-list-item>

                <v-list-item :to="{'name': 'surveys-administration'}" color="#E4E3E3" v-if="isRootClient">
                    <v-list-item-icon>
                        <v-icon color="#676B7E">layers</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>Survey master administation</v-list-item-title>
                </v-list-item>

                <v-list-item :to="{'name': 'survey-management'}" color="#E4E3E3">
                    <v-list-item-icon>
                        <v-icon color="#676B7E">done_all</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>Survey management</v-list-item-title>
                </v-list-item>

                <v-list-item :to="{'name': 'users'}" color="#E4E3E3">
                    <v-list-item-icon>
                        <v-icon color="#676B7E">person</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>Team management</v-list-item-title>
                </v-list-item>

                <v-list-item :to="{'name': 'clients'}" color="#E4E3E3">
                    <v-list-item-icon>
                        <v-icon color="#676B7E">face</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>Client management</v-list-item-title>
                </v-list-item>

                <v-list-item :to="{'name': 'pages'}" color="#E4E3E3">
                    <v-list-item-icon>
                        <v-icon color="#676B7E">pages</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>Pages</v-list-item-title>
                </v-list-item>
                <v-list-item :to="{'name': 'roles'}" color="#E4E3E3" v-if="isRootClient">
                    <v-list-item-icon>
                        <v-icon color="#676B7E">gavel</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>Roles</v-list-item-title>
                </v-list-item>
            </v-list>

            <template v-slot:append>
                <div class="pa-5">
                    <v-btn color="white" rounded small light block @click="onSignOut">Sign out</v-btn>
                </div>
            </template>

        </v-navigation-drawer>

        <v-app-bar app color="#fff" light flat>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title>{{ currentPageTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <!--<v-btn icon color="primary" value="notifications">
                <v-badge right overlap>
                    <template v-slot:badge>
                        <span>6</span>
                    </template>
                    <v-icon>
                        notifications_none
                    </v-icon>
                </v-badge>
            </v-btn>-->

            <!-- <v-btn icon color="primary" value="profile">
                 <v-icon>
                     person
                 </v-icon>
             </v-btn> -->

        </v-app-bar>

        <v-content>
            <v-container class="fill-height" fluid>
                <v-layout row wrap>
                    <v-flex xs12 grow>
                        <page-transition>
                            <router-view></router-view>
                        </page-transition>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { clearUserStorage } from '../../helpers/authHelpers';
import { updatePageTitleMixin } from '../../helpers/mixins/update-page-title';
import VueTitle from '../../components/UI/Title';

export default {
  name: 'IndexAdmin',
  mixins: [updatePageTitleMixin],
  components: { VueTitle },
  data: () => ({
    showFullMenu: true,
    drawer: true,
    clipped: false,
    surveysMenu: [
      {
        icon: 'layers',
        title: 'Survey Templates',
        active: false,
        link: 'surveys'
      },
      {
        icon: 'layers',
        title: 'Survey Management',
        active: 'false',
        link: 'survey-management'
      }
    ]
  }),
  computed: {
    ...mapGetters('cms', ['currentPageTitle']),
    ...mapState('users', ['currentUser']),
    ...mapState('clients', ['clientTheme']),
    ...mapGetters('clients', ['isRootClient'])
  },
  methods: {
    ...mapMutations('cms', ['updatePageTitle']),
    changePage(pageName) {
      this.updatePageTitle(pageName);
    },
    onSignOut() {
      clearUserStorage();
      this.$router.push('/');
    }
  }
};
</script>
