<template>
    <v-list>
        <small style="font-weight:300;" class="pl-4">Answer</small>
        <v-list-item v-for="(option, index) in answer" :key="index" class="u-border-bottom" :class="{'u-border-bottom-none': (index + 1) === answer.length }">
            <v-list-item-content>
                <strong>{{option.answer}}</strong>
                <br />
                <small>{{option.estimate}}</small>
            </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="!answer">
            No answer to question
        </v-list-item>
    </v-list>
</template>

<script>
export default {
  name: 'AnswerCostEstimate',
  props: {
    answer: {
      required: true
    }
  }
};
</script>
