<template>
    <div class="app-view show-v-text-field__details">
        <v-container class="pt-0 mt-0 mx-auto" style="max-width:400px">

            <div class="py-4">
                <h1>Reset password</h1>
                <p class="my-0 py-0">Reset your password, and you'll be on your way</p>
            </div>

            <v-card flat class="mt-10">
                <v-card-text>
                    <v-form class="py-4" v-if="!isSaved">
                        <v-text-field
                                v-model="user.email"
                                label="Email"
                                placeholder="Email"
                        ></v-text-field>
                        <v-text-field
                                type="password"
                                v-model="user.password"
                                :rules="[rules.required, rules.min]"
                                label="Enter desired password"
                                placeholder="Password"
                        ></v-text-field>
                        <v-text-field
                                type="password"
                                v-model="passwordConfirm"
                                hint="At least 8 characters"
                                :rules="[rules.required, rules.min, passwordConfirmationRule]"
                                label="Confirm password"
                                placeholder="Confirm password"
                        ></v-text-field>
                        <div class="text-center pt-4">
                            <v-btn color="primary" depressed rounded outlined @click="onResetPassword">Reset
                                password
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>

            <loading v-if="isLoading" />

            <v-alert type="info" dense v-if="isSaved">
                Your password have been set, you can now login with your new password
                <br />
                <v-btn rounded small outlined color="white" class="mt-2" @click="onGotoLogin">Login now</v-btn>
            </v-alert>

            <v-alert type="error" dense icon="error_outline" v-if="isError" class="mt-2">
                {{errorText}}
            </v-alert>

        </v-container>
    </div>
</template>

<script>
import {adminResetPassword} from '../graphql/mutations/users';

export default {
  name: 'PasswordReset',
  data: () => ({
    isLoading: false,
    isSaved: false,
    isError: false,
    errorText: '',
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters'
    },
    passwordConfirm: '',
    user: {
      email: '',
      password: '',
      token: ''
    }
  }),
  computed: {
    passwordConfirmationRule() {
      return () => (this.user.password === this.passwordConfirm) || 'Password must match';
    }
  },
  methods: {
    onResetPassword() {
      this.isLoading = true;
      this.$apollo.mutate({
        mutation: adminResetPassword,
        variables: {
          email: this.user.email,
          token: this.user.token,
          password: this.user.password
        }
      }).then(data => {
        this.isLoading = false;
        this.isError = false;
        this.isSaved = true;
      }).catch(error => {
        this.errorText = error;
        this.isLoading = false;
        this.isError = true;
        this.isActivated = false;
      });
    },
    onGotoLogin() {
      this.$router.push('/');
    }
  },
  created() {
    const url = window.location.href;
    const array = url.split('/');
    const token = array[array.length - 2];
    const email = array[array.length - 1];
    this.user.token = token;
    this.user.email = email;
  }
};
</script>
