<template>
    <div class="survey-section-info" v-if="section">
        <v-text-field
                :value="section.title ? section.title.en : 'N/A'"
                label="Survey title"
                disabled
        ></v-text-field>
        <v-textarea
                name="input-7-1"
                label="Description of the survey"
                auto-grow
                filled
                disabled
                :value="section.description ? section.description.en : 'N/A'"
        ></v-textarea>
        <v-text-field
                 v-if="section.displayConditions"
                :value="section.displayConditions ? 'Section has display conditions': 'Section has no display conditions'"
                label="Display conditions"
                disabled
        ></v-text-field>
    </div>
</template>

<script>
export default {
  name: 'Info',
  props: {
    section: {
      type: Object,
      required: true
    }
  }
};
</script>
