<template>
    <v-dialog
            v-model="dialog"
            max-width="390">
        <v-card>
            <v-card-title class="headline" v-if="page">Assign {{page.title.en}}</v-card-title>
            <v-card-text>
                <v-form @submit.prevent="onAssign">
                    <p>Which client do you want to assign this page to?</p>
                    <v-select
                            v-if="clients"
                            :items="clients"
                            v-model="selectedClientId"
                            label="Select client"
                            item-text="name"
                            item-value="id"
                    ></v-select>
                    <div class="text-sm-center">
                        <v-btn color="primary" :loading="isLoading" min-width="150" outlined rounded type="submit">
                            Assign page
                        </v-btn>
                    </div>
                </v-form>

                <v-alert tile class="mt-3" type="error" icon="error_outline" v-if="isError">
                    <small>There was an assigning the page: Error: {{errorText}}</small>
                </v-alert>

            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from 'vuex';
import {cloneStaticPage} from '../../../graphql/mutations/static';

export default {
  name: 'AssignPageModal',
  props: {
    page: {
      required: true
    }
  },
  data: () => ({
    isLoading: false,
    isError: false,
    errorText: '',
    dialog: true,
    selectedClientId: null
  }),
  computed: {
    ...mapState('clients', ['clients'])
  },
  watch: {
    dialog(val) {
      this.onCloseModal();
    }
  },
  methods: {
    onAssign() {
      this.isLoading = true;
      this.$apollo.mutate({
        mutation: cloneStaticPage,
        variables: {
          id: this.page.id,
          clientId: this.selectedClientId
        }
      }).then(data => {
        this.isLoading = false;
        this.$emit('pageAssigned');
        this.onCloseModal();
      }).catch(err => {
        this.isLoading = false;
        this.isError = true;
        this.errorText = err;
      });
    },
    onCloseModal() {
      this.$store.dispatch('modals/closeModal');
    }
  },
  created() {
    this.$store.dispatch('clients/getAllClients');
  }
};
</script>
