export default function(value) {
  switch (value) {
  case "SUBMIT":
    return "submitted";
  case "RESUBMIT":
    return "resubmitted";
  case "SUBMIT_ANSWER_CMS":
    return "answered question from cms";
  case "REOPEN":
    return "reopened";
  case "STATUS":
    return "changed status";
  case "CLOSE":
    return "closed";
  default:
    return "Missing a type/action";
  }
}
