<template>
  <v-dialog
      v-model="dialog"
      max-width="400">
    <v-card>
      <v-card-title class="headline text-center d-block">Delete: {{ getFieldValue(itemTitle) }}</v-card-title>

      <v-card-text>
        <div class="text-center">
          <v-form ref="form" @submit.prevent="onDelete">
            <v-icon :size="60" color="primary" class="py-3">delete</v-icon>
            <p>To delete this {{ type }}, please confirm by typing DELETE in the field below</p>
            <v-text-field
                v-model="deleteText"
                label="Confirm"
                name="confirm"
                type="text"
                autocomplete="off"
                :rules="validationRule"
            ></v-text-field>
            <div class="text-center">
              <v-btn color="primary" type="submit" :loading="isLoading" min-width="150" outlined rounded>
                Delete
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import constants from "@/helpers/constants";
import GetFieldValueMixin from "@/mixins/GetFieldValueMixin";

export default {
  name: "ConfirmDelete",
  mixins: [GetFieldValueMixin],

  props: {
    itemTitle: {
      required: true
    },
    type: {
      default: "role"
    }
  },
  data: () => ({
    validationRule: [v => (v) === constants.deleteMode.confirmText || "Error in text"],
    dialog: true,
    deleteText: "",
    isLoading: false
  }),
  watch: {
    dialog(val) {
      this.onCloseModal();
    }
  },
  methods: {
    onValidateConfirm() {
      if (this.deleteText !== constants.deleteMode.confirmText) {
        this.$refs.form.validate();
        return false;
      } else {
        return true;
      }
    },
    onDelete() {
      if (this.onValidateConfirm()) {
        this.onCloseModal(true);
      }
    },
    onCloseModal(confirmed = false) {
      this.$emit("closed", confirmed);
    }
  }
};
</script>

<style scoped>

</style>
