export const minMaxMixin = {
  props: {
    min: {
      required: false
    },
    max: {
      required: false
    }
  },
  data: () => ({
    minimum: '',
    maximum: ''
  }),
  methods: {
    updateMinData() {
      if (this.minimum.toString().length > 0) {
        this.$emit('updateMinData', this.minimum);
      } else {
        this.$emit('updateMinData', null);
      }
    },
    updateMaxData() {
      if (this.maximum.toString().length > 0) {
        this.$emit('updateMaxData', this.maximum);
      } else {
        this.$emit('updateMaxData', null);
      }
    }
  },
  created() {
    if (this.min || this.min === 0) {
      this.minimum = this.min;
    }
    if (this.max || this.max === 0) {
      this.maximum = this.max;
    }
  }
};
