<template>
    <div style="background-color:#f5f5f5;" class="pa-3 my-4">
        <v-list>
            <v-list-item link v-for="(section, index) in children" :key="section.id" class="u-border-bottom u-drag" :class="{'u-border-bottom-none': (index + 1) === children.length }">
                <v-list-item-avatar>
                    <v-avatar
                            :tile="false"
                            :size="30"
                            color="primary"
                    >
                        <span class="white--text">S</span>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{section.title.en}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!children || children.length === 0">
                <v-list-item-content>
                    No sections added
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
export default {
  name: 'SurveyEditorListSections',
  props: {
    children: {
      type: Array,
      required: true
    }
  }
};
</script>
