
export const state = {
  showAddToSurveyModal: false,
  showConfirmDeleteModal: false,
  showAssignSurveyModal: false,
  showReopenSurveyModal: false,
  showCompleteSurveyModal: false,
  showInviteUserToClientCMSModal: false,
  showInviteUserModal: false,
  showConfirmDeleteUserModal: false,
  showConfirmDeleteClientModal: false,
  showConfirmDeleteFEUserModal: false,
  showAssignPageModal: false
};

export const getters = {
};

const mutations = {
  setModal(state, payload) {
    state[payload] = !state[payload];
  },
  closeModal(state) {
    Object.keys(state).map((key) => {
      state[key] = false;
    });
  }
};

const actions = {
  toggleModal({commit}, val) {
    commit('setModal', val);
  },
  closeModal({commit}) {
    commit('closeModal');
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
