export const clearUserStorage = () => {
  //clearInterval(window.refreshToken);
  sessionStorage.clear();
  localStorage.clear();
};

export const createUserStorage = (data) => {
  localStorage.setItem('AUTH_TOKEN', data.message);
  localStorage.setItem('USER_ID', data.admin.id);
  localStorage.setItem('CURRENT_ADMIN', data.admin);
};
