<template>
    <div class="list-pages">
        <div class="list-pages__list pa-3 mt-10" v-for="client in pages" :key="client.id">
            <v-card flat class="mx-auto mb-5">
                <helper-offset :inline="true"
                               :full-width="false"
                               :offset="24">
                    <v-card color="primary"
                            class="v-card--material__header d-flex align-center mb-5"
                            dark
                            elevation="10"
                            min-height="80"
                    >
                        <v-card-title>
                            <h4 class="title font-weight-light mb-2">{{client.name}} pages</h4>
                        </v-card-title>
                    </v-card>
                </helper-offset>

                <v-simple-table width="100%" class="table-list" v-if="client.staticPages">
                    <thead>
                    <tr>
                        <th class="text-left table-list__heading-sortable"><span>Title</span></th>
                        <th class="text-right"><span>Actions</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in client.staticPages" :key="index">
                        <td>
                            <router-link :to="{name: 'edit-page', params: {id: item.id } }">
                                {{item.title.en || 'NA'}}
                            </router-link>
                        </td>
                        <td class="align-right d-flex justify-end align-center table-list__actions">
                            <v-menu bottom left>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                            light
                                            icon
                                            small
                                            v-on="on"
                                            class="mt-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item link @click="onDeletePage(item.id)">
                                        <v-list-item-title>Delete</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item link @click="onAssignPage(item)" v-if="isRootClient && isRoot">
                                        <v-list-item-title>Assign page</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>

                <empty-state type="error" error-text="No pages assigned" v-if="client.staticPages.length === 0"></empty-state>

            </v-card>
        </div>
    </div>
</template>

<script>
import {listPagesMixin} from '../../helpers/mixins/list-pages';
import {mapGetters} from 'vuex';
export default {
  name: 'ListClientsAndPages',
  props: {
    pages: {
      type: Array
    },
    isRoot: {
      default: false
    }
  },
  computed: {
    ...mapGetters('clients', ['isRootClient'])
  },
  mixins: [listPagesMixin]
};
</script>
