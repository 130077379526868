<template>
  <v-card flat class="mb-10">
    <v-layout align-content-start justify-start class="pa-5">

      <div class="d-flex mr-5" @click="selectedStatus('PARTIALLY_SUBMITTED')">
        <status-indicator status="PARTIALLY_SUBMITTED" :show-label="true" />
      </div>
      <div class="d-flex mr-5" @click="selectedStatus('SUBMITTED')">
        <status-indicator status="SUBMITTED" :show-label="true" />
      </div>
      <div class="d-flex mr-5" @click="selectedStatus('OPEN')">
        <status-indicator status="OPEN" :show-label="true" />
      </div>
      <div class="d-flex mr-5" @click="selectedStatus('CANCELLED')">
        <status-indicator status="CANCELLED" :show-label="true" />
      </div>
      <div class="d-flex mr-5" @click="selectedStatus('PENDING_FINALIZATION')">
        <status-indicator status="PENDING_FINALIZATION" :show-label="true" />
      </div>
      <div class="d-flex mr-5" @click="selectedStatus('CLOSED')">
        <status-indicator status="CLOSED" :show-label="true" />
      </div>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  name: "StatusIndicatorsRow",
  methods: {
    selectedStatus(status) {
      this.$store.commit("submittedSurveys/setStatus", status);
    }
  }
};
</script>
