<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <span v-if="!answer">Not answered</span>
        <v-text-field disabled v-else :value="answer | formatDate" label="Answer"></v-text-field>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "AnswerDate",
  props: {
    answer: {
      required: true
    }
  }
};
</script>
