import Vue from "vue";
import Vuex from "vuex";
import users from "./modules/users";
import cms from "./modules/cms";
import surveys from "./modules/surveys";
import modals from "./modules/modals";
import clients from "./modules/clients";
import submittedSurveys from "./modules/submitted-surveys";
import staticPages from "./modules/static";
import roles from "@/store/modules/roles";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    users: { ...users, namespaced: true },
    cms: { ...cms, namespaced: true },
    surveys: { ...surveys, namespaced: true },
    modals: { ...modals, namespaced: true },
    clients: { ...clients, namespaced: true },
    submittedSurveys: { ...submittedSurveys, namespaced: true },
    static: { ...staticPages, namespaced: true },
    roles: { ...roles, namespaced: true },
  },
  state: {
    locale: "en",
    isDirty: false,
  },
  mutations: {
    changeLanguage(state, newLocale) {
      state.locale = newLocale;
    },
    setIsDirty(state, dirty) {
      state.isDirty = dirty;
    },
  },
  strict: false,
});

export default store;
