<template>
  <v-dialog
      v-model="dialog"
      max-width="400">
    <v-card>
      <v-card-title class="headline text-center d-block">Copy: {{ getFieldValue(itemTitle) }}</v-card-title>

      <v-card-text>
        <div class="text-center">
          <v-form @submit.prevent="onCopy">
            <p>Which client do you want to assign this {{ type }} to?</p>
            <v-select
                v-if="clients"
                :items="clients"
                v-model="selectedClientId"
                label="Select client"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
            ></v-select>
            <div class="text-sm-center">
              <v-btn color="primary" :loading="isLoading" min-width="150" outlined rounded type="submit">
                Copy {{ getFieldValue(itemTitle) }}
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import GetFieldValueMixin from "@/mixins/GetFieldValueMixin";

export default {
  name: "CopyModal",
  mixins: [GetFieldValueMixin],
  props: {
    itemId: {
      required: true
    },
    dispatchAction: {
      required: true
    },
    itemTitle: {
      required: true
    },
    type: {
      default: "role"
    }
  },
  data: () => ({
    rules: {
      required: value => !!value || "Field is required."
    },
    isLoading: false,
    isError: false,
    errorText: "",
    dialog: true,
    selectedClientId: null
  }),
  watch: {
    dialog(val) {
      this.onCloseModal();
    }
  },
  computed: {
    ...mapState("clients", ["clients"])
  },
  methods: {
    onCopy() {
      this.isLoading = true;
      this.$store.dispatch(this.dispatchAction, {
        id: this.itemId,
        clientId: this.selectedClientId
      }).then(() => {
        this.$emit("copied");
        this.isLoading = false;
        this.onCloseModal(true);
      }).catch((err) => {
        this.errorText = err;
        this.isError = true;
        this.isLoading = false;
      });
    },
    onCloseModal(confirmed = false) {
      this.$emit("closed", confirmed);
    }
  },
  created() {
    this.$store.dispatch("clients/getAllClients");
  }
};
</script>

<style scoped>

</style>
