import gql from "graphql-tag";
import { LocalizedInputFragment } from "../fragtments/localizedInput";

export const createRole = gql`
  mutation roleAdd($role: RoleInput!) {
    roleAdd(role: $role) {
      success
      message
      role {
        id
        title {
          ...localizedFragment
        }
        roleName
        clientId
      }
    }
  }
  ${LocalizedInputFragment}
`;
export const copyRoleToClient = gql`
  mutation copyRole($roleId: ID!, $toClient: ID!) {
    roleCopy(roleId: $roleId, clientId: $toClient) {
      success
      message
      role {
        id
        title {
          ...localizedFragment
        }
        roleName
        clientId
      }
    }
  }
  ${LocalizedInputFragment}
`;

export const deleteRole = gql`
  mutation deleteRole($roleId: ID!) {
    roleDelete(id: $roleId)
  }
`;

export const updateRole = gql`
  mutation roleUpdate($id: ID!, $role: RoleInput) {
    roleUpdate(id: $id, role: $role) {
      success
      message
      role {
        id
        title {
          ...localizedFragment
        }
        userType
        clientId
        roleName
        permissions
        notifications
      }
    }
  }
  ${LocalizedInputFragment}
`;
