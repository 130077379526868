import {apolloClient} from "../../apollo/init-apollo";
import {
  activateSurvey,
  addPageToSurvey,
  addQuestionToSection,
  addSectionToPage,
  cloneSurvey,
  createSurvey,
  deactivateSurvey,
  deletePageFromSurvey,
  deleteQuestionFromSection,
  deleteSectionFromPage,
  deleteSurvey,
  setDisplayConditions,
  surveyUpdateRole,
  updatePageToSurvey,
  updateQuestionToSection,
  updateSectionToPage,
  updateSurvey,
} from "../../graphql/mutations/surveys";
import {getSurveyById, surveyCategories} from "../../graphql/queries/surveys";

export const state = {
  showModal: false,
  addMode: null,
  addItemObj: {},
  currentSurvey: null,
  surveyCategories: [],
  surveyEditClientId: null,
  editMode: {
    currentSurveyId: null,
    currentPageId: null,
    currentSectionId: null,
  },
};

export const getters = {
  surveyTree: (state) => {
    return [
      {
        ...state.currentSurvey,
      },
    ];
  },
  surveyQuestions: (state) => {
    let questions = [];

    //TODO: Make more efficient
    if (state.currentSurvey) {
      state.currentSurvey.children.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach((section) => {
            if (section.children && section.children.length > 0) {
              section.children.forEach((question) => {
                if (question) {
                  questions.push(question);
                }
              });
            }
          });
        }
      });
    }

    return questions;
  },
  currentSections: (state) => {
    if (state.currentSurvey && state.currentSurvey.children) {
      const currentPage = state.currentSurvey.children.find(
        (item) => item.id === state.editMode.currentPageId
      );
      if (currentPage) {
        return currentPage.children;
      }
    }
    return [];
  },
  currentQuestions: (state) => {
    let currentQuestions = [];

    if (state.currentSurvey) {
      state.currentSurvey.children.map((item) => {
        if (item.id === state.editMode.currentPageId) {
          item.children.map((section) => {
            if (section.id === state.editMode.currentSectionId) {
              currentQuestions = [...section.children];
            }
          });
        }
      });
    }
    return currentQuestions;
  },
  currentSurveyId: (state) => state.currentSurvey.id,
};

export const mutations = {
  setSurveyEditClientId(state, clientId) {
    state.surveyEditClientId = clientId;
  },
  addSurvey(state, data) {
    state.surveys.push({
      id: state.surveys.length + 1,
      name: data.name,
    });
  },
  addPageToSurvey(state, page) {
    state.currentSurvey.children.push({
      id: page.id,
      title: {en: page.title.en},
    });
  },
  updatePageTitle(state, title) {
    state.currentPageTitle = title;
  },
  toggleAddMode(state, mode) {
    state.addMode = mode;
  },
  toggleModal(state, showModal) {
    state.showModal = showModal;
  },
  setAddItemObject(state, data) {
    state.addItemObj = data;
  },
  setCurrentSurveyId(state, id) {
    state.editMode.currentSurveyId = id;
  },
  setCurrentPageId(state, id) {
    state.editMode.currentPageId = id;
  },
  setCurrentSectionId(state, id) {
    state.editMode.currentSectionId = id;
  },
  setCurrentSurvey(state, survey) {
    console.log(survey);
    state.currentSurvey = survey;
    if (survey && !state.surveyEditClientId) {
      state.surveyEditClientId = survey.client.id;
    }
  },
  setSurveyCategories(state, categories) {
    state.surveyCategories = categories;
  },
};

export const actions = {
  async onAddPageToSurvey({commit, state}, survey) {
    const {data} = await apolloClient.mutate({
      mutation: addPageToSurvey,
      variables: {
        surveyId: state.currentSurvey.id,
        title: survey.title,
      },
    });
    try {
      if (data) {
        commit("addPageToSurvey", {
          id: data.message,
          ...survey,
        });
        return data;
      }
    } catch (e) {
      return e;
    }
  },
  async onUpdatePageToSurvey({commit, state}, page) {
    const {data} = await apolloClient.mutate({
      mutation: updatePageToSurvey,
      variables: {
        surveyId: state.currentSurvey.id,
        pageId: page.id,
        title: page.title,
        description: page.description,
      },
    });
    try {
      if (data) {
        return data;
      } else {
        return new Error("cant update page in survey");
      }
    } catch (e) {
      return e;
    }
  },
  async onAddSectionToPage({commit, state}, section) {
    const {data} = await apolloClient.mutate({
      mutation: addSectionToPage,
      variables: {
        surveyId: state.currentSurvey.id,
        pageId: section.pageId,
        title: section.title,
      },
    });
    try {
      return data;
    } catch (e) {
      return e;
    }
  },
  async onUpdateSectionToPage({commit, state}, section) {
    const {data} = await apolloClient.mutate({
      mutation: updateSectionToPage,
      variables: {
        surveyId: state.currentSurvey.id,
        sectionId: section.sectionId,
        title: section.title,
        description: section.description,
        pageId: section.pageId,
      },
    });

    try {
      if (data) {
        return data;
      }
    } catch (e) {
      return e;
    }
  },
  async onAddQuestionToSection({commit, state}, question) {
    const {data} = await apolloClient.mutate({
      mutation: addQuestionToSection,
      variables: {
        surveyId: state.currentSurvey.id,
        pageId: question.pageId,
        sectionId: question.sectionId,
        title: question.title,
        question: question.title,
        inputType: "STRING",
        isRequired: true,
        isVisible: true,
      },
    });
    try {
      return data;
    } catch (e) {
      return e;
    }
    //commit('addQuestionToSection', data);
  },
  async onUpdateQuestionToSection({commit, state}, question) {
    const {data} = await apolloClient.mutate({
      mutation: updateQuestionToSection,
      variables: {
        surveyId: state.currentSurvey.id,
        pageId: question.pageId,
        sectionId: question.sectionId,
        questionId: question.id,
        inputType: question.inputType,
        title: question.title,
        question: question.question,
        helpShort: question.helpShort,
        helpDetail: question.helpDetail,
        placeholder: question.placeholder,
        min: question.min,
        max: question.max,
        confirmText: question.confirmText,
        confirmUrl: question.confirmUrl,
        options: question.options,
        isVisible: question.isVisible,
        isRequired: question.isRequired,
        isHighlighted: question.isHighlighted,
        highlightPriority: question.highlightPriority,
        data: JSON.stringify(question.data || {}),
      },
    });

    try {
      if (data) {
        return data;
      }
    } catch (e) {
      return e;
    }
  },
  async onDisplayConditionsUpdate({commit, state}, conditions) {
    const {data} = await apolloClient.mutate({
      mutation: setDisplayConditions,
      variables: {
        surveyId: state.currentSurvey.id,
        displayConditionId: conditions.id ? conditions.id : null,
        conditionQuestionId: conditions.conditionQuestionId,
        operator: conditions.operator,
        value: conditions.value,
        sectionId: conditions.sectionId || null,
        questionId: conditions.questionId || null,
        pageId: conditions.pageId || null,
      },
    });

    try {
      if (data) {
        return data.surveyDisplayConditionSet.message;
      }
    } catch (err) {
      return err;
    }
  },
  async updateRole({commit, state}, payload) {
    const {data} = await apolloClient.mutate({
      mutation: surveyUpdateRole,
      variables: {
        surveyId: state.currentSurvey.id,
        pageId: null,
        sectionId: null,
        questionId: null,
        roleName: null,
        ...payload,
      },
    });

    try {
      if (data) {
        return data.surveyUpdateRole.message;
      }
    } catch (err) {
      return err;
    }
  },
  async onSurveyAdd({commit}, survey) {
    const response = await apolloClient.mutate({
      mutation: createSurvey,
      variables: {
        title: {en: survey.title},
        type: survey.type,
      },
    });
    try {
      if (response && !response.loading) {
        //commit('addSurvey', survey);
      }
    } catch (error) {
      return error;
    }
  },
  async onSurveyUpdate({commit}, survey) {
    const {data} = await apolloClient.mutate({
      mutation: updateSurvey,
      variables: {
        surveyId: survey.surveyId,
        type: survey.type,
        title: survey.title,
        description: survey.description,
        icon: survey.icon || "",
        reportText: survey.reportText,
        reportLogo: survey.reportLogo || "",
        reportAddress: survey.reportAddress,
        clientId: survey.clientId,
        categoryId: survey.categoryId || null,
      },
    });

    try {
      return data;
    } catch (e) {
      return e;
    }
  },
  async surveyDelete({commit}, id) {
    const response = await apolloClient.mutate({
      mutation: deleteSurvey,
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    });
    try {
      if (response) {
        return response;
      }
    } catch (e) {
      return e;
    }
  },
  async onActivateSurvey({commit}, id) {
    const response = await apolloClient.mutate({
      mutation: activateSurvey,
      variables: {
        id: id,
      },
    });
    try {
      return response;
    } catch (e) {
      return e;
    }
  },
  async onDeactivateSurvey({commit}, id) {
    const response = await apolloClient.mutate({
      mutation: deactivateSurvey,
      variables: {
        id: id,
      },
    });
    try {
      return response;
    } catch (e) {
      return e;
    }
  },
  async onAssignSurvey({commit}, data) {
    const response = await apolloClient.mutate({
      mutation: cloneSurvey,
      variables: {
        id: data.id,
        clientId: data.clientId,
        categoryId: data.categoryId,
      },
    });

    try {
      return response;
    } catch (e) {
      return e;
    }
  },
  async getSurveyById({commit}, id) {
    commit("setCurrentSurvey", null);

    const {data} = await apolloClient.query({
      query: getSurveyById,
      fetchPolicy: "no-cache",
      variables: {
        id: id,
      },
    });

    try {
      if (data) {
        commit("setCurrentSurvey", data.survey);
      }
    } catch (e) {
      return e;
    }
  },
  async onDeletePage({commit, state}, pageId) {
    const {data} = await apolloClient.mutate({
      mutation: deletePageFromSurvey,
      variables: {
        surveyId: state.currentSurvey.id,
        id: pageId,
      },
    });
    try {
      return data;
    } catch (e) {
      return e;
    }
  },
  async onDeleteSection({commit, state}, sectionId) {
    const {data} = await apolloClient.mutate({
      mutation: deleteSectionFromPage,
      variables: {
        surveyId: state.currentSurvey.id,
        id: sectionId,
      },
    });
    try {
      return data;
    } catch (e) {
      return e;
    }
  },
  async onDeleteQuestion({commit, state}, questionId) {
    const {data} = await apolloClient.mutate({
      mutation: deleteQuestionFromSection,
      variables: {
        surveyId: state.currentSurvey.id,
        id: questionId,
      },
    });
    try {
      return data;
    } catch (e) {
      return e;
    }
  },
  async getSurveyCategories({commit}, clientId) {
    commit("setSurveyCategories", []);

    const {data} = await apolloClient.query({
      query: surveyCategories,
      variables: {
        clientId: clientId,
      },
    });

    try {
      commit("setSurveyCategories", data.surveyCategories);
      return data.surveyCategories;
    } catch (e) {
      return e;
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
