<template>
    <div>
        <v-tabs>
            <v-tab>
                Page
            </v-tab>
            <v-tab>Conditions</v-tab>
            <locale-picker :selected="locale" type="tab"></locale-picker>
            <v-tab-item transition="fade" reverse-transition="fade">
                <v-form>
                    <v-card flat>
                        <v-card-text>
                            <v-text-field
                                    :counter="30"
                                    label="Page title"
                                    required
                                    :placeholder="locale !== 'en' ? page.title['en'] : ''"
                                    v-model="page.title[locale]"
                            ></v-text-field>
                            <v-textarea
                                    name="input-7-1"
                                    label="Description"
                                    value="Description of page"
                                    auto-grow
                                    filled
                                    :placeholder="locale !== 'en' ? page.description['en'] : ''"
                                    v-model="page.description[locale]"
                            ></v-textarea>

                            <survey-list-sections :pageId="page.id" :children="page.children"
                                                  v-model="sections"></survey-list-sections>

                        </v-card-text>
                    </v-card>
                </v-form>
                <div class="px-7 pb-4">

                    <v-btn color="primary" :outlined=!isModified rounded @click="onSave" class="mb-3">Update</v-btn>
                </div>
            </v-tab-item>
            <v-tab-item transition="fade" reverse-transition="fade">
                <div class="pa-3 py-10">
                    <p>Please select the condition you want to add to this question</p>
                    <v-divider class="my-4"/>
                    <h3 class="subtitle-2">Visible if</h3>
                    <v-container>
                        <v-layout row>
                            <v-flex xs5>
                                <v-autocomplete
                                        type="search"
                                        v-model="selectedQuestion"
                                        :items="surveyQuestions"
                                        filled
                                        clearable
                                        @click:clear="selectedQuestion={}"
                                        item-value="id"
                                        :filter="questionFilter"
                                        return-object
                                        label="Choose question"
                                        style="max-width:100%">
                                    <template slot="item" slot-scope="data">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ data.item.index }} - {{ data.item.title.en }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.index }} - {{ data.item.title.en }}
                                    </template>
                                </v-autocomplete>
                            </v-flex>

                            <v-flex xs3>
                                <v-select
                                        :items="conditions"
                                        v-model="pageConditions.operator"
                                        item-text="text"
                                        item-value="value"
                                        filled
                                        label="Choose condition"
                                ></v-select>
                            </v-flex>

                            <v-flex xs4>
                                <template v-if="selectedQuestion.inputType === questionFieldTypesConstants.dropdown ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.multiple ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.damage ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.cost ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.checkbox ||
                                            selectedQuestion.inputType === questionFieldTypesConstants.radio">
                                    <v-autocomplete
                                            type="search"
                                            clearable
                                            :items="selectedQuestion.options"
                                            item-text="option.en"
                                            item-value="id"
                                            filled
                                            label="Value"
                                            v-model="pageConditions.value"
                                    ></v-autocomplete>
                                </template>
                                <template
                                        v-else-if="selectedQuestion.inputType === questionFieldTypesConstants.boolean">
                                    <v-select
                                            :items="optionsTrueFalse"
                                            filled
                                            label="Value"
                                            v-model="pageConditions.value"
                                    ></v-select>
                                </template>
                                <template v-else>
                                    <v-text-field
                                            filled
                                            label="Value"
                                            required
                                            v-model="pageConditions.value"
                                    ></v-text-field>
                                </template>
                            </v-flex>
                        </v-layout>
                        <v-layout row v-if="roles.length > 0">
                            <v-flex xs12>
                                <v-autocomplete
                                        v-model="selectedRole"
                                        :items="roles"
                                        filled
                                        multiple
                                        clearable
                                        @click:clear="selectedRole=[]"
                                        item-value="roleName"
                                        label="Choose Roles"
                                        style="max-width:100%">
                                    <template slot="item" slot-scope="data">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ data.item.title.en }} ({{ data.item.roleName }})
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.title.en }} ({{ data.item.roleName }})
                                    </template>
                                </v-autocomplete>
                            </v-flex>
                        </v-layout>
                        <div class="d-block pt-3">
                            <div class="d-flex">
                                <v-btn small rounded outlined color="primary" class="mr-3"
                                       @click="onSaveConditions">
                                    {{ conditionButtonText }}
                                </v-btn>
                                <v-btn small rounded outlined color="primary" @click="onDeleteConditions"
                                       v-if="showDeleteConditionButton">
                                    Delete condition
                                </v-btn>
                            </div>
                        </div>
                    </v-container>
                </div>
            </v-tab-item>

        </v-tabs>

        <div class="px-7 pb-4">

            <v-alert type="error" icon="error_outline" v-if="isError">
                There was an error saving. Please try again later, or contact SA Remote
            </v-alert>

            <v-alert type="success" icon="error_outline" v-if="isSaved">
                Details have been saved
            </v-alert>
        </div>
    </div>
</template>

<script>
import EventBus from "../../../../eventBus";
import SurveyListSections from "../Components/SurveyListSections";
import LocalePicker from "../../../../components/I18N/LocalePicker";
import {mapGetters, mapState} from "vuex";
import constants from "@/helpers/constants";
import surveys from "@/helpers/surveys";
import {surveyDisplayConditionDelete} from "@/graphql/mutations/surveys";
import _ from "lodash";
import {surveyUpdateList} from "../../../../graphql/mutations/surveys";
import {serial} from "../../../../helpers/promise";

export default {
  name: "survey-page",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showDeleteConditionButton: false,
    conditionButtonText: "Save condition",
    page: null,
    isLoading: false,
    isError: false,
    isSaved: false,
    selectedQuestion: {},
    pageConditions: {
      conditionQuestionId: null,
      operator: null,
      value: null
    },
    conditions: surveys.conditions,
    selectedRole: [],
    questionFieldTypesConstants: constants.questionFieldTypesConstants,
    optionsTrueFalse: ["true", "false"],
    conditionChanged: false,
    resetStatusAfterSeconds: 2000,
    sections: [],
    changed: {
      sections: false,
      page: false
    },
    original: {
      sections: [],
      page: {}
    }
  }),
  computed: {
    ...mapGetters("surveys", ["surveyQuestions", "currentSurveyId"]),
    ...mapState("roles", {
      "roles": (state) => {
        return state.rolesForClient || [];
      }
    }),
    ...mapState(["locale"]),
    isModified() {
      return Object.values(this.changed).find(f => f === true);
    }
  },
  components: {
    SurveyListSections,
    LocalePicker
  },
  watch: {
    selectedQuestion(val, oldVal) {
      if (val === undefined) {
        this.selectedQuestion = {};
        this.pageConditions.conditionQuestionId = null;
        this.pageConditions.value = null;
      } else {
        if (val !== oldVal) {
          this.pageConditions.conditionQuestionId = val.id;
        }
      }
    },
    pageConditions: {
      deep: true,
      handler() {
        this.conditionChanged = true;
      }
    },
    sections(val) {
      this.changed.sections = val !== this.original.sections;
    },
    page: {
      deep: true,
      handler(val) {
        let titleEqual = _.isEqual(val.title, this.original.page.title);
        let descriptionEqual = _.isEqual(val.description, this.original.page.description);
        this.changed.page = !titleEqual || !descriptionEqual;
      }
    }
  },
  methods: {
    questionFilter(item, search, itemText) {
      let [firstPart, remainder] = search.split(" ", 2);
      let searchRegexp = new RegExp(`^${firstPart}`);
      if (searchRegexp.test(item.index)) {
        if (remainder) {
          return item.title.en.toLocaleLowerCase().indexOf(remainder.toLocaleLowerCase()) > -1;
        } else {
          return true;
        }
      }
      return item.title.en.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
    },
    resetSavedStatus() {
      //Reset status
      setTimeout(() => {
        this.isLoading = false;

        this.isSaved = false;
        this.conditionChanged = false;
      }, this.resetStatusAfterSeconds);
    },
    onSaveConditions() {
      let promises = [];
      if (this.conditionChanged) {
        promises.push(this.$store.dispatch("surveys/onDisplayConditionsUpdate", {
          id: this.pageConditions.id || null,
          sectionId: null,
          conditionQuestionId: this.pageConditions.conditionQuestionId,
          operator: this.pageConditions.operator,
          value: this.pageConditions.value,
          pageId: this.page.id
        }).then((conditionId) => {
          this.pageConditions.id = conditionId;
          this.conditionButtonText = "Update condition";
          this.showDeleteConditionButton = true;
        }));
      }
      if (this.data.roleName !== this.selectedRole) {
        promises.push(this.$store.dispatch("surveys/updateRole", {
          roleName: this.selectedRole,
          pageId: this.page.id
        }).then((role) => {
          this.conditionButtonText = "Update condition";
        }));
      }
      if (promises.length === 0) {
        return false;
      }
      Promise.all(promises).then(() => {
        this.isLoading = false;
        this.isSaved = true;
        this.isError = false;
        EventBus.$emit("surveyUpdated");

        this.resetSavedStatus();
      }).catch((error) => {
        console.error(error);
        this.isError = true;
      });
    },
    onSave() {
      let promises = [];
      if (this.changed['sections']) {
        promises.push(() => {
          return this.$apollo.mutate({
            mutation: surveyUpdateList,
            variables: {
              surveyId: this.currentSurveyId,
              type: 'section', //We are updating a list of sections
              listMap: this.sections.map((entry, priority) => {
                let weight = parseInt(entry.weight);
                if (isNaN(weight)) {
                  weight = 1;
                }
                return {id: entry.id, weight: weight, priority};
              })
            }
          });
        });
      }
      if (this.changed.page) {
        const pageData = {
          id: this.page.id,
          title: this.page.title,
          description: this.page.description,
        };
        promises.push(() => {
          return this.$store.dispatch("surveys/onUpdatePageToSurvey", pageData);
        });
      }
      if (promises.length === 0) {
        return;
      }
      this.isLoading = true;

      serial(promises).then(() => {
        EventBus.$emit("surveyUpdated");

        //Reset status
        this.resetSavedStatus();
      }).catch(() => {
        this.isError = true;
        this.isLoading = false;
      });
    },
    onDeleteConditions() {
      this.$apollo.mutate({
        mutation: surveyDisplayConditionDelete,
        variables: {
          surveyId: this.currentSurveyId,
          id: this.pageConditions.id
        }
      }).then(data => {
        this.showDeleteConditionButton = false;
        this.conditionButtonText = "Save condition";
        this.isSaved = true;
        this.selectedQuestion = [];
        this.displayConditions = [];
        this.sectionConditions = {
          conditionQuestionId: null,
          operator: null,
          value: null
        };
        EventBus.$emit("surveyUpdated");

        this.resetSavedStatus();
      }).catch(() => {
        this.isError = true;
      });
    },
    setPage() {
      this.page = {
        displayConditions: [],
        roleName: null,
        ...this.data,
        id: this.data.id,
        title: this.data.title,
        description: this.data.description || {en: ""},
        children: this.data.children
      };
      if (this.page.roleName) {
        this.selectedRole = this.page.roleName;
      }
      if (this.page.displayConditions && this.page.displayConditions.length > 0) {
        this.selectedQuestion = this.surveyQuestions.find((page) => {
          return this.page.displayConditions[0] && page.id === this.page.displayConditions[0].conditionQuestionId;
        });
        if (this.selectedQuestion) {
          this.conditionButtonText = "Update condition";
          this.showDeleteConditionButton = true;

          this.pageConditions = {
            id: this.data.displayConditions[0].id,
            conditionQuestionId: this.data.displayConditions[0].conditionQuestionId,
            operator: this.data.displayConditions[0].operator,
            value: this.data.displayConditions[0].value
          };
        } else {
          this.selectedQuestion = {};
        }
      }
      this.original.page = _.cloneDeep(this.page);
    },
  },

  created() {
    this.$store.dispatch("roles/getRolesForClientSurvey");
    this.setPage();
  }
};
</script>
