<template>
    <v-dialog
            v-model="dialog"
            max-width="290">
        <v-card>
            <v-card-title class="headline text-center d-block">Reopen Survey?</v-card-title>

            <v-card-text>
                <div class="text-center" v-if="textState === 1">
                    <v-icon :size="60" color="primary" class="py-3">lock</v-icon>
                    <p>Are you sure that you wish to re-open this survey? The survey will be locked after confirmation</p>
                    <v-btn color="primary" :loading="isLoading" outlined rounded @click="onConfirm">
                        Yes
                    </v-btn>
                    <v-btn text depressed @click="onCloseModal">Cancel</v-btn>
                </div>
                <div class="text-center" v-if="textState === 2">
                    <p>The survey has been reopened. You can now close this window.</p>
                    <v-btn color="primary" outlined rounded @click="onCloseModal">Close</v-btn>
                </div>
                <v-alert class="mt-3" type="error" v-if="isError">
                    {{errorText}}
                </v-alert>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import EventBus from '../../../eventBus';

export default {
  name: 'ConfirmReopenSurvey',
  props: {
    surveyId: {
      required: true
    }
  },
  data: () => ({
    dialog: true,
    isLoading: false,
    isError: false,
    errorText: '',
    textState: 1
  }),
  watch: {
    dialog(val) {
      this.onCloseModal();
    }
  },
  methods: {
    onConfirm() {
      this.isLoading = true;
      this.$store.dispatch('submittedSurveys/surveyReopen', this.surveyId).then(() => {
        this.isSaved = true;
        this.isLoading = false;
        this.textState = 2;
        EventBus.$emit('reloadSubmittedSurveys');
      }).catch((err) => {
        this.isError = true;
        this.errorText = err;
      });
    },
    onCloseModal() {
      this.$emit('setSurveyId');
      this.$store.dispatch('modals/closeModal');
    }
  }
};
</script>
