<template>
  <div>
      <span v-if="answer">{{ answer | formatDate }}
</span>
  </div>
</template>

<script>
export default {
  name: "GenericAnswerDate",
  props: {
    answer: {
      required: true
    }
  }
};
</script>

<style scoped>

</style>
