<template>
  <div>
    <span v-if="answer">{{ answer | formatDateWithTime }}</span>
  </div>
</template>

<script>
export default {
  name: "GenericAnswerDateTime",
  props: {
    answer: {
      required: true
    }
  }
};
</script>

<style scoped>

</style>
