<template>
    <v-btn rounded :depressed="removeShadow ? true : false" color="primary" class="justify-end" @click="onAdd">
        {{buttonTitle}}
    </v-btn>
</template>

<script>
import {mapMutations} from 'vuex';

export default {
  name: 'button-add-to-survey',
  props: {
    buttonTitle: {
      required: true,
      default: 'N/A button text'
    },
    addMode: {
      required: true
    },
    removeShadow: {
      default: false
    },
    pageId: {
      default: null
    },
    sectionId: {
      default: null
    }
  },
  methods: {
    ...mapMutations('surveys', ['toggleAddMode', 'setCurrentPageId', 'setCurrentSectionId']),
    onAdd() {
      this.toggleAddMode(this.addMode);
      if (this.pageId) {
        this.setCurrentPageId(this.pageId);
      }
      if (this.sectionId) {
        this.setCurrentSectionId(this.sectionId);
      }
      this.$store.dispatch('modals/toggleModal', 'showAddToSurveyModal');
    }
  }
};
</script>
