<template>
    <v-card flat>
        <v-card-title class="client-info__heading">
            <div>
                Branding
            </div>
            <div class="text-right grow" v-if="isRootClient">
                <v-btn rounded outlined small color="primary" :to="{name: 'edit-client', params: {clientId: currentClient.id } }">Edit</v-btn>
            </div>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-container fluid>
                <v-layout row class="mb-4">
                    <v-flex md2>
                        <v-icon color="dark" class="d-inline-block mr-2" :size="20">format_paint</v-icon>
                        <h4 class="d-inline-block">Settings</h4>
                    </v-flex>
                </v-layout>
                <v-layout row>
                    <v-flex class="pl-7">
                        <div class="mb-3">
                            <div class="u-label">Primary color</div>
                            <div class="u-value mt-2">
                                <span class="brand-color brand-color--medium mr-2" :style="{'backgroundColor': currentClient.color}"></span> <span class="brand-color__label pt-1" v-if="currentClient.color">{{currentClient.color}}</span>
                                <span v-else>No color added</span>
                            </div>
                        </div>
                        <!--<div class="mb-3">
                            <div class="u-label">Secondary color (TODO: Remove?)</div>
                            <div class="u-value mt-2">
                                <span class="brand-color brand-color--medium mr-2" style="background-color: #ccc;"></span> <span class="brand-color__label pt-1">{{secondaryColor}}</span>
                            </div>
                        </div>-->
                        <div class="mb-3">
                            <div class="u-label">Logo</div>
                            <div class="u-value" v-if="currentClient.logo">
                                <img :src="cloudinaryUrl + currentClient.logo" :alt="currentClient.name" style="max-width:200px"/>
                            </div>
                            <div v-else>No logo added</div>
                        </div>
                    </v-flex>
                    <v-flex>
                        <client-branding-browser :logo="currentClient.logo" :primary-color="currentClient.color" v-if="currentClient.color" />
                        <div v-else>No branding added</div>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import constants from '../../../helpers/constants';
import {mapState, mapGetters} from 'vuex';
import ClientBrandingBrowser from './ClientBrandingBrowser';

export default {
  name: 'ClientBranding',
  data: () => ({
    cloudinaryUrl: constants.cloudinaryUrl
  }),
  components: {ClientBrandingBrowser},
  computed: {
    ...mapState('clients', ['currentClient']),
    ...mapGetters('clients', ['isRootClient'])
  }
};
</script>
