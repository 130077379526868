import { apolloClient } from "../../apollo/init-apollo";
import { sortingBy } from "../../helpers/sortingHelpers";
import {
  getLogForSurvey,
  getSubmittedSurveyInstance,
  getSubmittedSurveysList,
} from "../../graphql/queries/submitted-surveys";
import {
  surveyChangeStatus,
  surveyComplete,
  surveyInstanceAnswerFileDescriptionSet,
  surveyInstanceAnswerFileInReportSet,
  surveyInstanceAnswerReopen,
  surveyInstanceCommentSet,
  surveyInstanceRatingSet,
  surveyInstanceReopen,
} from "../../graphql/mutations/submitted-surveys";

export const state = {
  surveys: [],
  filteredSurveys: [],
  currentSurvey: null,
  includeCompletedSurveys: false,
  sortBy: null,
  vesselData: null,
  status: "SUBMITTED",
};

export const getters = {
  submittedSurveyTree: (state) => {
    if (state.currentSurvey) {
      return [
        {
          ...state.currentSurvey.survey,
        },
      ];
    }
  },
  filteredSurveys: (state) => {
    return state.filteredSurveys.filter((survey) => {
      return survey.status === state.status;
    });
  },
  getAnsweredQuestions: (state) => {
    if (!state.currentSurvey) {
      return [];
    }
    let answers = [];
    state.currentSurvey.survey.children.forEach((page) => {
      page.children.forEach((section) => {
        section.children.forEach((question) => {
          answers.push({
            questionId: question.id,
            answerId: question.answerId,
            answer: question.answer,
          });
        });
      });
    });
    return answers;
  },
};

const mutations = {
  setSearchQuery(state, query) {
    state.filteredSurveys = state.filteredSurveys.filter((survey) => {
      if (survey.title.en.toLowerCase().includes(query.toLowerCase())) {
        return survey;
      }
    });

    //Reset to original surveys array if query is under 3 chars.
    if (query.length < 3) {
      state.filteredSurveys = [...state.surveys];
    }
  },
  setSortBy(state, { sorting, direction = "ASC" }) {
    state.filteredSurveys = sortingBy[sorting](state.surveys);
    if (direction === "DESC") {
      state.filteredSurveys = state.filteredSurveys.reverse();
    }
  },
  setStatus(state, status) {
    state.status = status;
  },
  setIncludeCompletedSurveys(state, shouldInclude) {
    state.includeCompletedSurveys = shouldInclude;
  },
  setSubmittedSurveys(state, payload) {
    state.surveys = null;
    state.filteredSurveys = null;
    state.surveys = payload;
    state.filteredSurveys = payload;
  },
  setCurrentSubmittedSurvey(state, payload) {
    state.currentSurvey = null;
    const parsed = JSON.parse(payload);
    state.currentSurvey = parsed;
  },
};

const actions = {
  async getSubmittedSurveys({ commit }, keepStatus = false) {
    const { data } = await apolloClient.query({
      query: getSubmittedSurveysList,
      fetchPolicy: "no-cache",
    });
    try {
      if (data) {
        commit("setSubmittedSurveys", data.surveyInstancesCMS);
        if (!keepStatus) {
          commit("setStatus", "SUBMITTED");
        }
      }
    } catch (e) {
      return e;
    }
  },
  async getSubmittedSurvey({ commit, state }, id) {
    const { data } = await apolloClient.query({
      query: getSubmittedSurveyInstance,
      fetchPolicy: "no-cache",
      variables: {
        instanceId: id,
      },
    });
    try {
      if (data) {
        commit("setCurrentSubmittedSurvey", data.surveyInstanceData.message);
      }
    } catch (e) {
      return e;
    }
  },
  async getLog({ commit }, id) {
    const { data } = await apolloClient.query({
      query: getLogForSurvey,
      fetchPolicy: "no-cache",
      variables: {
        instanceId: id,
      },
    });
    try {
      return data.surveyInstanceEvents;
    } catch (e) {
      return e;
    }
  },
  commentSet({ commit }, payload) {
    return apolloClient
      .mutate({
        mutation: surveyInstanceCommentSet,
        variables: {
          content: payload.content,
          type: payload.type,
          commentId: payload.commentId ? payload.commentId : null,
          sectionId: payload.sectionId ? payload.sectionId : null,
          questionId: payload.questionId ? payload.questionId : null,
          instanceId: payload.instanceId ? payload.instanceId : null,
          dueDate: payload.dueDate || null,
        },
      })
      .then(
        ({ data }) => data,
        (error) => error
      );
  },
  async surveyReopen({ commit }, id) {
    const { data } = apolloClient.mutate({
      mutation: surveyInstanceReopen,
      variables: {
        instanceId: id,
      },
    });

    try {
      return data;
    } catch (e) {
      return e;
    }
  },

  async setRating({ commit }, payload) {
    const { data } = await apolloClient.mutate({
      mutation: surveyInstanceRatingSet,
      variables: {
        instanceId: payload.instanceId,
        sectionId: payload.sectionId,
        ratingId: payload.ratingId,
        rating: payload.rating,
      },
    });

    try {
      return data;
    } catch (e) {
      return e;
    }
  },

  async setImageInReport({ commit }, payload) {
    const { data } = await apolloClient.mutate({
      mutation: surveyInstanceAnswerFileInReportSet,
      variables: {
        instanceId: payload.instanceId,
        fileId: payload.fileId,
        inReport: payload.inReport,
      },
    });

    try {
      return data;
    } catch (e) {
      return e;
    }
  },

  async setDescriptionToImage({ commit }, payload) {
    const { data } = await apolloClient.mutate({
      mutation: surveyInstanceAnswerFileDescriptionSet,
      variables: {
        instanceId: payload.instanceId,
        fileId: payload.fileId,
        description: payload.description,
      },
    });

    try {
      return data;
    } catch (e) {
      return e;
    }
  },

  async openAnswerToQuestion({ commit }, payload) {

    const { data } = await apolloClient.mutate({
      mutation: surveyInstanceAnswerReopen,
      variables: {
        content: payload.content,
        instanceId: payload.instanceId,
        answerId: payload.answerId,
        question: payload.question,
      },
    });
    return data;

  },

  async completeSurveyInstance({ commit }, id) {
    const { data } = await apolloClient.mutate({
      mutation: surveyComplete,
      variables: {
        instanceId: id,
      },
    });

    try {
      return data;
    } catch (e) {
      return e;
    }
  },
  async changeStatus({ commit }, { id, status }) {
    const { data } = await apolloClient.mutate({
      mutation: surveyChangeStatus,
      variables: {
        instanceId: id,
        status: status,
      },
    });

    try {
      return data;
    } catch (e) {
      return e;
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
