import gql from "graphql-tag";

export const surveyInstanceCommentSet = gql`
  mutation surveyInstanceCommentSet(
    $content: String!
    $dueDate: String
    $type: SurveyInstanceCommentType!
    $commentId: ID
    $sectionId: ID
    $questionId: ID
    $instanceId: ID!
  ) {
    surveyInstanceCommentSet(
      content: $content
      type: $type
      commentId: $commentId
      sectionId: $sectionId
      questionId: $questionId
      instanceId: $instanceId
      dueDate: $dueDate
    ) {
      success
      message
    }
  }
`;

export const surveyInstanceReopen = gql`
  mutation surveyInstanceReopen($instanceId: ID!) {
    surveyInstanceReopen(instanceId: $instanceId) {
      success
      message
    }
  }
`;

export const surveyInstanceRatingSet = gql`
  mutation surveyInstanceRatingSet(
    $instanceId: ID!
    $sectionId: ID!
    $ratingId: ID
    $rating: Int!
  ) {
    surveyInstanceRatingSet(
      instanceId: $instanceId
      sectionId: $sectionId
      ratingId: $ratingId
      rating: $rating
    ) {
      success
      message
    }
  }
`;

export const surveyInstanceAnswerFileInReportSet = gql`
  mutation surveyInstanceAnswerFileInReportSet(
    $instanceId: ID!
    $fileId: ID!
    $inReport: Boolean!
  ) {
    surveyInstanceAnswerFileInReportSet(
      instanceId: $instanceId
      fileId: $fileId
      inReport: $inReport
    ) {
      success
      message
    }
  }
`;

export const surveyInstanceAnswerFileDescriptionSet = gql`
  mutation surveyInstanceAnswerFileDescriptionSet(
    $instanceId: ID!
    $fileId: ID!
    $description: String!
  ) {
    surveyInstanceAnswerFileDescriptionSet(
      instanceId: $instanceId
      fileId: $fileId
      description: $description
    ) {
      success
      message
    }
  }
`;

export const surveyInstanceAnswerReopen = gql`
  mutation surveyInstanceAnswerReopen(
    $content: String!
    $instanceId: ID!
    $answerId: ID!
    $question: LocaleStringInput!
  ) {
    surveyInstanceAnswerReopen(
      content: $content
      instanceId: $instanceId
      answerId: $answerId
      question: $question
    ) {
      success
      message
    }
  }
`;

export const surveyComplete = gql`
  mutation surveyInstanceClose($instanceId: ID!) {
    surveyInstanceClose(instanceId: $instanceId)
  }
`;

export const surveyChangeStatus = gql`
  mutation surveyInstanceChangeStatus($instanceId: ID!, $status: String!) {
    surveyInstanceChangeStatus(instanceId: $instanceId, status: $status)
  }
`;
export const surveyInstanceAddAttachment = gql`
  mutation surveyInstanceAddAttachment(
    $instanceId: ID!
    $files: [AttachmentInput!]!
  ) {
    surveyInstanceAttachmentAdd(instanceId: $instanceId, files: $files)
  }
`;
