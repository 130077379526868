<template>
    <v-layout row>
        <v-flex xs12 class="pa-3">
            <loading v-if="isLoading"/>

            <div v-if="!isLoading">
                <template v-if="isRootClient">
                <v-card flat class="mx-auto" style="margin-bottom:50px;" v-for="(client) in rootClients"
                        :key="client.id">
                    <helper-offset :inline="true"
                                   :full-width="false"
                                   :offset="24">
                        <v-card color="primary"
                                class="v-card--material__header d-flex align-center mb-5"
                                dark
                                elevation="10"
                                min-height="80"
                        >
                            <v-card-title>
                                <h4 class="title font-weight-light mb-2">{{client.name}} templates</h4>
                            </v-card-title>
                        </v-card>
                    </helper-offset>
                    <v-divider></v-divider>
                    <v-simple-table width="100%" class="mb-10 table-list">
                        <thead>
                        <tr>
                            <th width="20"></th>
                            <th class="text-left table-list__heading-sortable" @click="sortBy('title-deep', client.id)"><span>Survey name <v-icon>expand_more</v-icon></span>
                            </th>
                            <th class="text-left table-list__heading-sortable" @click="sortBy('date', client.id)"><span>Created <v-icon>expand_more</v-icon></span>
                            </th>
                            <th class="text-left table-list__heading-sortable" @click="sortBy('updated', client.id)"><span>Last updated <v-icon>expand_more</v-icon></span>
                            </th>
                            <th class="text-left table-list__heading-sortable" @click="sortBy('type', client.id)"><span>Type <v-icon>expand_more</v-icon></span>
                            </th>
                            <th class="text-right"><span>Actions</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in client.surveys" :key="index">
                            <td width="20">
                            </td>
                            <td>
                                <a @click="onEditSurvey(item, client.id)">
                                    {{item.title.en}}
                                </a>
                            </td>
                            <td>{{item.createdAt | formatDate }}</td>
                            <td>{{item.updatedAt | formatDate }}</td>
                            <td>{{item.type}}</td>
                            <td class="align-right d-flex justify-end align-center table-list__actions">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                                light
                                                icon
                                                small
                                                v-on="on"
                                        >
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item link @click="onEditSurvey(item, client.id)">
                                            <v-list-item-title>Edit</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item link @click="onAssignSurvey(item)">
                                            <v-list-item-title>Assign survey</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item link @click="onDelete(item)">
                                            <v-list-item-title>Delete</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                        <tr v-if="client.surveys.length === 0">
                            <td colspan="5">No surveys created</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
                </template>
                <active-indicators-row/>

                <!-- Start Clients -->
                <v-card flat class="mx-auto" v-for="client in isNotRootClients" :key="client.id">
                    <helper-offset :inline="true"
                                   :full-width="false"
                                   :offset="24">
                        <v-card color="primary"
                                class="v-card--material__header d-flex align-center mb-5"
                                dark
                                elevation="10"
                                min-height="80"
                        >
                            <v-card-title>
                                <h4 class="title font-weight-light mb-2">{{client.name}} templates</h4>
                            </v-card-title>
                        </v-card>
                    </helper-offset>
                    <v-divider></v-divider>
                    <v-simple-table width="100%" class="mb-10 table-list">
                        <thead>
                        <tr>
                            <th width="20"></th>
                            <th class="text-left table-list__heading-sortable" @click="sortBy('title-deep', client.id)"><span>Survey name <v-icon>expand_more</v-icon></span>
                            </th>
                            <th class="text-left table-list__heading-sortable" @click="sortBy('date', client.id)"><span>Created <v-icon>expand_more</v-icon></span>
                            </th>
                            <th class="text-left table-list__heading-sortable" @click="sortBy('updated', client.id)"><span>Last updated <v-icon>expand_more</v-icon></span>
                            </th>
                            <th class="text-right"><span>Actions</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in client.surveys" :key="index">
                            <td width="20">
                                <active-indicator :is-active="item.isActive"/>
                            </td>
                            <td>
                                <a @click="onEditSurvey(item, client.id)">
                                    {{item.title.en}}
                                </a>
                            </td>
                            <td>{{item.createdAt | formatDate }}</td>
                            <td>{{item.updatedAt | formatDate }}</td>
                            <td class="align-right d-flex justify-end align-center table-list__actions">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                                light
                                                icon
                                                small
                                                v-on="on"
                                        >
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item link @click="onEditSurvey(item, client.id)">
                                            <v-list-item-title>Edit</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item link @click="onDelete(item)" v-if="!item.isActive">
                                            <v-list-item-title>Delete</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item link @click="onAssignSurvey(item)" v-if="isRootClient">
                                            <v-list-item-title>Assign survey</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item link @click="toggleActivation(item)">
                                            <v-list-item-title>{{item.isActive ? 'Deactivate' : 'Activate'}}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
                        </tr>
                        <tr v-if="client.surveys.length === 0">
                            <td colspan="5">No surveys created</td>
                        </tr>
                        </tbody>
                    </v-simple-table>

                    <v-alert type="error" icon="error_outline" v-if="isError">
                        {{errorText}}
                    </v-alert>
                </v-card>
                <template v-if="isNotRootClients.length === 0">
                    <empty-state error-text="Currently no survey templates are available. Please contact SA Remote or create a new one" />
                </template>

            </div>
        </v-flex>
        <assign-survey-modal v-if="showAssignSurveyModal" :survey="selectedSurvey"
                             @surveyAssigned="fetchAllClientsAndSurveys"/>
        <confirm-delete-survey v-if="showConfirmDeleteModal" :survey="selectedSurvey" survey-name="Test"
                               @surveyDeleted="fetchAllClientsAndSurveys"/>
    </v-layout>
</template>

<script>
import EventBus from '../../eventBus';
import {mapState, mapGetters, mapMutations} from 'vuex';
import ConfirmDeleteSurvey from './Modals/ConfirmDeleteSurvey';
import AssignSurveyModal from './Modals/AssignSurveyModal';
import ActiveIndicatorsRow from '../UI/ActiveIndicatorsRow';

export default {
  name: 'manage-surveys',
  data: () => ({
    isLoading: false,
    errorText: '',
    isError: false,
    surveyId: null,
    selectedSurvey: null
  }),
  computed: {
    ...mapState('modals', ['showConfirmDeleteModal', 'showAssignSurveyModal']),
    ...mapState('clients', ['clientsWithSurveys', 'filterClientId']),
    ...mapGetters('clients', ['isRootClient']),
    rootClients() {
      const rootClients = this.clientsWithSurveys.filter((client) => {
        return client.isRoot;
      });
      return rootClients;
    },
    isNotRootClients() {
      const clients = this.clientsWithSurveys.filter((client) => {
        if (this.filterClientId) {
          return !client.isRoot && client.id.indexOf(this.filterClientId) > -1;
        } else {
          return !client.isRoot;
        }
      });
      return clients;
    }
  },
  components: {ActiveIndicatorsRow, ConfirmDeleteSurvey, AssignSurveyModal},
  methods: {
    ...mapMutations('clients', ['setSortBy']),
    ...mapMutations('surveys', ['setSurveyEditClientId']),
    onEditSurvey(item, clientId) {
      this.setSurveyEditClientId(clientId);
      this.$router.push({
        name: 'edit-survey',
        params: {surveyId: item.id}
      });
    },
    onDelete(survey) {
      this.selectedSurvey = survey;
      this.$store.dispatch('modals/toggleModal', 'showConfirmDeleteModal');
    },
    onAssignSurvey(survey) {
      this.selectedSurvey = survey;
      this.$store.dispatch('modals/toggleModal', 'showAssignSurveyModal');
    },
    toggleActivation(item) {
      if (item.isActive) {
        this.$store.dispatch('surveys/onDeactivateSurvey', item.id);
      }
      if (!item.isActive) {
        this.$store.dispatch('surveys/onActivateSurvey', item.id);
      }
      item.isActive = !item.isActive;
    },
    fetchAllClientsAndSurveys() {
      this.isLoading = true;
      this.$store.dispatch('clients/getClientsAndSurveys').then(() => {
        this.isLoading = false;
      }).catch((err) => {
        this.errorText = err;
        this.isError = true;
        this.isLoading = false;
      });
    },
    sortBy(sorting, clientId) {
      const payload = {
        sorting: sorting,
        clientId: clientId
      };
      this.setSortBy(payload);
    }
  },
  created() {
    this.fetchAllClientsAndSurveys();
  },
  mounted() {
    EventBus.$on('surveyUpdated', () => {
      this.fetchAllClientsAndSurveys();
    });
  }
};
</script>
