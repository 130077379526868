<template>
    <v-dialog
            v-model="dialog"
            max-width="400"
    >
        <v-card>
            <v-card-title class="headline">Add {{addMode}}</v-card-title>

            <v-card-text>
                <v-form @submit.prevent="onAdd">
                    <p>First thing, we need to know the title of the {{addMode}} you want to create</p>
                    <v-text-field
                            v-model="data.title"
                            label="Name"
                            name="name"
                            type="text"
                            :counter="255"
                            hint="Enter a short descriptive title"
                            autofocus
                    ></v-text-field>
                    <div v-if="addMode === 'Survey'">
                        <v-select
                                :items="types"
                                label="Survey type"
                                v-model="data.type"
                        ></v-select>
                    </div>
                    <div class="text-sm-center pt-3">
                        <v-btn color="primary" :loading="isLoading" min-width="150" outlined rounded type="submit">Add
                            {{addMode}}
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>
</template>

<script>
import EventBus from '../../../eventBus';
import constants from '../../../helpers/constants';
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'AddSurveyModal',
  data: () => ({
    isLoading: false,
    dialog: true,
    types: ['DAMAGE', 'CONDITION'],
    data: {
      title: '',
      type: ''
    }
  }),
  watch: {
    dialog(val) {
      this.onCloseModal();
    }
  },
  computed: {
    ...mapState('surveys', ['addMode', 'addItemObj', 'editMode'])
  },
  methods: {
    ...mapMutations('surveys', ['toggleModal']),
    onCloseModal() {
      this.$store.dispatch('modals/closeModal');
    },
    onAdd() {
      if (this.addMode === constants.editModes.survey) {
        this.$store.dispatch('surveys/onSurveyAdd', this.data).then(() => {
          this.isLoading = false;
          EventBus.$emit('surveyUpdated');
          this.onCloseModal();
        });
      }
      if (this.addMode === constants.editModes.page) {
        this.$store.dispatch('surveys/onAddPageToSurvey', {title: {en: this.data.title}}).then(() => {
          this.isLoading = false;
          EventBus.$emit('surveyUpdated');
          this.onCloseModal();
        });
      }
      if (this.addMode === constants.editModes.section) {
        this.$store.dispatch('surveys/onAddSectionToPage', {
          pageId: this.editMode.currentPageId,
          title: {en: this.data.title}
        }).then(() => {
          this.isLoading = false;
          EventBus.$emit('surveyUpdated');
          this.onCloseModal();
        });
      }
      if (this.addMode === constants.editModes.question) {
        this.$store.dispatch('surveys/onAddQuestionToSection', {
          pageId: this.editMode.currentPageId,
          sectionId: this.editMode.currentSectionId,
          title: {en: this.data.title}
        }).then(() => {
          this.isLoading = false;
          EventBus.$emit('surveyUpdated');
          this.onCloseModal();
        });
      }
    }
  }
};
</script>
