import gql from "graphql-tag";
import { LocalizedInputFragment } from "@/graphql/fragtments/localizedInput";

export const exploreRoleRelevantEnums = gql`
  query exploreRoleRelevantEnums {
    RolePermissions: __type(name: "RolePermission") {
      enumValues {
        name
        description
      }
    }
    NotificationEvents: __type(name: "NotificationEvent") {
      enumValues {
        name
        description
      }
    }
  }
`;
export const getRolesForClientForUser = gql`
  query roles($clientId: ID!) {
    roles(clientId: $clientId) {
      id
      title {
        ...localizedFragment
      }
      roleName
    }
  }
  ${LocalizedInputFragment}
`;

export const getRoleById = gql`
  query role($id: ID!) {
    role(id: $id) {
      id
      title {
        ...localizedFragment
      }
      userType
      clientId
      roleName
      permissions
      notifications
    }
  }
  ${LocalizedInputFragment}
`;
